import './main.scss';
import { Elm } from './elm/Main.elm';
import * as serviceWorker from './serviceWorker';

const getAPIDomain = () => process.env.ELM_APP_API_URL || 'janet.local:8000';

const getProtocol = () => process.env.NODE_ENV === 'production' ? 'https' : 'http';

const getDarkThemeLS = () => localStorage.getItem('dark-theme') === 'true';

const getTenant = () => window.location.hostname.split('.')[0];

const getToken = () => tokenIsValid(localStorage.getItem('token'));

const decodeJWT = token => JSON.parse(atob(token.split('.')[1]));

const tokenIsValid = token => token && decodeJWT(token).exp * 1000 > Date.now() ? token : null;

const getFlags = () => {
  return {
    token: getToken(),
    api_url: getAPIDomain(),
    tenant: getTenant(),
    protocol: getProtocol(),
    is_dark_theme: getDarkThemeLS(),
  }
};

const toggleDarkTheme = enabled => {
  document.querySelector('#clarity-light').disabled = enabled;
  document.querySelector('#clarity-dark').disabled = !enabled;
};


toggleDarkTheme(getDarkThemeLS());

const app = Elm.Main.init({flags: getFlags()});

app.ports.saveTokenToLS.subscribe(token => localStorage.setItem('token', token));

app.ports.deleteTokenFromLS.subscribe(() => localStorage.removeItem('token'));

app.ports.toggleDarkTheme.subscribe(enabled => {
  localStorage.setItem('dark-theme', enabled);
  toggleDarkTheme(enabled);
});

app.ports.decodeJWT.subscribe((token) => {
  app.ports.decodeJWTResults.send(decodeJWT(token));
});

// serviceWorker.register();
