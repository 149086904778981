(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.ax.R === region.aI.R)
	{
		return 'on line ' + region.ax.R;
	}
	return 'on lines ' + region.ax.R + ' through ' + region.aI.R;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bT,
		impl.cu,
		impl.cp,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		b_: func(record.b_),
		co: record.co,
		cb: record.cb
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.b_;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.co;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.cb) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bT,
		impl.cu,
		impl.cp,
		function(sendToApp, initialModel) {
			var view = impl.cy;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bT,
		impl.cu,
		impl.cp,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.G && impl.G(sendToApp)
			var view = impl.cy;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.ak);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.cs) && (_VirtualDom_doc.title = title = doc.cs);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.b1;
	var onUrlRequest = impl.b2;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		G: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.ce === next.ce
							&& curr.aQ === next.aQ
							&& curr.a4.a === next.a4.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		bT: function(flags)
		{
			return A3(impl.bT, flags, _Browser_getUrl(), key);
		},
		cy: impl.cy,
		cu: impl.cu,
		cp: impl.cp
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { bR: 'hidden', by: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { bR: 'mozHidden', by: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { bR: 'msHidden', by: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { bR: 'webkitHidden', by: 'webkitvisibilitychange' }
		: { bR: 'hidden', by: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		bf: _Browser_getScene(),
		br: {
			ai: _Browser_window.pageXOffset,
			aj: _Browser_window.pageYOffset,
			L: _Browser_doc.documentElement.clientWidth,
			C: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		L: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		C: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			bf: {
				L: node.scrollWidth,
				C: node.scrollHeight
			},
			br: {
				ai: node.scrollLeft,
				aj: node.scrollTop,
				L: node.clientWidth,
				C: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			bf: _Browser_getScene(),
			br: {
				ai: x,
				aj: y,
				L: _Browser_doc.documentElement.clientWidth,
				C: _Browser_doc.documentElement.clientHeight
			},
			bJ: {
				ai: x + rect.left,
				aj: y + rect.top,
				L: rect.width,
				C: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.an.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.an.b, xhr)); });
		$elm$core$Maybe$isJust(request.az) && _Http_track(router, xhr, request.az.a);

		try {
			xhr.open(request.au, request.aA, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.aA));
		}

		_Http_configureRequest(xhr, request);

		request.ak.a && xhr.setRequestHeader('Content-Type', request.ak.a);
		xhr.send(request.ak.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.ao; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.ay.a || 0;
	xhr.responseType = request.an.d;
	xhr.withCredentials = request.bv;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		aA: xhr.responseURL,
		X: xhr.status,
		cn: xhr.statusText,
		ao: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			cj: event.loaded,
			bj: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			cf: event.loaded,
			bj: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $author$project$Messages$LinkClicked = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $author$project$Messages$UrlChanged = function (a) {
	return {$: 0, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.e) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.g),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.g);
		} else {
			var treeLen = builder.e * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.h) : builder.h;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.e);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.g) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.g);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{h: nodeList, e: (len / $elm$core$Array$branchFactor) | 0, g: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {aM: fragment, aQ: host, a2: path, a4: port_, ce: protocol, a8: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Messages$ScheduleMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Schedule$Messages$UpdateTime = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Commands$initTime = A2(
	$elm$core$Task$perform,
	A2($elm$core$Basics$composeL, $author$project$Messages$ScheduleMsg, $author$project$Schedule$Messages$UpdateTime),
	$elm$time$Time$now);
var $author$project$Auth$Models$LoginClean = {$: 0};
var $author$project$Schedule$Models$Now = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Schedule$Models$VaryJob = {$: 0};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $author$project$Clients$Models$emptyClient = {a_: $elm$core$Dict$empty, b0: '', bp: ''};
var $author$project$Departments$Models$emptyDepartment = {a_: $elm$core$Dict$empty, b0: '', bp: ''};
var $author$project$Jobs$Models$Setup = 0;
var $author$project$Jobs$Models$emptyJob = {bz: '', bE: '', bF: '', bI: $elm$core$Maybe$Nothing, a_: $elm$core$Dict$empty, b0: '', W: 0, ct: 1, bp: '', cx: 0};
var $author$project$Jobs$Models$emptyJobListFilter = {bz: '', bg: '', W: ''};
var $author$project$Jobs$Models$emptyJobOperation = {bC: 1, bD: 1, bH: _List_Nil, bK: 1, bX: '', a_: $elm$core$Dict$empty, b5: '', ck: 1, cq: 1, bp: ''};
var $author$project$Machines$Models$emptyMachine = {aH: '', a_: $elm$core$Dict$empty, b0: '', b5: '', bp: ''};
var $author$project$Operations$Models$emptyOperation = {a_: $elm$core$Dict$empty, b0: '', bp: ''};
var $author$project$Shifts$Models$emptyShiftRule = {
	bG: _List_Nil,
	aY: $elm$core$Maybe$Nothing,
	a_: $elm$core$Dict$empty,
	b0: '',
	cc: 2,
	bi: _Utils_Tuple2(0, 0),
	bp: '',
	bq: $elm$core$Maybe$Nothing
};
var $author$project$Routes$initHistory = function (route) {
	return _List_fromArray(
		[route]);
};
var $author$project$Models$initialModel = F3(
	function (flags, key, route) {
		return {
			bt: $elm$core$Maybe$Nothing,
			bw: flags.bw,
			N: $author$project$Clients$Models$emptyClient,
			al: $elm$core$Dict$empty,
			bA: $author$project$Schedule$Models$VaryJob,
			O: $author$project$Departments$Models$emptyDepartment,
			am: $elm$core$Dict$empty,
			bQ: $elm$core$Maybe$Nothing,
			aP: $author$project$Routes$initHistory(route),
			bV: flags.bV,
			P: $author$project$Jobs$Models$emptyJob,
			aV: $author$project$Jobs$Models$emptyJobListFilter,
			Q: $author$project$Jobs$Models$emptyJobOperation,
			aq: $elm$core$Dict$empty,
			ar: $elm$core$Dict$empty,
			bY: $elm$core$Maybe$Nothing,
			ae: key,
			as: $author$project$Auth$Models$LoginClean,
			S: $author$project$Machines$Models$emptyMachine,
			aZ: $elm$core$Dict$empty,
			b4: $elm$core$Maybe$Nothing,
			b6: $author$project$Operations$Models$emptyOperation,
			b7: $elm$core$Dict$empty,
			b8: '',
			cd: _List_Nil,
			ce: flags.ce,
			ci: _List_Nil,
			cl: $author$project$Shifts$Models$emptyShiftRule,
			cm: _List_Nil,
			cr: flags.cr,
			bn: flags.bn,
			cw: '',
			cz: A2(
				$author$project$Schedule$Models$Now,
				$elm$time$Time$millisToPosix(0),
				54)
		};
	});
var $author$project$Commands$initialNav = function (url) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(
			$author$project$Messages$UrlChanged(url)));
};
var $author$project$Messages$ClientsMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Clients$Messages$ListClientsResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Clients$Models$Client = F3(
	function (uri, name, meta) {
		return {a_: meta, b0: name, bp: uri};
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Clients$Codecs$decodeClient = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'meta',
	$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'uri',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Clients$Models$Client))));
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Clients$Codecs$decodeClients = $elm$json$Json$Decode$list($author$project$Clients$Codecs$decodeClient);
var $author$project$Requests$GET = 0;
var $author$project$Requests$buildUrl = F2(
	function (model, path) {
		var api_url = function () {
			var _v0 = model.cr;
			if (_v0 === '') {
				return model.bw;
			} else {
				return model.cr + ('.' + model.bw);
			}
		}();
		return model.ce + ('://' + (api_url + ('/' + (path + '/'))));
	});
var $author$project$Requests$ErrorWithMessage = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Requests$ErrorWithoutMessage = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $author$project$Requests$expectErrorJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 0:
						var url = response.a;
						return $elm$core$Result$Err(
							$author$project$Requests$ErrorWithoutMessage(404));
					case 1:
						return $elm$core$Result$Err(
							$author$project$Requests$ErrorWithoutMessage(999));
					case 2:
						return $elm$core$Result$Err(
							$author$project$Requests$ErrorWithoutMessage(999));
					case 3:
						var metadata = response.a;
						var body = response.b;
						var _v1 = A2(
							$elm$json$Json$Decode$decodeString,
							A2(
								$elm$json$Json$Decode$at,
								_List_fromArray(
									['Message']),
								$elm$json$Json$Decode$string),
							body);
						if (!_v1.$) {
							var message = _v1.a;
							return $elm$core$Result$Err(
								A2($author$project$Requests$ErrorWithMessage, metadata.X, message));
						} else {
							return $elm$core$Result$Err(
								$author$project$Requests$ErrorWithoutMessage(metadata.X));
						}
					default:
						var metadata = response.a;
						var body = response.b;
						var _v2 = A2($elm$json$Json$Decode$decodeString, decoder, body);
						if (!_v2.$) {
							var value = _v2.a;
							return $elm$core$Result$Ok(value);
						} else {
							var e = _v2.a;
							return $elm$core$Result$Err(
								A2(
									$author$project$Requests$ErrorWithMessage,
									metadata.X,
									$elm$json$Json$Decode$errorToString(e)));
						}
				}
			});
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {ba: reqs, bl: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.az;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.ba));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.bl)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					bv: r.bv,
					ak: r.ak,
					an: A2(_Http_mapExpect, func, r.an),
					ao: r.ao,
					au: r.au,
					ay: r.ay,
					az: r.az,
					aA: r.aA
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{bv: false, ak: r.ak, an: r.an, ao: r.ao, au: r.au, ay: r.ay, az: r.az, aA: r.aA}));
};
var $author$project$Requests$verbAsString = function (verb) {
	switch (verb) {
		case 0:
			return 'GET';
		case 1:
			return 'POST';
		case 2:
			return 'PUT';
		case 3:
			return 'PATCH';
		case 4:
			return 'DELETE';
		default:
			return 'OPTIONS';
	}
};
var $author$project$Requests$authorizedRequest = F6(
	function (method, model, url, body, msg, decoder) {
		var _v0 = model.bn;
		if (!_v0.$) {
			var token = _v0.a;
			return $elm$http$Http$request(
				{
					ak: body,
					an: A2($author$project$Requests$expectErrorJson, msg, decoder),
					ao: _List_fromArray(
						[
							A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
						]),
					au: $author$project$Requests$verbAsString(method),
					ay: $elm$core$Maybe$Nothing,
					az: $elm$core$Maybe$Nothing,
					aA: A2($author$project$Requests$buildUrl, model, url)
				});
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $author$project$Requests$get = F2(
	function (model, _v0) {
		var url = _v0.aA;
		var msg = _v0.a0;
		var decoder = _v0.aG;
		return A6($author$project$Requests$authorizedRequest, 0, model, url, $elm$http$Http$emptyBody, msg, decoder);
	});
var $author$project$Clients$Commands$listClients = function (model) {
	return A2(
		$author$project$Requests$get,
		model,
		{
			aG: $author$project$Clients$Codecs$decodeClients,
			a0: A2($elm$core$Basics$composeL, $author$project$Messages$ClientsMsg, $author$project$Clients$Messages$ListClientsResponse),
			aA: 'clients'
		});
};
var $author$project$Messages$DepartmentsMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Departments$Messages$ListDepartmentsResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Departments$Models$Department = F3(
	function (uri, name, meta) {
		return {a_: meta, b0: name, bp: uri};
	});
var $author$project$Departments$Codecs$decodeDepartment = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'meta',
	$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'uri',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Departments$Models$Department))));
var $author$project$Departments$Codecs$decodeDepartments = $elm$json$Json$Decode$list($author$project$Departments$Codecs$decodeDepartment);
var $author$project$Departments$Commands$listDepartments = function (model) {
	return A2(
		$author$project$Requests$get,
		model,
		{
			aG: $author$project$Departments$Codecs$decodeDepartments,
			a0: A2($elm$core$Basics$composeL, $author$project$Messages$DepartmentsMsg, $author$project$Departments$Messages$ListDepartmentsResponse),
			aA: 'departments'
		});
};
var $author$project$Messages$JobsMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Jobs$Messages$ListJobOperationsResponse = function (a) {
	return {$: 1, a: a};
};
var $author$project$Jobs$Models$JobOperation = function (uri) {
	return function (job) {
		return function (operation) {
			return function (count) {
				return function (estimated_rate) {
					return function (count_conversion) {
						return function (setup_time) {
							return function (teardown_time) {
								return function (meta) {
									return function (dependencies) {
										return {bC: count, bD: count_conversion, bH: dependencies, bK: estimated_rate, bX: job, a_: meta, b5: operation, ck: setup_time, cq: teardown_time, bp: uri};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Jobs$Codecs$decodeJobOperation = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'dependencies',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'meta',
		$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'teardown_time',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'setup_time',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'count_conversion',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'estimated_rate',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'count',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'operation',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'job',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'uri',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Jobs$Models$JobOperation)))))))))));
var $author$project$Jobs$Codecs$decodeJobOperations = $elm$json$Json$Decode$list($author$project$Jobs$Codecs$decodeJobOperation);
var $author$project$Jobs$Commands$listJobOperations = function (model) {
	return A2(
		$author$project$Requests$get,
		model,
		{
			aG: $author$project$Jobs$Codecs$decodeJobOperations,
			a0: A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$ListJobOperationsResponse),
			aA: 'job-operations'
		});
};
var $author$project$Jobs$Messages$ListJobsResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Jobs$Models$Job = function (uri) {
	return function (name) {
		return function (description) {
			return function (client) {
				return function (date_created) {
					return function (date_due) {
						return function (total_count) {
							return function (value) {
								return function (status) {
									return function (meta) {
										return {bz: client, bE: date_created, bF: date_due, bI: description, a_: meta, b0: name, W: status, ct: total_count, bp: uri, cx: value};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Jobs$Models$Completed = 4;
var $author$project$Jobs$Models$Ready = 1;
var $author$project$Jobs$Models$Running = 3;
var $author$project$Jobs$Models$Scheduled = 2;
var $elm$json$Json$Decode$fail = _Json_fail;
var $author$project$Jobs$Codecs$decodeJobStatus = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'Setup':
				return $elm$json$Json$Decode$succeed(0);
			case 'Ready':
				return $elm$json$Json$Decode$succeed(1);
			case 'Scheduled':
				return $elm$json$Json$Decode$succeed(2);
			case 'Running':
				return $elm$json$Json$Decode$succeed(3);
			case 'Completed':
				return $elm$json$Json$Decode$succeed(4);
			default:
				var unknown = str;
				return $elm$json$Json$Decode$fail('Unknown status:' + unknown);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Jobs$Codecs$decodeJob = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'meta',
	$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'status',
		$author$project$Jobs$Codecs$decodeJobStatus,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'value',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'total_count',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'date_due',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'date_created',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'client',
							$elm$json$Json$Decode$string,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'description',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'name',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'uri',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Jobs$Models$Job)))))))))));
var $author$project$Jobs$Codecs$decodeJobs = $elm$json$Json$Decode$list($author$project$Jobs$Codecs$decodeJob);
var $author$project$Jobs$Commands$listJobs = function (model) {
	return A2(
		$author$project$Requests$get,
		model,
		{
			aG: $author$project$Jobs$Codecs$decodeJobs,
			a0: A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$ListJobsResponse),
			aA: 'jobs'
		});
};
var $author$project$Machines$Messages$ListMachinesResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Messages$MachinesMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Machines$Models$Machine = F5(
	function (uri, name, operation, department, meta) {
		return {aH: department, a_: meta, b0: name, b5: operation, bp: uri};
	});
var $author$project$Machines$Codecs$decodeMachine = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'meta',
	$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'department',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'operation',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'uri',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Machines$Models$Machine))))));
var $author$project$Machines$Codecs$decodeMachines = $elm$json$Json$Decode$list($author$project$Machines$Codecs$decodeMachine);
var $author$project$Machines$Commands$listMachines = function (model) {
	return A2(
		$author$project$Requests$get,
		model,
		{
			aG: $author$project$Machines$Codecs$decodeMachines,
			a0: A2($elm$core$Basics$composeL, $author$project$Messages$MachinesMsg, $author$project$Machines$Messages$ListMachinesResponse),
			aA: 'machines'
		});
};
var $author$project$Operations$Messages$ListOperationsResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Messages$OperationsMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Operations$Models$Operation = F3(
	function (uri, name, meta) {
		return {a_: meta, b0: name, bp: uri};
	});
var $author$project$Operations$Codecs$decodeOperation = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'meta',
	$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'uri',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Operations$Models$Operation))));
var $author$project$Operations$Codecs$decodeOperations = $elm$json$Json$Decode$list($author$project$Operations$Codecs$decodeOperation);
var $author$project$Operations$Commands$listOperations = function (model) {
	return A2(
		$author$project$Requests$get,
		model,
		{
			aG: $author$project$Operations$Codecs$decodeOperations,
			a0: A2($elm$core$Basics$composeL, $author$project$Messages$OperationsMsg, $author$project$Operations$Messages$ListOperationsResponse),
			aA: 'operations'
		});
};
var $author$project$Schedule$Messages$ListScheduleResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Schedule$Models$ScheduledOperation = F5(
	function (uri, job_operation, machine, time_start, meta) {
		return {aW: job_operation, aY: machine, a_: meta, Z: time_start, bp: uri};
	});
var $author$project$Schedule$Codecs$decodeScheduledOperation = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'meta',
	$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'time_start',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'machine',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'job_operation',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'uri',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Schedule$Models$ScheduledOperation))))));
var $author$project$Schedule$Codecs$decodeSchedule = $elm$json$Json$Decode$list($author$project$Schedule$Codecs$decodeScheduledOperation);
var $author$project$Schedule$Commands$listSchedule = function (model) {
	return A2(
		$author$project$Requests$get,
		model,
		{
			aG: $author$project$Schedule$Codecs$decodeSchedule,
			a0: A2($elm$core$Basics$composeL, $author$project$Messages$ScheduleMsg, $author$project$Schedule$Messages$ListScheduleResponse),
			aA: 'schedule'
		});
};
var $author$project$Shifts$Messages$ListShiftsResponse = function (a) {
	return {$: 0, a: a};
};
var $author$project$Messages$ShiftsMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Shifts$Models$ShiftRule = F8(
	function (uri, name, days, machine, valid_range, shift, priority, meta) {
		return {bG: days, aY: machine, a_: meta, b0: name, cc: priority, bi: shift, bp: uri, bq: valid_range};
	});
var $author$project$Shifts$Codecs$decodePosixRange = A2(
	$elm$json$Json$Decode$andThen,
	function (timelist) {
		if ((timelist.b && timelist.b.b) && (!timelist.b.b.b)) {
			var start = timelist.a;
			var _v1 = timelist.b;
			var end = _v1.a;
			return $elm$json$Json$Decode$succeed(
				_Utils_Tuple2(
					$elm$time$Time$millisToPosix(start),
					$elm$time$Time$millisToPosix(end)));
		} else {
			return $elm$json$Json$Decode$fail('Unknown time range');
		}
	},
	$elm$json$Json$Decode$list($elm$json$Json$Decode$int));
var $author$project$Shifts$Codecs$decodeStartEnd = A2(
	$elm$json$Json$Decode$andThen,
	function (result) {
		if ((result.b && result.b.b) && (!result.b.b.b)) {
			var start = result.a;
			var _v1 = result.b;
			var end = _v1.a;
			return $elm$json$Json$Decode$succeed(
				_Utils_Tuple2(start, end));
		} else {
			return $elm$json$Json$Decode$fail('Unknown shift bounds');
		}
	},
	$elm$json$Json$Decode$list($elm$json$Json$Decode$int));
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $author$project$Shifts$Codecs$decodeWeekday = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'Mon':
				return $elm$json$Json$Decode$succeed(0);
			case 'Tue':
				return $elm$json$Json$Decode$succeed(1);
			case 'Wed':
				return $elm$json$Json$Decode$succeed(2);
			case 'Thu':
				return $elm$json$Json$Decode$succeed(3);
			case 'Fri':
				return $elm$json$Json$Decode$succeed(4);
			case 'Sat':
				return $elm$json$Json$Decode$succeed(5);
			case 'Sun':
				return $elm$json$Json$Decode$succeed(6);
			default:
				var unknown = str;
				return $elm$json$Json$Decode$fail('Unknown weekday:' + unknown);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Shifts$Codecs$decodeWeekdays = $elm$json$Json$Decode$list($author$project$Shifts$Codecs$decodeWeekday);
var $author$project$Shifts$Codecs$decodeShiftRule = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'meta',
	$elm$json$Json$Decode$dict($elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'priority',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'shift',
			$author$project$Shifts$Codecs$decodeStartEnd,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'valid_range',
				$elm$json$Json$Decode$nullable($author$project$Shifts$Codecs$decodePosixRange),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'machine',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'days',
						$author$project$Shifts$Codecs$decodeWeekdays,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'name',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'uri',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Shifts$Models$ShiftRule)))))))));
var $author$project$Shifts$Codecs$decodeShiftRules = $elm$json$Json$Decode$list($author$project$Shifts$Codecs$decodeShiftRule);
var $author$project$Shifts$Commands$listShifts = function (model) {
	return A2(
		$author$project$Requests$get,
		model,
		{
			aG: $author$project$Shifts$Codecs$decodeShiftRules,
			a0: A2($elm$core$Basics$composeL, $author$project$Messages$ShiftsMsg, $author$project$Shifts$Messages$ListShiftsResponse),
			aA: 'shifts'
		});
};
var $author$project$Messages$AuthMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Auth$Messages$CheckTokenResponse = function (a) {
	return {$: 7, a: a};
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Auth$Codecs$encodeAuthToken = function (token) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'token',
				$elm$json$Json$Encode$string(token))
			]));
};
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.X));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Auth$Commands$sendCheckToken = function (model) {
	var _v0 = model.bn;
	if (!_v0.$) {
		var token = _v0.a;
		return $elm$http$Http$request(
			{
				ak: $elm$http$Http$jsonBody(
					$author$project$Auth$Codecs$encodeAuthToken(token)),
				an: $elm$http$Http$expectWhatever(
					A2($elm$core$Basics$composeL, $author$project$Messages$AuthMsg, $author$project$Auth$Messages$CheckTokenResponse)),
				ao: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				au: 'POST',
				ay: $elm$core$Maybe$Nothing,
				az: $elm$core$Maybe$Nothing,
				aA: A2($author$project$Requests$buildUrl, model, 'auth/check')
			});
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {u: frag, w: params, s: unvisited, cx: value, y: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.s;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.cx);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.cx);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.a2),
					$elm$url$Url$Parser$prepareQuery(url.a8),
					url.aM,
					$elm$core$Basics$identity)));
	});
var $author$project$Routes$AuthRoute = function (a) {
	return {$: 1, a: a};
};
var $author$project$Clients$Routes$ClientCreate = {$: 1};
var $author$project$Clients$Routes$ClientDetail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Clients$Routes$ClientList = {$: 0};
var $author$project$Routes$ClientsRoute = function (a) {
	return {$: 3, a: a};
};
var $author$project$Departments$Routes$DepartmentCreate = {$: 1};
var $author$project$Departments$Routes$DepartmentDetail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Departments$Routes$DepartmentsList = {$: 0};
var $author$project$Routes$DepartmentsRoute = function (a) {
	return {$: 6, a: a};
};
var $author$project$Schedule$Routes$EditSchedule = 1;
var $author$project$Routes$HomepageRoute = {$: 9};
var $author$project$Jobs$Routes$JobCreate = {$: 1};
var $author$project$Jobs$Routes$JobDetail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Jobs$Routes$JobList = {$: 0};
var $author$project$Routes$JobsRoute = function (a) {
	return {$: 2, a: a};
};
var $author$project$Auth$Routes$Login = $elm$core$Basics$identity;
var $author$project$Machines$Routes$MachineCreate = {$: 1};
var $author$project$Machines$Routes$MachineDetail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Machines$Routes$MachinesList = {$: 0};
var $author$project$Routes$MachinesRoute = function (a) {
	return {$: 4, a: a};
};
var $author$project$Operations$Routes$OperationCreate = {$: 1};
var $author$project$Operations$Routes$OperationDetail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Operations$Routes$OperationsList = {$: 0};
var $author$project$Routes$OperationsRoute = function (a) {
	return {$: 5, a: a};
};
var $author$project$Schedule$Routes$Schedule = 0;
var $author$project$Routes$ScheduleRoute = function (a) {
	return {$: 7, a: a};
};
var $author$project$Shifts$Routes$ShiftCreate = {$: 1};
var $author$project$Shifts$Routes$ShiftDetail = function (a) {
	return {$: 2, a: a};
};
var $author$project$Shifts$Routes$ShiftList = {$: 0};
var $author$project$Routes$ShiftsRoute = function (a) {
	return {$: 8, a: a};
};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.y;
		var unvisited = _v0.s;
		var params = _v0.w;
		var frag = _v0.u;
		var value = _v0.cx;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.y;
			var unvisited = _v1.s;
			var params = _v1.w;
			var frag = _v1.u;
			var value = _v1.cx;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.y;
		var unvisited = _v1.s;
		var params = _v1.w;
		var frag = _v1.u;
		var value = _v1.cx;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.y;
		var unvisited = _v0.s;
		var params = _v0.w;
		var frag = _v0.u;
		var value = _v0.cx;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.y;
			var unvisited = _v0.s;
			var params = _v0.w;
			var frag = _v0.u;
			var value = _v0.cx;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Routes$routeParser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeL, $author$project$Routes$AuthRoute, $elm$core$Basics$identity),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('auth'),
				A2(
					$elm$url$Url$Parser$questionMark,
					$elm$url$Url$Parser$s('login'),
					$elm$url$Url$Parser$Query$string('next')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$JobsRoute($author$project$Jobs$Routes$JobCreate),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('jobs'),
				$elm$url$Url$Parser$s('create'))),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeL, $author$project$Routes$JobsRoute, $author$project$Jobs$Routes$JobDetail),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('jobs'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$JobsRoute($author$project$Jobs$Routes$JobList),
			$elm$url$Url$Parser$s('jobs')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$MachinesRoute($author$project$Machines$Routes$MachineCreate),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('machines'),
				$elm$url$Url$Parser$s('create'))),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeL, $author$project$Routes$MachinesRoute, $author$project$Machines$Routes$MachineDetail),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('machines'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$MachinesRoute($author$project$Machines$Routes$MachinesList),
			$elm$url$Url$Parser$s('machines')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$OperationsRoute($author$project$Operations$Routes$OperationCreate),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('operations'),
				$elm$url$Url$Parser$s('create'))),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeL, $author$project$Routes$OperationsRoute, $author$project$Operations$Routes$OperationDetail),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('operations'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$OperationsRoute($author$project$Operations$Routes$OperationsList),
			$elm$url$Url$Parser$s('operations')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$DepartmentsRoute($author$project$Departments$Routes$DepartmentCreate),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('departments'),
				$elm$url$Url$Parser$s('create'))),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeL, $author$project$Routes$DepartmentsRoute, $author$project$Departments$Routes$DepartmentDetail),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('departments'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$DepartmentsRoute($author$project$Departments$Routes$DepartmentsList),
			$elm$url$Url$Parser$s('departments')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$ClientsRoute($author$project$Clients$Routes$ClientCreate),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('clients'),
				$elm$url$Url$Parser$s('create'))),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeL, $author$project$Routes$ClientsRoute, $author$project$Clients$Routes$ClientDetail),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('clients'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$ClientsRoute($author$project$Clients$Routes$ClientList),
			$elm$url$Url$Parser$s('clients')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$ShiftsRoute($author$project$Shifts$Routes$ShiftCreate),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('shifts'),
				$elm$url$Url$Parser$s('create'))),
			A2(
			$elm$url$Url$Parser$map,
			A2($elm$core$Basics$composeL, $author$project$Routes$ShiftsRoute, $author$project$Shifts$Routes$ShiftDetail),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('shifts'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$ShiftsRoute($author$project$Shifts$Routes$ShiftList),
			$elm$url$Url$Parser$s('shifts')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$ScheduleRoute(0),
			$elm$url$Url$Parser$s('schedule')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$ScheduleRoute(1),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('schedule'),
				$elm$url$Url$Parser$s('edit'))),
			A2($elm$url$Url$Parser$map, $author$project$Routes$HomepageRoute, $elm$url$Url$Parser$top)
		]));
var $author$project$Routes$urlToRoute = function (url) {
	return A2($elm$url$Url$Parser$parse, $author$project$Routes$routeParser, url);
};
var $author$project$Main$init = F3(
	function (flags, url, key) {
		var route = $author$project$Routes$urlToRoute(url);
		var model = A3($author$project$Models$initialModel, flags, key, route);
		var commands = function () {
			var _v0 = flags.bn;
			if (!_v0.$) {
				return _List_fromArray(
					[
						$author$project$Commands$initialNav(url),
						$author$project$Commands$initTime,
						$author$project$Auth$Commands$sendCheckToken(model),
						$author$project$Jobs$Commands$listJobs(model),
						$author$project$Jobs$Commands$listJobOperations(model),
						$author$project$Machines$Commands$listMachines(model),
						$author$project$Departments$Commands$listDepartments(model),
						$author$project$Operations$Commands$listOperations(model),
						$author$project$Clients$Commands$listClients(model),
						$author$project$Schedule$Commands$listSchedule(model),
						$author$project$Shifts$Commands$listShifts(model)
					]);
			} else {
				return _List_fromArray(
					[
						$author$project$Commands$initialNav(url),
						$author$project$Commands$initTime
					]);
			}
		}();
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(commands));
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Auth$Messages$RefreshToken = function (a) {
	return {$: 8, a: a};
};
var $author$project$Ports$decodeJWTResults = _Platform_incomingPort('decodeJWTResults', $elm$json$Json$Decode$value);
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {a7: processes, bm: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.a7;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.bm);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Auth$Messages$JWTDecodeResponse = function (a) {
	return {$: 10, a: a};
};
var $author$project$Messages$NoOp = {$: 12};
var $author$project$Auth$Models$JWTBody = F4(
	function (user_id, username, name, is_superuser) {
		return {bW: is_superuser, b0: name, cv: user_id, cw: username};
	});
var $author$project$Auth$Codecs$decodeJWT = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'is_superuser',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'username',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'user_id',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Auth$Models$JWTBody)))));
var $author$project$Auth$Subscriptions$mapDecodeUser = function (incomingJson) {
	var _v0 = A2($elm$json$Json$Decode$decodeValue, $author$project$Auth$Codecs$decodeJWT, incomingJson);
	if (!_v0.$) {
		var user = _v0.a;
		return $author$project$Messages$AuthMsg(
			$author$project$Auth$Messages$JWTDecodeResponse(user));
	} else {
		var e = _v0.a;
		return $author$project$Messages$NoOp;
	}
};
var $author$project$Auth$Subscriptions$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$time$Time$every,
				1000 * 15,
				A2($elm$core$Basics$composeL, $author$project$Messages$AuthMsg, $author$project$Auth$Messages$RefreshToken)),
				$author$project$Ports$decodeJWTResults($author$project$Auth$Subscriptions$mapDecodeUser)
			]));
};
var $author$project$Jobs$Messages$PollJobs = function (a) {
	return {$: 2, a: a};
};
var $author$project$Jobs$Subscriptions$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$time$Time$every,
				1000 * 60,
				A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$PollJobs))
			]));
};
var $author$project$Schedule$Messages$PollSchedule = function (a) {
	return {$: 1, a: a};
};
var $author$project$Schedule$Subscriptions$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$time$Time$every,
				1000 * 10,
				A2($elm$core$Basics$composeL, $author$project$Messages$ScheduleMsg, $author$project$Schedule$Messages$PollSchedule)),
				A2(
				$elm$time$Time$every,
				(1000 * 60) * 5,
				A2($elm$core$Basics$composeL, $author$project$Messages$ScheduleMsg, $author$project$Schedule$Messages$UpdateTime))
			]));
};
var $author$project$Subscriptions$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		A2(
			$elm$core$List$map,
			function (s) {
				return s(model);
			},
			_List_fromArray(
				[$author$project$Jobs$Subscriptions$subscriptions, $author$project$Schedule$Subscriptions$subscriptions, $author$project$Auth$Subscriptions$subscriptions])));
};
var $author$project$Routes$Loading = {$: 0};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Auth$Routes$routeToString = function (route) {
	var next = route;
	if (!next.$) {
		var nextRoute = next.a;
		return '/auth/login?next=' + nextRoute;
	} else {
		return '/auth/login';
	}
};
var $author$project$Clients$Routes$routeToString = function (route) {
	switch (route.$) {
		case 0:
			return '/clients';
		case 1:
			return '/clients/create';
		default:
			var clientId = route.a;
			return '/clients/' + clientId;
	}
};
var $author$project$Departments$Routes$routeToString = function (route) {
	switch (route.$) {
		case 0:
			return '/departments';
		case 1:
			return '/department/create';
		default:
			var departmentId = route.a;
			return '/departments/' + departmentId;
	}
};
var $author$project$Jobs$Routes$routeToString = function (route) {
	switch (route.$) {
		case 0:
			return '/jobs';
		case 1:
			return '/jobs/create';
		default:
			var jobid = route.a;
			return '/jobs/' + jobid;
	}
};
var $author$project$Machines$Routes$routeToString = function (route) {
	switch (route.$) {
		case 0:
			return '/machines';
		case 1:
			return '/machines/create';
		default:
			var machineId = route.a;
			return '/machines/' + machineId;
	}
};
var $author$project$Operations$Routes$routeToString = function (route) {
	switch (route.$) {
		case 0:
			return '/operations';
		case 1:
			return '/operations/create';
		default:
			var operationId = route.a;
			return '/operations/' + operationId;
	}
};
var $author$project$Schedule$Routes$routeToString = function (route) {
	if (!route) {
		return '/schedule';
	} else {
		return '/schedule/edit';
	}
};
var $author$project$Shifts$Routes$routeToString = function (route) {
	switch (route.$) {
		case 0:
			return '/shifts';
		case 1:
			return '/shift/create';
		default:
			var shiftId = route.a;
			return '/shifts/' + shiftId;
	}
};
var $author$project$Routes$routeToString = function (route) {
	switch (route.$) {
		case 9:
			return '/';
		case 1:
			var authRoute = route.a;
			return $author$project$Auth$Routes$routeToString(authRoute);
		case 2:
			var jobsRoute = route.a;
			return $author$project$Jobs$Routes$routeToString(jobsRoute);
		case 3:
			var clientsRoute = route.a;
			return $author$project$Clients$Routes$routeToString(clientsRoute);
		case 4:
			var machinesRoute = route.a;
			return $author$project$Machines$Routes$routeToString(machinesRoute);
		case 5:
			var operationRoute = route.a;
			return $author$project$Operations$Routes$routeToString(operationRoute);
		case 6:
			var departmentsRoute = route.a;
			return $author$project$Departments$Routes$routeToString(departmentsRoute);
		case 7:
			var scheduleRoute = route.a;
			return $author$project$Schedule$Routes$routeToString(scheduleRoute);
		case 8:
			var shiftRoute = route.a;
			return $author$project$Shifts$Routes$routeToString(shiftRoute);
		default:
			return '';
	}
};
var $author$project$Clients$Update$urlUpdate = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$Clients$Commands$listClients(model));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{N: $author$project$Clients$Models$emptyClient}),
					$elm$core$Platform$Cmd$none);
			default:
				var clientId = route.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Departments$Update$urlUpdate = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$Departments$Commands$listDepartments(model));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: $author$project$Departments$Models$emptyDepartment}),
					$elm$core$Platform$Cmd$none);
			default:
				var id = route.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Jobs$Update$urlUpdate = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Jobs$Commands$listJobs(model)
							])));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{P: $author$project$Jobs$Models$emptyJob}),
					$elm$core$Platform$Cmd$batch(_List_Nil));
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Q: $author$project$Jobs$Models$emptyJobOperation}),
					$elm$core$Platform$Cmd$batch(_List_Nil));
		}
	});
var $author$project$Machines$Update$urlUpdate = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$Machines$Commands$listMachines(model));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: $author$project$Machines$Models$emptyMachine}),
					$elm$core$Platform$Cmd$none);
			default:
				var id = route.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Operations$Update$urlUpdate = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$author$project$Operations$Commands$listOperations(model));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b6: $author$project$Operations$Models$emptyOperation}),
					$elm$core$Platform$Cmd$none);
			default:
				var id = route.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Schedule$Update$urlUpdate = F2(
	function (model, route) {
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{bA: $author$project$Schedule$Models$VaryJob}),
			$author$project$Schedule$Commands$listSchedule(model));
	});
var $author$project$Shifts$Update$urlUpdate = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Shifts$Commands$listShifts(model)
							])));
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cl: $author$project$Shifts$Models$emptyShiftRule}),
					$elm$core$Platform$Cmd$none);
			default:
				var s = route.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Update$urlUpdate = F2(
	function (model, route) {
		var newModel = _Utils_update(
			model,
			{
				aP: A2($elm$core$List$cons, route, model.aP)
			});
		_v0$7:
		while (true) {
			if (!route.$) {
				switch (route.a.$) {
					case 2:
						var jobsRoute = route.a.a;
						return A2($author$project$Jobs$Update$urlUpdate, newModel, jobsRoute);
					case 3:
						var clientsRoute = route.a.a;
						return A2($author$project$Clients$Update$urlUpdate, newModel, clientsRoute);
					case 4:
						var machinesRoute = route.a.a;
						return A2($author$project$Machines$Update$urlUpdate, newModel, machinesRoute);
					case 5:
						var operationsRoute = route.a.a;
						return A2($author$project$Operations$Update$urlUpdate, newModel, operationsRoute);
					case 6:
						var departmentRoute = route.a.a;
						return A2($author$project$Departments$Update$urlUpdate, newModel, departmentRoute);
					case 7:
						var scheduleRoute = route.a.a;
						return A2($author$project$Schedule$Update$urlUpdate, newModel, scheduleRoute);
					case 8:
						var shiftRoute = route.a.a;
						return A2($author$project$Shifts$Update$urlUpdate, newModel, shiftRoute);
					default:
						break _v0$7;
				}
			} else {
				break _v0$7;
			}
		}
		return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Update$guardRoutes = F2(
	function (model, route) {
		if (!route.$) {
			if (route.a.$ === 1) {
				return A2($author$project$Update$urlUpdate, model, route);
			} else {
				var protectedRoute = route.a;
				var nextUrl = '/auth/login?next=' + $author$project$Routes$routeToString(protectedRoute);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aP: _List_fromArray(
								[
									$elm$core$Maybe$Just($author$project$Routes$Loading)
								])
						}),
					A2($elm$browser$Browser$Navigation$pushUrl, model.ae, nextUrl));
			}
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						aP: _List_fromArray(
							[
								$elm$core$Maybe$Just($author$project$Routes$Loading)
							])
					}),
				A2($elm$browser$Browser$Navigation$pushUrl, model.ae, '/auth/login'));
		}
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.ce;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.aM,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.a8,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.a4,
					_Utils_ap(http, url.aQ)),
				url.a2)));
};
var $author$project$Auth$Models$LoginError = function (a) {
	return {$: 2, a: a};
};
var $author$project$Auth$Models$LoginProcessing = {$: 1};
var $elm$core$String$append = _String_append;
var $author$project$Ports$decodeJWT = _Platform_outgoingPort('decodeJWT', $elm$json$Json$Encode$string);
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Ports$deleteTokenFromLS = _Platform_outgoingPort(
	'deleteTokenFromLS',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Ports$saveTokenToLS = _Platform_outgoingPort('saveTokenToLS', $elm$json$Json$Encode$string);
var $author$project$Auth$Update$handleLoginResponse = F2(
	function (auth_token, model) {
		var route = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Maybe$Nothing,
			$elm$core$List$head(model.aP));
		var next_url = function () {
			if ((!route.$) && (route.a.$ === 1)) {
				var authRoute = route.a.a;
				var next = authRoute;
				if (!next.$) {
					var nextUrl = next.a;
					return nextUrl;
				} else {
					return '/';
				}
			} else {
				return '/';
			}
		}();
		var newModel = _Utils_update(
			model,
			{
				as: $author$project$Auth$Models$LoginClean,
				bn: $elm$core$Maybe$Just(auth_token.bn)
			});
		var commands = $elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					$author$project$Ports$saveTokenToLS(auth_token.bn),
					A2($elm$browser$Browser$Navigation$pushUrl, newModel.ae, next_url),
					$author$project$Jobs$Commands$listJobs(newModel),
					$author$project$Jobs$Commands$listJobOperations(newModel),
					$author$project$Machines$Commands$listMachines(newModel),
					$author$project$Departments$Commands$listDepartments(newModel),
					$author$project$Operations$Commands$listOperations(newModel),
					$author$project$Clients$Commands$listClients(newModel),
					$author$project$Schedule$Commands$listSchedule(newModel),
					$author$project$Shifts$Commands$listShifts(newModel),
					$author$project$Ports$decodeJWT(auth_token.bn)
				]));
		return _Utils_Tuple2(newModel, commands);
	});
var $author$project$Auth$Messages$LoginResponse = function (a) {
	return {$: 4, a: a};
};
var $author$project$Auth$Models$AuthToken = function (token) {
	return {bn: token};
};
var $author$project$Auth$Codecs$decodeAuthToken = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'token',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Auth$Models$AuthToken));
var $author$project$Auth$Codecs$encodeLogin = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'username',
				$elm$json$Json$Encode$string(model.cw)),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$string(model.b8))
			]));
};
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $author$project$Auth$Commands$postLogin = function (model) {
	return $elm$http$Http$request(
		{
			ak: $elm$http$Http$jsonBody(
				$author$project$Auth$Codecs$encodeLogin(model)),
			an: A2(
				$elm$http$Http$expectJson,
				A2($elm$core$Basics$composeL, $author$project$Messages$AuthMsg, $author$project$Auth$Messages$LoginResponse),
				$author$project$Auth$Codecs$decodeAuthToken),
			ao: _List_Nil,
			au: 'POST',
			ay: $elm$core$Maybe$Nothing,
			az: $elm$core$Maybe$Nothing,
			aA: A2($author$project$Requests$buildUrl, model, 'auth/login')
		});
};
var $author$project$Auth$Messages$RefreshTokenResponse = function (a) {
	return {$: 9, a: a};
};
var $author$project$Auth$Commands$refreshToken = function (model) {
	var _v0 = model.bn;
	if (!_v0.$) {
		var token = _v0.a;
		return $elm$http$Http$request(
			{
				ak: $elm$http$Http$jsonBody(
					$author$project$Auth$Codecs$encodeAuthToken(token)),
				an: A2(
					$author$project$Requests$expectErrorJson,
					A2($elm$core$Basics$composeL, $author$project$Messages$AuthMsg, $author$project$Auth$Messages$RefreshTokenResponse),
					$author$project$Auth$Codecs$decodeAuthToken),
				ao: _List_Nil,
				au: 'POST',
				ay: $elm$core$Maybe$Nothing,
				az: $elm$core$Maybe$Nothing,
				aA: A2($author$project$Requests$buildUrl, model, 'auth/refresh')
			});
	} else {
		return $elm$core$Platform$Cmd$none;
	}
};
var $author$project$Auth$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var username = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cw: username}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var password = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b8: password}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var tenant = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cr: tenant}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{as: $author$project$Auth$Models$LoginProcessing}),
					$author$project$Auth$Commands$postLogin(model));
			case 4:
				if (!msg.a.$) {
					var authToken = msg.a.a;
					return A2($author$project$Auth$Update$handleLoginResponse, authToken, model);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								as: $author$project$Auth$Models$LoginError('Incorrect Username and/or Password')
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bn: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($elm$browser$Browser$Navigation$pushUrl, model.ae, '/auth/login'),
								$author$project$Ports$deleteTokenFromLS(0)
							])));
			case 6:
				var _v1 = model.bn;
				if (!_v1.$) {
					var token = _v1.a;
					return _Utils_Tuple2(
						model,
						$author$project$Auth$Commands$sendCheckToken(model));
				} else {
					return _Utils_Tuple2(
						model,
						A2($elm$browser$Browser$Navigation$pushUrl, model.ae, '/auth/login'));
				}
			case 7:
				if (!msg.a.$) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var nextUrl = A2(
						$elm$core$String$append,
						'?next=',
						$author$project$Routes$routeToString(
							A2(
								$elm$core$Maybe$withDefault,
								$author$project$Routes$Loading,
								A2(
									$elm$core$Maybe$withDefault,
									$elm$core$Maybe$Nothing,
									$elm$core$List$head(model.aP)))));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bn: $elm$core$Maybe$Nothing}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Ports$deleteTokenFromLS(0),
									A2($elm$browser$Browser$Navigation$pushUrl, model.ae, '/auth/login' + nextUrl)
								])));
				}
			case 8:
				var t = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Auth$Commands$refreshToken(model));
			case 9:
				if (!msg.a.$) {
					var token = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bn: $elm$core$Maybe$Just(token.bn)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Ports$saveTokenToLS(token.bn),
									$author$project$Ports$decodeJWT(token.bn)
								])));
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var body = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bY: $elm$core$Maybe$Just(body)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Clients$Messages$CreateClientResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Clients$Codecs$encodeClient = function (client) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(client.b0))
			]));
};
var $author$project$Requests$POST = 1;
var $author$project$Requests$post = F2(
	function (model, _v0) {
		var url = _v0.aA;
		var body = _v0.ak;
		var msg = _v0.a0;
		var decoder = _v0.aG;
		return A6(
			$author$project$Requests$authorizedRequest,
			1,
			model,
			url,
			$elm$http$Http$jsonBody(body),
			msg,
			decoder);
	});
var $author$project$Clients$Commands$createClient = F2(
	function (model, client) {
		return A2(
			$author$project$Requests$post,
			model,
			{
				ak: $author$project$Clients$Codecs$encodeClient(client),
				aG: $author$project$Clients$Codecs$decodeClient,
				a0: A2($elm$core$Basics$composeL, $author$project$Messages$ClientsMsg, $author$project$Clients$Messages$CreateClientResponse),
				aA: 'clients'
			});
	});
var $author$project$Utils$Utils$dictByKey = F2(
	function (key, data) {
		return $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (record) {
					return _Utils_Tuple2(
						key(record),
						record);
				},
				data));
	});
var $author$project$UI$Models$Alert = F2(
	function (level, message) {
		return {bZ: level, b_: message};
	});
var $author$project$UI$Models$Danger = 0;
var $author$project$UI$Models$Warning = 1;
var $author$project$UI$Messages$AlertMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Messages$UIMsg = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$UI$Commands$delay = F2(
	function (i, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(i));
	});
var $author$project$UI$Commands$delayedClearAlert = A2(
	$author$project$UI$Commands$delay,
	5000,
	$author$project$Messages$UIMsg(
		$author$project$UI$Messages$AlertMsg($elm$core$Maybe$Nothing)));
var $author$project$UI$Utils$handleError = F2(
	function (model, error) {
		var is404 = function () {
			if (!error.$) {
				var status = error.a;
				return status === 404;
			} else {
				var status = error.a;
				return status === 404;
			}
		}();
		var is401 = function () {
			if (!error.$) {
				var status = error.a;
				return status === 401;
			} else {
				var status = error.a;
				return status === 401;
			}
		}();
		var alert = function () {
			if (!error.$) {
				var status = error.a;
				switch (status) {
					case 500:
						return A2($author$project$UI$Models$Alert, 0, 'Something went wrong with our server. Please Try Again Shortly');
					case 401:
						return A2($author$project$UI$Models$Alert, 1, 'Your session has expired, please login and try again.');
					case 400:
						return A2($author$project$UI$Models$Alert, 1, 'Something you entered was not quite right. Please fix it and try again.');
					default:
						return A2($author$project$UI$Models$Alert, 0, 'Something went wrong! Please Try again Shortly');
				}
			} else {
				var error_msg = error.b;
				return A2($author$project$UI$Models$Alert, 0, error_msg);
			}
		}();
		var _v0 = _Utils_Tuple2(is401, is404);
		if (_v0.a) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{bn: $elm$core$Maybe$Nothing}),
				$elm$core$Platform$Cmd$none);
		} else {
			if (_v0.b) {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bQ: $elm$core$Maybe$Just(alert)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$UI$Commands$delayedClearAlert,
								A2($elm$browser$Browser$Navigation$pushUrl, model.ae, '/')
							])));
			} else {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bQ: $elm$core$Maybe$Just(alert)
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[$author$project$UI$Commands$delayedClearAlert])));
			}
		}
	});
var $author$project$Clients$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var clients = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								al: A2(
									$author$project$Utils$Utils$dictByKey,
									function ($) {
										return $.bp;
									},
									clients)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			case 1:
				var s = msg.a;
				var client_form = model.N;
				var new_client_form = _Utils_update(
					client_form,
					{b0: s});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{N: new_client_form}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{N: $author$project$Clients$Models$emptyClient}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($author$project$Clients$Commands$createClient, model, model.N),
								A2($elm$browser$Browser$Navigation$pushUrl, model.ae, '/clients')
							])));
			default:
				if (!msg.a.$) {
					var client = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								al: A3($elm$core$Dict$insert, client.bp, client, model.al)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Clients$Commands$listClients(model)
								])));
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
		}
	});
var $author$project$Departments$Messages$CreateDepartmentResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Departments$Codecs$encodeDepartment = function (department) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(department.b0))
			]));
};
var $author$project$Departments$Commands$createDepartment = F2(
	function (model, department) {
		return A2(
			$author$project$Requests$post,
			model,
			{
				ak: $author$project$Departments$Codecs$encodeDepartment(department),
				aG: $author$project$Departments$Codecs$decodeDepartment,
				a0: A2($elm$core$Basics$composeL, $author$project$Messages$DepartmentsMsg, $author$project$Departments$Messages$CreateDepartmentResponse),
				aA: 'departments'
			});
	});
var $author$project$Departments$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var departments = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								am: A2(
									$author$project$Utils$Utils$dictByKey,
									function ($) {
										return $.bp;
									},
									departments)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			case 1:
				var s = msg.a;
				var department = model.O;
				var new_department = _Utils_update(
					department,
					{b0: s});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: new_department}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: $author$project$Departments$Models$emptyDepartment}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($author$project$Departments$Commands$createDepartment, model, model.O),
								A2($elm$browser$Browser$Navigation$pushUrl, model.ae, '/departments')
							])));
			default:
				if (!msg.a.$) {
					var dept = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								am: A3($elm$core$Dict$insert, dept.bp, dept, model.am)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
		}
	});
var $author$project$Jobs$Messages$JobCreationResponse = function (a) {
	return {$: 8, a: a};
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Jobs$Codecs$encodeCreateJob = function (job) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(job.b0)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(
					A2($elm$core$Maybe$withDefault, '', job.bI))),
				_Utils_Tuple2(
				'client',
				$elm$json$Json$Encode$string(job.bz)),
				_Utils_Tuple2(
				'date_created',
				$elm$json$Json$Encode$string(job.bE)),
				_Utils_Tuple2(
				'date_due',
				$elm$json$Json$Encode$string(job.bF)),
				_Utils_Tuple2(
				'total_count',
				$elm$json$Json$Encode$int(job.ct)),
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$int(job.cx))
			]));
};
var $author$project$Jobs$Commands$createJob = F2(
	function (model, job) {
		return A2(
			$author$project$Requests$post,
			model,
			{
				ak: $author$project$Jobs$Codecs$encodeCreateJob(job),
				aG: $author$project$Jobs$Codecs$decodeJob,
				a0: A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobCreationResponse),
				aA: 'jobs'
			});
	});
var $author$project$Jobs$Messages$JobOperationCreationResponse = function (a) {
	return {$: 5, a: a};
};
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Jobs$Codecs$encodeCreateJobOperation = function (job_op) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'job',
				$elm$json$Json$Encode$string(job_op.bX)),
				_Utils_Tuple2(
				'operation',
				$elm$json$Json$Encode$string(job_op.b5)),
				_Utils_Tuple2(
				'count',
				$elm$json$Json$Encode$int(job_op.bC)),
				_Utils_Tuple2(
				'estimated_rate',
				$elm$json$Json$Encode$int(job_op.bK)),
				_Utils_Tuple2(
				'count_conversion',
				$elm$json$Json$Encode$int(job_op.bD)),
				_Utils_Tuple2(
				'setup_time',
				$elm$json$Json$Encode$int(job_op.ck)),
				_Utils_Tuple2(
				'teardown_time',
				$elm$json$Json$Encode$int(job_op.cq)),
				_Utils_Tuple2(
				'meta',
				A3($elm$json$Json$Encode$dict, $elm$core$Basics$identity, $elm$json$Json$Encode$string, job_op.a_)),
				_Utils_Tuple2(
				'dependencies',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, job_op.bH))
			]));
};
var $author$project$Jobs$Commands$createJobOperation = F3(
	function (model, job_id, job_op) {
		return A2(
			$author$project$Requests$post,
			model,
			{
				ak: $author$project$Jobs$Codecs$encodeCreateJobOperation(
					_Utils_update(
						job_op,
						{bX: job_id})),
				aG: $author$project$Jobs$Codecs$decodeJobOperation,
				a0: A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobOperationCreationResponse),
				aA: 'job-operations'
			});
	});
var $author$project$Jobs$Update$updateJobForm = F2(
	function (msg, job) {
		switch (msg.$) {
			case 0:
				var n = msg.a;
				return _Utils_update(
					job,
					{b0: n});
			case 1:
				var c = msg.a;
				return _Utils_update(
					job,
					{bz: c});
			case 2:
				var d = msg.a;
				return _Utils_update(
					job,
					{
						bI: $elm$core$Maybe$Just(d)
					});
			case 3:
				var d = msg.a;
				return _Utils_update(
					job,
					{bE: d});
			case 4:
				var d = msg.a;
				return _Utils_update(
					job,
					{bF: d});
			case 5:
				var t = msg.a;
				return _Utils_update(
					job,
					{
						ct: A2(
							$elm$core$Maybe$withDefault,
							1,
							$elm$core$String$toInt(t))
					});
			default:
				var v = msg.a;
				return _Utils_update(
					job,
					{
						cx: A2(
							$elm$core$Maybe$withDefault,
							1,
							$elm$core$String$toInt(v))
					});
		}
	});
var $author$project$Jobs$Update$updateJobListFilter = F2(
	function (msg, job_list_filter) {
		switch (msg.$) {
			case 0:
				var s = msg.a;
				return _Utils_update(
					job_list_filter,
					{bg: s});
			case 1:
				var c = msg.a;
				return _Utils_update(
					job_list_filter,
					{bz: c});
			case 2:
				var s = msg.a;
				return _Utils_update(
					job_list_filter,
					{bz: s});
			default:
				return $author$project$Jobs$Models$emptyJobListFilter;
		}
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $author$project$Jobs$Update$updateJobOperationForm = F2(
	function (msg, job_operation) {
		switch (msg.$) {
			case 0:
				var o = msg.a;
				return _Utils_update(
					job_operation,
					{b5: o});
			case 1:
				var c = msg.a;
				return _Utils_update(
					job_operation,
					{
						bC: A2(
							$elm$core$Maybe$withDefault,
							1,
							$elm$core$String$toInt(c))
					});
			case 2:
				var r = msg.a;
				return _Utils_update(
					job_operation,
					{
						bK: A2(
							$elm$core$Maybe$withDefault,
							1,
							$elm$core$String$toInt(r))
					});
			case 3:
				var c = msg.a;
				return _Utils_update(
					job_operation,
					{
						bD: A2(
							$elm$core$Maybe$withDefault,
							1,
							$elm$core$String$toInt(c))
					});
			case 4:
				var t = msg.a;
				return _Utils_update(
					job_operation,
					{
						ck: A2(
							$elm$core$Maybe$withDefault,
							1,
							$elm$core$String$toInt(t))
					});
			case 5:
				var t = msg.a;
				return _Utils_update(
					job_operation,
					{
						cq: A2(
							$elm$core$Maybe$withDefault,
							1,
							$elm$core$String$toInt(t))
					});
			default:
				var d = msg.a;
				var _v1 = A2($elm$core$List$member, d, job_operation.bH);
				if (_v1) {
					return _Utils_update(
						job_operation,
						{
							bH: A2(
								$elm$core$List$filter,
								function (dep) {
									return !_Utils_eq(dep, d);
								},
								job_operation.bH)
						});
				} else {
					return _Utils_update(
						job_operation,
						{
							bH: A2($elm$core$List$cons, d, job_operation.bH)
						});
				}
		}
	});
var $author$project$Jobs$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Jobs$Commands$listJobs(model),
								$author$project$Jobs$Commands$listJobOperations(model)
							])));
			case 0:
				if (!msg.a.$) {
					var jobs = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: A2(
									$author$project$Utils$Utils$dictByKey,
									function ($) {
										return $.bp;
									},
									jobs)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			case 1:
				if (!msg.a.$) {
					var operations = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aq: A2(
									$author$project$Utils$Utils$dictByKey,
									function ($) {
										return $.bp;
									},
									operations)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			case 6:
				var formMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							P: A2($author$project$Jobs$Update$updateJobForm, formMsg, model.P)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{P: $author$project$Jobs$Models$emptyJob}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($author$project$Jobs$Commands$createJob, model, model.P),
								A2($elm$browser$Browser$Navigation$pushUrl, model.ae, '/jobs')
							])));
			case 8:
				if (!msg.a.$) {
					var job = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: A3($elm$core$Dict$insert, job.bp, job, model.ar)
							}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									$author$project$Jobs$Commands$listJobs(model),
									A2($elm$browser$Browser$Navigation$pushUrl, model.ae, job.bp)
								])));
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			case 3:
				var formMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							Q: A2($author$project$Jobs$Update$updateJobOperationForm, formMsg, model.Q)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var job_id = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{Q: $author$project$Jobs$Models$emptyJobOperation}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A3($author$project$Jobs$Commands$createJobOperation, model, job_id, model.Q)
							])));
			case 5:
				if (!msg.a.$) {
					var job_op = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aq: A3($elm$core$Dict$insert, job_op.bp, job_op, model.aq)
							}),
						$author$project$Jobs$Commands$listJobOperations(model));
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			default:
				var filterMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aV: A2($author$project$Jobs$Update$updateJobListFilter, filterMsg, model.aV)
						}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Machines$Messages$CreateMachineResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Machines$Codecs$encodeMachine = function (machine) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(machine.b0)),
				_Utils_Tuple2(
				'operation',
				$elm$json$Json$Encode$string(machine.b5)),
				_Utils_Tuple2(
				'department',
				$elm$json$Json$Encode$string(machine.aH))
			]));
};
var $author$project$Machines$Commands$createMachine = F2(
	function (model, machine) {
		return A2(
			$author$project$Requests$post,
			model,
			{
				ak: $author$project$Machines$Codecs$encodeMachine(machine),
				aG: $author$project$Machines$Codecs$decodeMachine,
				a0: A2($elm$core$Basics$composeL, $author$project$Messages$MachinesMsg, $author$project$Machines$Messages$CreateMachineResponse),
				aA: 'machines'
			});
	});
var $author$project$Machines$Update$updateMachineForm = F2(
	function (msg, machine) {
		switch (msg.$) {
			case 0:
				var n = msg.a;
				return _Utils_update(
					machine,
					{b0: n});
			case 1:
				var o = msg.a;
				return _Utils_update(
					machine,
					{b5: o});
			default:
				var d = msg.a;
				return _Utils_update(
					machine,
					{aH: d});
		}
	});
var $author$project$Machines$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var machines = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: A2(
									$author$project$Utils$Utils$dictByKey,
									function ($) {
										return $.bp;
									},
									machines)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			case 1:
				var formMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							S: A2($author$project$Machines$Update$updateMachineForm, formMsg, model.S)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{S: $author$project$Machines$Models$emptyMachine}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($author$project$Machines$Commands$createMachine, model, model.S),
								A2($elm$browser$Browser$Navigation$pushUrl, model.ae, '/machines')
							])));
			default:
				if (!msg.a.$) {
					var machine = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aZ: A3($elm$core$Dict$insert, machine.bp, machine, model.aZ)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
		}
	});
var $author$project$Operations$Messages$CreateOperationResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Operations$Codecs$encodeOperation = function (operation) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(operation.b0))
			]));
};
var $author$project$Operations$Commands$createOperation = F2(
	function (model, operation) {
		return A2(
			$author$project$Requests$post,
			model,
			{
				ak: $author$project$Operations$Codecs$encodeOperation(operation),
				aG: $author$project$Operations$Codecs$decodeOperation,
				a0: A2($elm$core$Basics$composeL, $author$project$Messages$OperationsMsg, $author$project$Operations$Messages$CreateOperationResponse),
				aA: 'operations'
			});
	});
var $author$project$Operations$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var operations = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b7: A2(
									$author$project$Utils$Utils$dictByKey,
									function ($) {
										return $.bp;
									},
									operations)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			case 1:
				var s = msg.a;
				var operation_form = model.b6;
				var new_operation_form = _Utils_update(
					operation_form,
					{b0: s});
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b6: new_operation_form}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b6: $author$project$Operations$Models$emptyOperation}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2($author$project$Operations$Commands$createOperation, model, model.b6),
								A2($elm$browser$Browser$Navigation$pushUrl, model.ae, '/operations')
							])));
			default:
				if (!msg.a.$) {
					var operation = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								b7: A3($elm$core$Dict$insert, operation.bp, operation, model.b7)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
		}
	});
var $author$project$Schedule$Models$Window = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Schedule$Messages$CreateScheduleOperationResponse = function (a) {
	return {$: 7, a: a};
};
var $author$project$Schedule$Codecs$encodeScheduledOperation = function (operation) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'job_operation',
				$elm$json$Json$Encode$string(operation.aW)),
				_Utils_Tuple2(
				'machine',
				$elm$json$Json$Encode$string(operation.aY)),
				_Utils_Tuple2(
				'time_start',
				$elm$json$Json$Encode$string(operation.Z))
			]));
};
var $author$project$Schedule$Commands$createScheduledOperation = F2(
	function (model, operation) {
		return A2(
			$author$project$Requests$post,
			model,
			{
				ak: $author$project$Schedule$Codecs$encodeScheduledOperation(operation),
				aG: $author$project$Schedule$Codecs$decodeScheduledOperation,
				a0: A2($elm$core$Basics$composeL, $author$project$Messages$ScheduleMsg, $author$project$Schedule$Messages$CreateScheduleOperationResponse),
				aA: 'schedule'
			});
	});
var $author$project$Utils$TimeSlots$hourMillis = 3600000;
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$Utils$TimeSlots$toHourInt = function (time) {
	return $author$project$Utils$TimeSlots$hourMillis * function (t) {
		return (t / $author$project$Utils$TimeSlots$hourMillis) | 0;
	}(
		$elm$time$Time$posixToMillis(time));
};
var $author$project$Schedule$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var schedule = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ci: schedule}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			case 1:
				return _Utils_Tuple2(
					model,
					$author$project$Schedule$Commands$listSchedule(model));
			case 2:
				var now = msg.a;
				var _v1 = model.cz;
				if (!_v1.$) {
					var range = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cz: A2($author$project$Schedule$Models$Now, now, range)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 3:
				var deltaHour = msg.a;
				var _v2 = function () {
					var _v3 = model.cz;
					if (!_v3.$) {
						var s = _v3.a;
						var r = _v3.b;
						return _Utils_Tuple2(
							$author$project$Utils$TimeSlots$toHourInt(s),
							r);
					} else {
						var s = _v3.a;
						var r = _v3.b;
						return _Utils_Tuple2(s, r);
					}
				}();
				var prevStart = _v2.a;
				var range = _v2.b;
				var newStart = prevStart + (deltaHour * $author$project$Utils$TimeSlots$hourMillis);
				var newWindow = A2($author$project$Schedule$Models$Window, newStart, range);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cz: newWindow}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var deltaSize = msg.a;
				var newWindow = function () {
					var _v4 = model.cz;
					if (!_v4.$) {
						var s = _v4.a;
						var r = _v4.b;
						return A2($author$project$Schedule$Models$Now, s, r + deltaSize);
					} else {
						var s = _v4.a;
						var r = _v4.b;
						return A2($author$project$Schedule$Models$Window, s, r + deltaSize);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cz: newWindow}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var newWindow = function () {
					var _v5 = model.cz;
					if (!_v5.$) {
						var s = _v5.a;
						var r = _v5.b;
						return A2($author$project$Schedule$Models$Now, s, r);
					} else {
						var s = _v5.a;
						var r = _v5.b;
						return A2(
							$author$project$Schedule$Models$Now,
							$elm$time$Time$millisToPosix(s),
							r);
					}
				}();
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cz: newWindow}),
					$author$project$Commands$initTime);
			case 6:
				var schema = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bA: schema}),
					$elm$core$Platform$Cmd$none);
			case 7:
				if (!msg.a.$) {
					var operation = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ci: A2($elm$core$List$cons, operation, model.ci)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			case 8:
				var op = msg.a;
				if (!op.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bt: op}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bt: $elm$core$Maybe$Nothing, cd: _List_Nil}),
						$elm$core$Platform$Cmd$none);
				}
			case 9:
				var op = msg.a;
				var cell = msg.b;
				var newScheduled = {aW: op.bp, aY: cell.a, a_: $elm$core$Dict$empty, Z: cell.b, bp: ''};
				var newProposed = $elm$core$List$singleton(newScheduled);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cd: newProposed}),
					$elm$core$Platform$Cmd$none);
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bt: $elm$core$Maybe$Nothing, cd: _List_Nil}),
					$elm$core$Platform$Cmd$batch(
						A2(
							$elm$core$List$map,
							$author$project$Schedule$Commands$createScheduledOperation(model),
							model.cd)));
			default:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cd: _List_Nil}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Shifts$Messages$ShiftCreationResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Utils$TimeSlots$showDay = function (day) {
	switch (day) {
		case 0:
			return 'Mon';
		case 1:
			return 'Tue';
		case 2:
			return 'Wed';
		case 3:
			return 'Thu';
		case 4:
			return 'Fri';
		case 5:
			return 'Sat';
		default:
			return 'Sun';
	}
};
var $author$project$Shifts$Codecs$encodeDayList = $elm$json$Json$Encode$list(
	A2($elm$core$Basics$composeL, $elm$json$Json$Encode$string, $author$project$Utils$TimeSlots$showDay));
var $author$project$Utils$Utils$tupleToList = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	return _List_fromArray(
		[a, b]);
};
var $author$project$Shifts$Codecs$encodeIntegerRange = A2(
	$elm$core$Basics$composeL,
	$elm$json$Json$Encode$list($elm$json$Json$Encode$int),
	$author$project$Utils$Utils$tupleToList);
var $elm$core$Tuple$mapBoth = F3(
	function (funcA, funcB, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			funcA(x),
			funcB(y));
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm_community$json_extra$Json$Encode$Extra$maybe = function (encoder) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(encoder),
		$elm$core$Maybe$withDefault($elm$json$Json$Encode$null));
};
var $author$project$Shifts$Codecs$encodeMaybePosixRange = $elm_community$json_extra$Json$Encode$Extra$maybe(
	A2(
		$elm$core$Basics$composeL,
		A2(
			$elm$core$Basics$composeL,
			$elm$json$Json$Encode$list($elm$json$Json$Encode$int),
			$author$project$Utils$Utils$tupleToList),
		A2($elm$core$Tuple$mapBoth, $elm$time$Time$posixToMillis, $elm$time$Time$posixToMillis)));
var $author$project$Shifts$Codecs$encodeCreateShiftRule = function (shift) {
	var properties = _List_fromArray(
		[
			_Utils_Tuple2(
			'name',
			$elm$json$Json$Encode$string(shift.b0)),
			_Utils_Tuple2(
			'days',
			$author$project$Shifts$Codecs$encodeDayList(shift.bG)),
			_Utils_Tuple2(
			'machine',
			A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, shift.aY)),
			_Utils_Tuple2(
			'valid_range',
			$author$project$Shifts$Codecs$encodeMaybePosixRange(shift.bq)),
			_Utils_Tuple2(
			'shift',
			$author$project$Shifts$Codecs$encodeIntegerRange(shift.bi)),
			_Utils_Tuple2(
			'priority',
			$elm$json$Json$Encode$int(shift.cc))
		]);
	return $elm$json$Json$Encode$object(properties);
};
var $author$project$Shifts$Commands$createShift = F2(
	function (model, shift) {
		return A2(
			$author$project$Requests$post,
			model,
			{
				ak: $author$project$Shifts$Codecs$encodeCreateShiftRule(shift),
				aG: $author$project$Shifts$Codecs$decodeShiftRule,
				a0: A2($elm$core$Basics$composeL, $author$project$Messages$ShiftsMsg, $author$project$Shifts$Messages$ShiftCreationResponse),
				aA: 'shifts'
			});
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {aE: col, bB: contextStack, a5: problem, be: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.be, s.aE, x, s.c));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.a),
			s.b) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.a);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.b, offset) < 0,
					0,
					{aE: col, c: s0.c, d: s0.d, b: offset, be: row, a: s0.a});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.b, s.be, s.aE, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.b, s1.b, s0.a),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.b, s.a);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{aE: 1, c: s.c, d: s.d, b: s.b + 1, be: s.be + 1, a: s.a}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{aE: s.aE + 1, c: s.c, d: s.d, b: newOffset, be: s.be, a: s.a}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.b, s.be, s.aE, s.a);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{aE: newCol, c: s.c, d: s.d, b: newOffset, be: newRow, a: s.a});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {aE: col, a5: problem, be: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.be, p.aE, p.a5);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{aE: 1, c: _List_Nil, d: 1, b: 0, be: 1, a: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Utils$TimeSlots$timeslotToPosix = function (timeslot) {
	return A2(
		$elm$core$Result$withDefault,
		$elm$time$Time$millisToPosix(0),
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(timeslot));
};
var $author$project$Shifts$Update$updateShiftForm = F2(
	function (msg, shift) {
		switch (msg.$) {
			case 0:
				var s = msg.a;
				return _Utils_update(
					shift,
					{b0: s});
			case 2:
				var machine = msg.a;
				if (machine === '') {
					return _Utils_update(
						shift,
						{aY: $elm$core$Maybe$Nothing});
				} else {
					return _Utils_update(
						shift,
						{
							aY: $elm$core$Maybe$Just(machine)
						});
				}
			case 1:
				var day = msg.a;
				var enabled = msg.b;
				if (enabled) {
					return _Utils_update(
						shift,
						{
							bG: A2($elm$core$List$cons, day, shift.bG)
						});
				} else {
					return _Utils_update(
						shift,
						{
							bG: A2(
								$elm$core$List$filter,
								function (d) {
									return !_Utils_eq(d, day);
								},
								shift.bG)
						});
				}
			case 3:
				var iso = msg.a;
				var as_posix = $author$project$Utils$TimeSlots$timeslotToPosix(iso);
				var _v3 = shift.bq;
				if (_v3.$ === 1) {
					return _Utils_update(
						shift,
						{
							bq: $elm$core$Maybe$Just(
								_Utils_Tuple2(
									as_posix,
									$elm$time$Time$millisToPosix(0)))
						});
				} else {
					var _v4 = _v3.a;
					var stop = _v4.b;
					return _Utils_update(
						shift,
						{
							bq: $elm$core$Maybe$Just(
								_Utils_Tuple2(as_posix, stop))
						});
				}
			case 4:
				var iso = msg.a;
				var as_posix = $author$project$Utils$TimeSlots$timeslotToPosix(iso);
				var _v5 = shift.bq;
				if (_v5.$ === 1) {
					return _Utils_update(
						shift,
						{
							bq: $elm$core$Maybe$Just(
								_Utils_Tuple2(
									$elm$time$Time$millisToPosix(0),
									as_posix))
						});
				} else {
					var _v6 = _v5.a;
					var start = _v6.a;
					return _Utils_update(
						shift,
						{
							bq: $elm$core$Maybe$Just(
								_Utils_Tuple2(start, as_posix))
						});
				}
			case 5:
				var hour = msg.a;
				return _Utils_update(
					shift,
					{
						bi: _Utils_Tuple2(hour, shift.bi.b)
					});
			case 6:
				var hour = msg.a;
				return _Utils_update(
					shift,
					{
						bi: _Utils_Tuple2(shift.bi.a, hour)
					});
			default:
				var p = msg.a;
				return _Utils_update(
					shift,
					{cc: p});
		}
	});
var $author$project$Shifts$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var shifts = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cm: shifts}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
			case 1:
				var shiftMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							cl: A2($author$project$Shifts$Update$updateShiftForm, shiftMsg, model.cl)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cl: $author$project$Shifts$Models$emptyShiftRule}),
					A2($author$project$Shifts$Commands$createShift, model, model.cl));
			default:
				if (!msg.a.$) {
					var shift = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								cm: A2($elm$core$List$cons, shift, model.cm)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return A2($author$project$UI$Utils$handleError, model, e);
				}
		}
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$Ports$toggleDarkTheme = _Platform_outgoingPort('toggleDarkTheme', $elm$json$Json$Encode$bool);
var $author$project$UI$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var target = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{b4: target}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var alert = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bQ: alert}),
					$elm$core$Platform$Cmd$none);
			default:
				var enabled = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bV: enabled}),
					$author$project$Ports$toggleDarkTheme(enabled));
		}
	});
var $author$project$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 12:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var url = msg.a;
				var _v1 = model.bn;
				if (!_v1.$) {
					return A2(
						$author$project$Update$urlUpdate,
						model,
						$author$project$Routes$urlToRoute(url));
				} else {
					return A2(
						$author$project$Update$guardRoutes,
						model,
						$author$project$Routes$urlToRoute(url));
				}
			case 1:
				var urlRequest = msg.a;
				if (!urlRequest.$) {
					var url = urlRequest.a;
					return _Utils_Tuple2(
						model,
						A2(
							$elm$browser$Browser$Navigation$pushUrl,
							model.ae,
							$elm$url$Url$toString(url)));
				} else {
					var href = urlRequest.a;
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(href));
				}
			case 2:
				var url = msg.a;
				return _Utils_Tuple2(
					model,
					A2($elm$browser$Browser$Navigation$pushUrl, model.ae, url));
			case 3:
				var uiMsg = msg.a;
				return A2($author$project$UI$Update$update, uiMsg, model);
			case 4:
				var authMsg = msg.a;
				return A2($author$project$Auth$Update$update, authMsg, model);
			case 5:
				var jobsMsg = msg.a;
				return A2($author$project$Jobs$Update$update, jobsMsg, model);
			case 6:
				var clientsMsg = msg.a;
				return A2($author$project$Clients$Update$update, clientsMsg, model);
			case 7:
				var machinesMsg = msg.a;
				return A2($author$project$Machines$Update$update, machinesMsg, model);
			case 8:
				var operationsMsg = msg.a;
				return A2($author$project$Operations$Update$update, operationsMsg, model);
			case 9:
				var departmentsMsg = msg.a;
				return A2($author$project$Departments$Update$update, departmentsMsg, model);
			case 10:
				var scheduleMsg = msg.a;
				return A2($author$project$Schedule$Update$update, scheduleMsg, model);
			default:
				var shiftMsg = msg.a;
				return A2($author$project$Shifts$Update$update, shiftMsg, model);
		}
	});
var $author$project$Routes$getCurrentRoute = A2(
	$elm$core$Basics$composeL,
	$elm$core$Maybe$withDefault($elm$core$Maybe$Nothing),
	$elm$core$List$head);
var $author$project$Auth$Messages$Password = function (a) {
	return {$: 2, a: a};
};
var $author$project$Auth$Messages$SubmitLogin = {$: 3};
var $author$project$Auth$Messages$Tenant = function (a) {
	return {$: 1, a: a};
};
var $author$project$Auth$Messages$Username = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$html$Html$form = _VirtualDom_node('form');
var $author$project$Auth$View$getLoginError = function (state) {
	if (state.$ === 2) {
		var message = state.a;
		return message;
	} else {
		return '';
	}
};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$section = _VirtualDom_node('section');
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Auth$View$view = F2(
	function (model, route) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('page-login')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('login-wrapper')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$form,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('login'),
									$elm$html$Html$Events$onSubmit(
									$author$project$Messages$AuthMsg($author$project$Auth$Messages$SubmitLogin))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$section,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('title')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$h3,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('Welcome')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Welcome to')
												])),
											$elm$html$Html$text('Janet Scheduler'),
											A2(
											$elm$html$Html$h5,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('hint')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Use your LDAP (VPN) login to get started.')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('login-group')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('company'),
													$elm$html$Html$Attributes$type_('text'),
													$elm$html$Html$Attributes$id('login_company'),
													$elm$html$Html$Attributes$placeholder('Company'),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$Messages$AuthMsg, $author$project$Auth$Messages$Tenant)),
													$elm$html$Html$Attributes$disabled(true),
													$elm$html$Html$Attributes$value(model.cr)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('username'),
													$elm$html$Html$Attributes$type_('text'),
													$elm$html$Html$Attributes$id('login_username'),
													$elm$html$Html$Attributes$placeholder('Username'),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$Messages$AuthMsg, $author$project$Auth$Messages$Username)),
													$elm$html$Html$Attributes$value(model.cw)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('password'),
													$elm$html$Html$Attributes$type_('password'),
													$elm$html$Html$Attributes$id('login_password'),
													$elm$html$Html$Attributes$placeholder('Password'),
													$elm$html$Html$Events$onInput(
													A2($elm$core$Basics$composeL, $author$project$Messages$AuthMsg, $author$project$Auth$Messages$Password)),
													$elm$html$Html$Attributes$value(model.b8)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$type_('submit'),
													$elm$html$Html$Attributes$class('btn btn-primary'),
													$elm$html$Html$Attributes$disabled(
													_Utils_eq(model.as, $author$project$Auth$Models$LoginProcessing))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$span,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('spinner spinner-inline mar-r-md'),
															$elm$html$Html$Attributes$classList(
															_List_fromArray(
																[
																	_Utils_Tuple2(
																	'hidden',
																	!_Utils_eq(model.as, $author$project$Auth$Models$LoginProcessing))
																]))
														]),
													_List_Nil),
													A2(
													$elm$html$Html$span,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(' Login')
														]))
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('error active'),
													$elm$html$Html$Attributes$classList(
													_List_fromArray(
														[
															_Utils_Tuple2(
															'hidden',
															$author$project$Auth$View$getLoginError(model.as) === '')
														]))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text(
													$author$project$Auth$View$getLoginError(model.as))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$View$view404 = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			$elm$html$Html$text('404 Not Found')
		]));
var $author$project$View$viewLoading = A2(
	$elm$html$Html$div,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('spinner')
				]),
			_List_Nil)
		]));
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $author$project$UI$View$darkThemeAttribute = function (enabled) {
	var theme = function () {
		if (enabled) {
			return 'dark';
		} else {
			return 'light';
		}
	}();
	return A2($elm$html$Html$Attributes$attribute, 'data-theme', theme);
};
var $author$project$View$showModal = F2(
	function (model, route) {
		return $elm$core$Maybe$Nothing;
	});
var $author$project$View$showModalBackdrop = F2(
	function (model, route) {
		return $elm$core$Maybe$Nothing;
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$endsWith = _String_endsWith;
var $author$project$Utils$Utils$flip = F3(
	function (func, a, b) {
		return A2(func, b, a);
	});
var $author$project$Utils$Uri$lookupId = F3(
	function (resource, id, store) {
		var key = $elm$core$List$head(
			A2(
				$elm$core$List$filter,
				$elm$core$String$endsWith('/' + (resource + ('/' + (id + '/')))),
				$elm$core$Dict$keys(store)));
		return A2(
			$elm$core$Maybe$andThen,
			A2($author$project$Utils$Utils$flip, $elm$core$Dict$get, store),
			key);
	});
var $author$project$Clients$Messages$ClientFormMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Clients$Messages$SubmitClient = {$: 2};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Utils$Clarity$clarityLabel = function (_v0) {
	var id = _v0.a;
	var labelText = _v0.b;
	return A2(
		$elm$html$Html$label,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$for(id),
				$elm$html$Html$Attributes$class('clr-control-label')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(labelText)
			]));
};
var $author$project$Utils$Clarity$clarityFormControl = F2(
	function (idLabel, inner) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('clr-form-control')
				]),
			_List_fromArray(
				[
					$author$project$Utils$Clarity$clarityLabel(idLabel),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('clr-control-container')
						]),
					inner)
				]));
	});
var $author$project$Utils$Clarity$clarityInput = function (attrs) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('clr-input-wrapper')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('clr-input')
						]),
					attrs),
				_List_Nil)
			]));
};
var $author$project$Utils$Clarity$clarityTextControl = F2(
	function (idLabel, attrs) {
		var inputId = idLabel.a;
		return A2(
			$author$project$Utils$Clarity$clarityFormControl,
			idLabel,
			_List_fromArray(
				[
					$author$project$Utils$Clarity$clarityInput(
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(inputId),
								$elm$html$Html$Attributes$type_('text')
							]),
						attrs))
				]));
	});
var $author$project$Utils$Clarity$gridFullWidthColumn = $elm$html$Html$div(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('clr-col-md-12')
		]));
var $author$project$Utils$Clarity$gridRow = $elm$html$Html$div(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('clr-row')
		]));
var $author$project$Utils$Clarity$gridFullBlock = A2(
	$elm$core$Basics$composeR,
	$author$project$Utils$Clarity$gridFullWidthColumn,
	A2($elm$core$Basics$composeR, $elm$core$List$singleton, $author$project$Utils$Clarity$gridRow));
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Utils$Utils$validateForm = F2(
	function (validations, form) {
		return A2(
			$elm$core$List$all,
			function (pred) {
				return pred(form);
			},
			validations);
	});
var $author$project$Clients$View$viewClientCreate = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('/clients')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Clients')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('New Client')
									]))
							]))
					])),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clr-form clr-form-compact'),
								$elm$html$Html$Events$onSubmit(
								$author$project$Messages$ClientsMsg($author$project$Clients$Messages$SubmitClient))
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Utils$Clarity$clarityTextControl,
								_Utils_Tuple2('client_name', 'Client Name'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(model.N.b0),
										$elm$html$Html$Events$onInput(
										A2($elm$core$Basics$composeL, $author$project$Messages$ClientsMsg, $author$project$Clients$Messages$ClientFormMsg))
									])),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('submit'),
										$elm$html$Html$Attributes$class('btn btn-primary'),
										$elm$html$Html$Attributes$disabled(
										!A2(
											$author$project$Utils$Utils$validateForm,
											_List_fromArray(
												[
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.b0;
													},
													$elm$core$Basics$neq(''))
												]),
											model.N))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Create Client')
									]))
							]))
					]))
			]));
};
var $author$project$Clients$View$viewClientDetail = F2(
	function (model, client) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Utils$Clarity$gridFullBlock(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('/clients')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Clients')
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(client.b0)
										]))
								]))
						]))
				]));
	});
var $author$project$Utils$Clarity$gridHalfWidthColumn = $elm$html$Html$div(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('clr-col-md-6')
		]));
var $author$project$Utils$Clarity$gridTwoColumns = A2(
	$elm$core$Basics$composeR,
	$author$project$Utils$Utils$tupleToList,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map($author$project$Utils$Clarity$gridHalfWidthColumn),
		$author$project$Utils$Clarity$gridRow));
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $author$project$Utils$Uri$linkFromUri = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$split('/'),
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$reverse,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$filter(
				$elm$core$Basics$neq('')),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$take(2),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$reverse,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$String$join('/'),
						$elm$core$Basics$append('/')))))));
var $lattyware$elm_fontawesome$FontAwesome$Icon$Icon = F5(
	function (prefix, name, width, height, paths) {
		return {C: height, b0: name, b9: paths, ca: prefix, L: width};
	});
var $lattyware$elm_fontawesome$FontAwesome$Solid$plus = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'plus',
	448,
	512,
	_List_fromArray(
		['M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z']));
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $lattyware$elm_fontawesome$FontAwesome$Icon$Presentation = $elm$core$Basics$identity;
var $lattyware$elm_fontawesome$FontAwesome$Icon$present = function (icon) {
	return {ac: _List_Nil, aR: icon, ap: $elm$core$Maybe$Nothing, V: $elm$core$Maybe$Nothing, aw: 'img', cs: $elm$core$Maybe$Nothing, ah: _List_Nil};
};
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$add = F2(
	function (transform, combined) {
		switch (transform.$) {
			case 0:
				var direction = transform.a;
				var amount = function () {
					if (!direction.$) {
						var by = direction.a;
						return by;
					} else {
						var by = direction.a;
						return -by;
					}
				}();
				return _Utils_update(
					combined,
					{bj: combined.bj + amount});
			case 1:
				var direction = transform.a;
				var _v2 = function () {
					switch (direction.$) {
						case 0:
							var by = direction.a;
							return _Utils_Tuple2(0, -by);
						case 1:
							var by = direction.a;
							return _Utils_Tuple2(0, by);
						case 2:
							var by = direction.a;
							return _Utils_Tuple2(-by, 0);
						default:
							var by = direction.a;
							return _Utils_Tuple2(by, 0);
					}
				}();
				var x = _v2.a;
				var y = _v2.b;
				return _Utils_update(
					combined,
					{ai: combined.ai + x, aj: combined.aj + y});
			case 2:
				var rotation = transform.a;
				return _Utils_update(
					combined,
					{ch: combined.ch + rotation});
			default:
				if (!transform.a) {
					var _v4 = transform.a;
					return _Utils_update(
						combined,
						{bO: true});
				} else {
					var _v5 = transform.a;
					return _Utils_update(
						combined,
						{bP: true});
				}
		}
	});
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$baseSize = 16;
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaninglessTransform = {bO: false, bP: false, ch: 0, bj: $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$baseSize, ai: 0, aj: 0};
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$combine = function (transforms) {
	return A3($elm$core$List$foldl, $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$add, $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaninglessTransform, transforms);
};
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaningfulTransform = function (transforms) {
	var combined = $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$combine(transforms);
	return _Utils_eq(combined, $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaninglessTransform) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(combined);
};
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $elm$svg$Svg$title = $elm$svg$Svg$trustedNode('title');
var $lattyware$elm_fontawesome$FontAwesome$Icon$titledContents = F3(
	function (titleId, contents, title) {
		return A2(
			$elm$core$List$cons,
			A2(
				$elm$svg$Svg$title,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$id(titleId)
					]),
				_List_fromArray(
					[
						$elm$svg$Svg$text(title)
					])),
			contents);
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$transformForSvg = F3(
	function (containerWidth, iconWidth, transform) {
		var path = 'translate(' + ($elm$core$String$fromFloat((iconWidth / 2) * (-1)) + ' -256)');
		var outer = 'translate(' + ($elm$core$String$fromFloat(containerWidth / 2) + ' 256)');
		var innerTranslate = 'translate(' + ($elm$core$String$fromFloat(transform.ai * 32) + (',' + ($elm$core$String$fromFloat(transform.aj * 32) + ') ')));
		var innerRotate = 'rotate(' + ($elm$core$String$fromFloat(transform.ch) + ' 0 0)');
		var flipY = transform.bP ? (-1) : 1;
		var scaleY = (transform.bj / $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$baseSize) * flipY;
		var flipX = transform.bO ? (-1) : 1;
		var scaleX = (transform.bj / $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$baseSize) * flipX;
		var innerScale = 'scale(' + ($elm$core$String$fromFloat(scaleX) + (', ' + ($elm$core$String$fromFloat(scaleY) + ') ')));
		return {
			aS: $elm$svg$Svg$Attributes$transform(
				_Utils_ap(
					innerTranslate,
					_Utils_ap(innerScale, innerRotate))),
			V: $elm$svg$Svg$Attributes$transform(outer),
			a2: $elm$svg$Svg$Attributes$transform(path)
		};
	});
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$x = _VirtualDom_attribute('x');
var $elm$svg$Svg$Attributes$y = _VirtualDom_attribute('y');
var $lattyware$elm_fontawesome$FontAwesome$Icon$allSpace = _List_fromArray(
	[
		$elm$svg$Svg$Attributes$x('0'),
		$elm$svg$Svg$Attributes$y('0'),
		$elm$svg$Svg$Attributes$width('100%'),
		$elm$svg$Svg$Attributes$height('100%')
	]);
var $elm$svg$Svg$clipPath = $elm$svg$Svg$trustedNode('clipPath');
var $elm$svg$Svg$Attributes$clipPath = _VirtualDom_attribute('clip-path');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $lattyware$elm_fontawesome$FontAwesome$Svg$Internal$corePath = F2(
	function (attrs, d) {
		return A2(
			$elm$svg$Svg$path,
			A2(
				$elm$core$List$cons,
				$elm$svg$Svg$Attributes$fill('currentColor'),
				A2(
					$elm$core$List$cons,
					$elm$svg$Svg$Attributes$d(d),
					attrs)),
			_List_Nil);
	});
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $lattyware$elm_fontawesome$FontAwesome$Svg$Internal$corePaths = F2(
	function (attrs, icon) {
		var _v0 = icon.b9;
		if (!_v0.b) {
			return A2($lattyware$elm_fontawesome$FontAwesome$Svg$Internal$corePath, attrs, '');
		} else {
			if (!_v0.b.b) {
				var only = _v0.a;
				return A2($lattyware$elm_fontawesome$FontAwesome$Svg$Internal$corePath, attrs, only);
			} else {
				var secondary = _v0.a;
				var _v1 = _v0.b;
				var primary = _v1.a;
				return A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('fa-group')
						]),
					_List_fromArray(
						[
							A2(
							$lattyware$elm_fontawesome$FontAwesome$Svg$Internal$corePath,
							A2(
								$elm$core$List$cons,
								$elm$svg$Svg$Attributes$class('fa-secondary'),
								attrs),
							secondary),
							A2(
							$lattyware$elm_fontawesome$FontAwesome$Svg$Internal$corePath,
							A2(
								$elm$core$List$cons,
								$elm$svg$Svg$Attributes$class('fa-primary'),
								attrs),
							primary)
						]));
			}
		}
	});
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$svg$Svg$mask = $elm$svg$Svg$trustedNode('mask');
var $elm$svg$Svg$Attributes$mask = _VirtualDom_attribute('mask');
var $elm$svg$Svg$Attributes$maskContentUnits = _VirtualDom_attribute('maskContentUnits');
var $elm$svg$Svg$Attributes$maskUnits = _VirtualDom_attribute('maskUnits');
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $lattyware$elm_fontawesome$FontAwesome$Icon$viewMaskedWithTransform = F4(
	function (id, transforms, inner, outer) {
		var maskInnerGroup = A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[transforms.aS]),
			_List_fromArray(
				[
					A2(
					$lattyware$elm_fontawesome$FontAwesome$Svg$Internal$corePaths,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$fill('black'),
							transforms.a2
						]),
					inner)
				]));
		var maskId = 'mask-' + (inner.b0 + ('-' + id));
		var maskTag = A2(
			$elm$svg$Svg$mask,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$id(maskId),
						$elm$svg$Svg$Attributes$maskUnits('userSpaceOnUse'),
						$elm$svg$Svg$Attributes$maskContentUnits('userSpaceOnUse')
					]),
				$lattyware$elm_fontawesome$FontAwesome$Icon$allSpace),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$rect,
					A2(
						$elm$core$List$cons,
						$elm$svg$Svg$Attributes$fill('white'),
						$lattyware$elm_fontawesome$FontAwesome$Icon$allSpace),
					_List_Nil),
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[transforms.V]),
					_List_fromArray(
						[maskInnerGroup]))
				]));
		var clipId = 'clip-' + (outer.b0 + ('-' + id));
		var defs = A2(
			$elm$svg$Svg$defs,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$clipPath,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$id(clipId)
						]),
					_List_fromArray(
						[
							A2($lattyware$elm_fontawesome$FontAwesome$Svg$Internal$corePaths, _List_Nil, outer)
						])),
					maskTag
				]));
		return _List_fromArray(
			[
				defs,
				A2(
				$elm$svg$Svg$rect,
				$elm$core$List$concat(
					_List_fromArray(
						[
							_List_fromArray(
							[
								$elm$svg$Svg$Attributes$fill('currentColor'),
								$elm$svg$Svg$Attributes$clipPath('url(#' + (clipId + ')')),
								$elm$svg$Svg$Attributes$mask('url(#' + (maskId + ')'))
							]),
							$lattyware$elm_fontawesome$FontAwesome$Icon$allSpace
						])),
				_List_Nil)
			]);
	});
var $lattyware$elm_fontawesome$FontAwesome$Icon$viewWithTransform = F2(
	function (transforms, icon) {
		if (!transforms.$) {
			var ts = transforms.a;
			return A2(
				$elm$svg$Svg$g,
				_List_fromArray(
					[ts.V]),
				_List_fromArray(
					[
						A2(
						$elm$svg$Svg$g,
						_List_fromArray(
							[ts.aS]),
						_List_fromArray(
							[
								A2(
								$lattyware$elm_fontawesome$FontAwesome$Svg$Internal$corePaths,
								_List_fromArray(
									[ts.a2]),
								icon)
							]))
					]));
		} else {
			return A2($lattyware$elm_fontawesome$FontAwesome$Svg$Internal$corePaths, _List_Nil, icon);
		}
	});
var $lattyware$elm_fontawesome$FontAwesome$Icon$internalView = function (_v0) {
	var icon = _v0.aR;
	var attributes = _v0.ac;
	var transforms = _v0.ah;
	var role = _v0.aw;
	var id = _v0.ap;
	var title = _v0.cs;
	var outer = _v0.V;
	var alwaysId = A2($elm$core$Maybe$withDefault, icon.b0, id);
	var titleId = alwaysId + '-title';
	var semantics = A2(
		$elm$core$Maybe$withDefault,
		A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$always(
				A2($elm$html$Html$Attributes$attribute, 'aria-labelledby', titleId)),
			title));
	var _v1 = A2(
		$elm$core$Maybe$withDefault,
		_Utils_Tuple2(icon.L, icon.C),
		A2(
			$elm$core$Maybe$map,
			function (o) {
				return _Utils_Tuple2(o.L, o.C);
			},
			outer));
	var width = _v1.a;
	var height = _v1.b;
	var classes = _List_fromArray(
		[
			'svg-inline--fa',
			'fa-' + icon.b0,
			'fa-w-' + $elm$core$String$fromInt(
			$elm$core$Basics$ceiling((width / height) * 16))
		]);
	var svgTransform = A2(
		$elm$core$Maybe$map,
		A2($lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$transformForSvg, width, icon.L),
		$lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaningfulTransform(transforms));
	var contents = function () {
		var resolvedSvgTransform = A2(
			$elm$core$Maybe$withDefault,
			A3($lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$transformForSvg, width, icon.L, $lattyware$elm_fontawesome$FontAwesome$Transforms$Internal$meaninglessTransform),
			svgTransform);
		return A2(
			$elm$core$Maybe$withDefault,
			_List_fromArray(
				[
					A2($lattyware$elm_fontawesome$FontAwesome$Icon$viewWithTransform, svgTransform, icon)
				]),
			A2(
				$elm$core$Maybe$map,
				A3($lattyware$elm_fontawesome$FontAwesome$Icon$viewMaskedWithTransform, alwaysId, resolvedSvgTransform, icon),
				outer));
	}();
	var potentiallyTitledContents = A2(
		$elm$core$Maybe$withDefault,
		contents,
		A2(
			$elm$core$Maybe$map,
			A2($lattyware$elm_fontawesome$FontAwesome$Icon$titledContents, titleId, contents),
			title));
	return A2(
		$elm$svg$Svg$svg,
		$elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', role),
						A2($elm$html$Html$Attributes$attribute, 'xmlns', 'http://www.w3.org/2000/svg'),
						$elm$svg$Svg$Attributes$viewBox(
						'0 0 ' + ($elm$core$String$fromInt(width) + (' ' + $elm$core$String$fromInt(height)))),
						semantics
					]),
					A2($elm$core$List$map, $elm$svg$Svg$Attributes$class, classes),
					attributes
				])),
		potentiallyTitledContents);
};
var $lattyware$elm_fontawesome$FontAwesome$Icon$view = function (presentation) {
	return $lattyware$elm_fontawesome$FontAwesome$Icon$internalView(presentation);
};
var $lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon = A2($elm$core$Basics$composeR, $lattyware$elm_fontawesome$FontAwesome$Icon$present, $lattyware$elm_fontawesome$FontAwesome$Icon$view);
var $elm$html$Html$table = _VirtualDom_node('table');
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $author$project$Utils$Clarity$viewTable = F2(
	function (fields, data) {
		var fieldNames = A2($elm$core$List$map, $elm$core$Tuple$first, fields);
		var accessors = A2($elm$core$List$map, $elm$core$Tuple$second, fields);
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$tr,
							_List_Nil,
							A2(
								$elm$core$List$map,
								function (f) {
									return A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(f)
											]));
								},
								fieldNames))
						])),
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (row) {
							return A2(
								$elm$html$Html$tr,
								_List_Nil,
								A2(
									$elm$core$List$map,
									function (acc) {
										return A2(
											$elm$html$Html$td,
											_List_Nil,
											_List_fromArray(
												[
													acc(row)
												]));
									},
									accessors));
						},
						data))
				]));
	});
var $author$project$Clients$View$viewClientList = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridTwoColumns(
				_Utils_Tuple2(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mar-none')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Clients')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-right')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-primary'),
											$elm$html$Html$Attributes$href('/clients/create')
										]),
									_List_fromArray(
										[
											$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$plus),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mar-l-sm')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Create Client')
												]))
										]))
								]))
						]))),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$author$project$Utils$Clarity$viewTable,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Name',
								function (client) {
									return A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$href(
												$author$project$Utils$Uri$linkFromUri(client.bp))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(client.b0)
											]));
								}),
								_Utils_Tuple2(
								'# Jobs',
								A2(
									$elm$core$Basics$composeR,
									function (d) {
										return $elm$core$List$length(_List_Nil);
									},
									A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text)))
							]),
						$elm$core$Dict$values(model.al))
					]))
			]));
};
var $author$project$Clients$View$view = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return $author$project$Clients$View$viewClientList(model);
			case 1:
				return $author$project$Clients$View$viewClientCreate(model);
			default:
				var client_id = route.a;
				var _v1 = A3($author$project$Utils$Uri$lookupId, 'clients', client_id, model.al);
				if (!_v1.$) {
					var client = _v1.a;
					return A2($author$project$Clients$View$viewClientDetail, model, client);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Client Not Found')
							]));
				}
		}
	});
var $author$project$Departments$Messages$DepartmentFormMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Departments$Messages$SubmitDepartment = {$: 2};
var $author$project$Departments$View$viewDepartmentCreate = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('/departments')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Departments')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('New Department')
									]))
							]))
					])),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clr-form clr-form-compact'),
								$elm$html$Html$Events$onSubmit(
								$author$project$Messages$DepartmentsMsg($author$project$Departments$Messages$SubmitDepartment))
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Utils$Clarity$clarityTextControl,
								_Utils_Tuple2('department_name', 'Department Name'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(model.O.b0),
										$elm$html$Html$Events$onInput(
										A2($elm$core$Basics$composeL, $author$project$Messages$DepartmentsMsg, $author$project$Departments$Messages$DepartmentFormMsg))
									])),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('submit'),
										$elm$html$Html$Attributes$class('btn btn-primary'),
										$elm$html$Html$Attributes$disabled(
										!A2(
											$author$project$Utils$Utils$validateForm,
											_List_fromArray(
												[
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.b0;
													},
													$elm$core$Basics$neq(''))
												]),
											model.O))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Create Department')
									]))
							]))
					]))
			]));
};
var $author$project$Utils$Uri$getRelatedAttr = F3(
	function (this_attr, that_attr, collection) {
		return A2(
			$elm$core$Basics$composeL,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					$elm$core$Maybe$withDefault(''),
					$elm$core$Maybe$map(that_attr)),
				A2($author$project$Utils$Utils$flip, $elm$core$Dict$get, collection)),
			this_attr);
	});
var $author$project$Departments$View$viewDepartmentDetail = F2(
	function (model, department) {
		var machines = A2(
			$elm$core$List$filter,
			function (r) {
				return _Utils_eq(r.aH, department.bp);
			},
			$elm$core$Dict$values(model.aZ));
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Utils$Clarity$gridFullBlock(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('/departments')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Departments')
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(department.b0)
										]))
								]))
						])),
					$author$project$Utils$Clarity$gridFullBlock(
					_List_fromArray(
						[
							A2(
							$author$project$Utils$Clarity$viewTable,
							_List_fromArray(
								[
									_Utils_Tuple2(
									'Machine',
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.b0;
										},
										$elm$html$Html$text)),
									_Utils_Tuple2(
									'Operations',
									A2(
										$elm$core$Basics$composeR,
										A3(
											$author$project$Utils$Uri$getRelatedAttr,
											function ($) {
												return $.b5;
											},
											function ($) {
												return $.b0;
											},
											model.b7),
										$elm$html$Html$text))
								]),
							machines)
						]))
				]));
	});
var $author$project$Departments$View$viewDepartmentList = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridTwoColumns(
				_Utils_Tuple2(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mar-none')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Departments')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-right')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-primary'),
											$elm$html$Html$Attributes$href('/departments/create')
										]),
									_List_fromArray(
										[
											$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$plus),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mar-l-sm')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Create Department')
												]))
										]))
								]))
						]))),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$author$project$Utils$Clarity$viewTable,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Name',
								function (department) {
									return A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$href(
												$author$project$Utils$Uri$linkFromUri(department.bp))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(department.b0)
											]));
								}),
								_Utils_Tuple2(
								'# Machines',
								A2(
									$elm$core$Basics$composeR,
									function (d) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (m) {
													return _Utils_eq(m.aH, d.bp);
												},
												$elm$core$Dict$values(model.aZ)));
									},
									A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text)))
							]),
						$elm$core$Dict$values(model.am))
					]))
			]));
};
var $author$project$Departments$View$view = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return $author$project$Departments$View$viewDepartmentList(model);
			case 1:
				return $author$project$Departments$View$viewDepartmentCreate(model);
			default:
				var department_id = route.a;
				var _v1 = A3($author$project$Utils$Uri$lookupId, 'departments', department_id, model.am);
				if (!_v1.$) {
					var department = _v1.a;
					return A2($author$project$Departments$View$viewDepartmentDetail, model, department);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Department Not Found')
							]));
				}
		}
	});
var $author$project$Jobs$Messages$JobClient = function (a) {
	return {$: 1, a: a};
};
var $author$project$Jobs$Messages$JobDateCreated = function (a) {
	return {$: 3, a: a};
};
var $author$project$Jobs$Messages$JobDateDue = function (a) {
	return {$: 4, a: a};
};
var $author$project$Jobs$Messages$JobDescription = function (a) {
	return {$: 2, a: a};
};
var $author$project$Jobs$Messages$JobFormMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Jobs$Messages$JobName = function (a) {
	return {$: 0, a: a};
};
var $author$project$Jobs$Messages$JobTotalCount = function (a) {
	return {$: 5, a: a};
};
var $author$project$Jobs$Messages$JobValue = function (a) {
	return {$: 6, a: a};
};
var $author$project$Jobs$Messages$SubmitJob = {$: 7};
var $author$project$Utils$Clarity$clarityNumberControl = F2(
	function (idLabel, attrs) {
		var inputId = idLabel.a;
		return A2(
			$author$project$Utils$Clarity$clarityFormControl,
			idLabel,
			_List_fromArray(
				[
					$author$project$Utils$Clarity$clarityInput(
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(inputId),
								$elm$html$Html$Attributes$type_('number')
							]),
						attrs))
				]));
	});
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$Utils$Clarity$claritySelect = F4(
	function (attrs, options, _default, selected_) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('clr-select-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$select,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clr-select')
							]),
						attrs),
					A2(
						$elm$core$List$cons,
						A2(
							$elm$html$Html$option,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(_default)
								])),
						A2(
							$elm$core$List$map,
							function (_v0) {
								var val = _v0.a;
								var str = _v0.b;
								return A2(
									$elm$html$Html$option,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(val),
											$elm$html$Html$Attributes$selected(
											_Utils_eq(val, selected_))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(str)
										]));
							},
							options)))
				]));
	});
var $author$project$Utils$Clarity$claritySelectControl = F5(
	function (idLabel, attrs, options, _default, selected) {
		var inputId = idLabel.a;
		return A2(
			$author$project$Utils$Clarity$clarityFormControl,
			idLabel,
			_List_fromArray(
				[
					A4(
					$author$project$Utils$Clarity$claritySelect,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(inputId)
							]),
						attrs),
					options,
					_default,
					selected)
				]));
	});
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Utils$Clarity$clarityTextarea = F2(
	function (attrs, value) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('clr-textarea-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$textarea,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clr-textarea')
							]),
						attrs),
					_List_fromArray(
						[
							$elm$html$Html$text(value)
						]))
				]));
	});
var $author$project$Utils$Clarity$clarityTextareaControl = F3(
	function (idLabel, attrs, value) {
		var inputId = idLabel.a;
		return A2(
			$author$project$Utils$Clarity$clarityFormControl,
			idLabel,
			_List_fromArray(
				[
					A2(
					$author$project$Utils$Clarity$clarityTextarea,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(inputId)
							]),
						attrs),
					value)
				]));
	});
var $author$project$Jobs$View$viewJobCreate = function (model) {
	var client_options = A2(
		$elm$core$List$map,
		function (c) {
			return _Utils_Tuple2(c.bp, c.b0);
		},
		$elm$core$Dict$values(model.al));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('/jobs')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Jobs')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('New Job')
									]))
							]))
					])),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clr-form clr-form-compact'),
								$elm$html$Html$Events$onSubmit(
								$author$project$Messages$JobsMsg($author$project$Jobs$Messages$SubmitJob))
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Utils$Clarity$clarityTextControl,
								_Utils_Tuple2('job_name', 'Job Number'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(model.P.b0),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobFormMsg),
											$author$project$Jobs$Messages$JobName))
									])),
								A5(
								$author$project$Utils$Clarity$claritySelectControl,
								_Utils_Tuple2('clients', 'Client'),
								_List_fromArray(
									[
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobFormMsg),
											$author$project$Jobs$Messages$JobClient))
									]),
								client_options,
								'Select a Client',
								model.P.bz),
								A3(
								$author$project$Utils$Clarity$clarityTextareaControl,
								_Utils_Tuple2('description', 'Description'),
								_List_fromArray(
									[
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobFormMsg),
											$author$project$Jobs$Messages$JobDescription))
									]),
								A2($elm$core$Maybe$withDefault, '', model.P.bI)),
								A2(
								$author$project$Utils$Clarity$clarityTextControl,
								_Utils_Tuple2('date_created', 'Date Created'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(model.P.bE),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobFormMsg),
											$author$project$Jobs$Messages$JobDateCreated))
									])),
								A2(
								$author$project$Utils$Clarity$clarityTextControl,
								_Utils_Tuple2('date_due', 'Date Due'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(model.P.bF),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobFormMsg),
											$author$project$Jobs$Messages$JobDateDue))
									])),
								A2(
								$author$project$Utils$Clarity$clarityNumberControl,
								_Utils_Tuple2('total_count', 'Total Count'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromInt(model.P.ct)),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobFormMsg),
											$author$project$Jobs$Messages$JobTotalCount))
									])),
								A2(
								$author$project$Utils$Clarity$clarityNumberControl,
								_Utils_Tuple2('value', 'Value'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromInt(model.P.cx)),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobFormMsg),
											$author$project$Jobs$Messages$JobValue))
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('submit'),
										$elm$html$Html$Attributes$class('btn btn-primary'),
										$elm$html$Html$Attributes$disabled(
										!A2(
											$author$project$Utils$Utils$validateForm,
											_List_fromArray(
												[
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.b0;
													},
													$elm$core$Basics$neq('')),
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.bz;
													},
													$elm$core$Basics$neq('')),
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.bE;
													},
													$elm$core$Basics$neq('')),
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.bF;
													},
													$elm$core$Basics$neq('')),
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.ct;
													},
													$elm$core$Basics$lt(0)),
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.cx;
													},
													$elm$core$Basics$lt(0))
												]),
											model.P))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Create Job')
									]))
							]))
					]))
			]));
};
var $author$project$Jobs$View$viewJobDetailHeader = function (job) {
	return $author$project$Utils$Clarity$gridFullBlock(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('/jobs')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Jobs')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(job.b0)
							]))
					]))
			]));
};
var $author$project$Utils$Clarity$claritySimpleCard = F2(
	function (header, body) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('card')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('card-header')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(header)
						])),
					body
				]));
	});
var $author$project$Utils$Clarity$clarityTextCard = F2(
	function (header, body) {
		return A2(
			$author$project$Utils$Clarity$claritySimpleCard,
			header,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('card-block')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('card-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(body)
							]))
					])));
	});
var $author$project$Jobs$View$viewJobInfoDescription = F2(
	function (model, job) {
		return $author$project$Utils$Clarity$gridTwoColumns(
			_Utils_Tuple2(
				_List_fromArray(
					[
						A2(
						$author$project$Utils$Clarity$claritySimpleCard,
						'Job Info',
						A2(
							$elm$html$Html$table,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('table table-vertical mar-t-none')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$tbody,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Client')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															A4(
																$author$project$Utils$Uri$getRelatedAttr,
																function ($) {
																	return $.bz;
																},
																function ($) {
																	return $.b0;
																},
																model.al,
																job))
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Date Created')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(job.bE)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Date Due')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(job.bF)
														]))
												])),
											A2(
											$elm$html$Html$tr,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$th,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Total Count')
														])),
													A2(
													$elm$html$Html$td,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text(
															$elm$core$String$fromInt(job.ct))
														]))
												]))
										]))
								])))
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Utils$Clarity$clarityTextCard,
						'Job Description',
						A2($elm$core$Maybe$withDefault, '', job.bI))
					])));
	});
var $author$project$Jobs$Messages$Conversion = function (a) {
	return {$: 3, a: a};
};
var $author$project$Jobs$Messages$Count = function (a) {
	return {$: 1, a: a};
};
var $author$project$Jobs$Messages$Dependencies = function (a) {
	return {$: 6, a: a};
};
var $author$project$Jobs$Messages$JobOperationFormMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Jobs$Messages$Rate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Jobs$Messages$SelectOperation = function (a) {
	return {$: 0, a: a};
};
var $author$project$Jobs$Messages$SetupTime = function (a) {
	return {$: 4, a: a};
};
var $author$project$Jobs$Messages$SubmitJobOperation = function (a) {
	return {$: 4, a: a};
};
var $author$project$Jobs$Messages$TeardownTime = function (a) {
	return {$: 5, a: a};
};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $author$project$Utils$Clarity$clarityCheckbox = F2(
	function (idLabel, attrs) {
		var inputId = idLabel.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('clr-checkbox-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(inputId),
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Attributes$class('clr-checkbox')
							]),
						attrs),
					_List_Nil),
					$author$project$Utils$Clarity$clarityLabel(idLabel)
				]));
	});
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $author$project$Jobs$View$viewJobOperationForm = F3(
	function (model, job, operations) {
		var operation_options = A2(
			$elm$core$List$map,
			function (o) {
				return _Utils_Tuple2(o.bp, o.b0);
			},
			$elm$core$Dict$values(model.b7));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('hidden', !(!job.W))
						]))
				]),
			_List_fromArray(
				[
					$author$project$Utils$Clarity$gridFullBlock(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h4,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Add Operation')
								])),
							A2(
							$elm$html$Html$form,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('clr-form clr-form-compact'),
									$elm$html$Html$Events$onSubmit(
									$author$project$Messages$JobsMsg(
										$author$project$Jobs$Messages$SubmitJobOperation(job.bp)))
								]),
							_List_fromArray(
								[
									A5(
									$author$project$Utils$Clarity$claritySelectControl,
									_Utils_Tuple2('operation', 'Operation'),
									_List_fromArray(
										[
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobOperationFormMsg),
												$author$project$Jobs$Messages$SelectOperation))
										]),
									operation_options,
									'Select an Operation',
									model.Q.b5),
									A2(
									$author$project$Utils$Clarity$clarityNumberControl,
									_Utils_Tuple2('count', 'Piece Count'),
									_List_fromArray(
										[
											$elm$html$Html$Attributes$min('1'),
											$elm$html$Html$Attributes$value(
											$elm$core$String$fromInt(model.Q.bC)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobOperationFormMsg),
												$author$project$Jobs$Messages$Count))
										])),
									A2(
									$author$project$Utils$Clarity$clarityNumberControl,
									_Utils_Tuple2('rate', 'Estimated Rate (hr)'),
									_List_fromArray(
										[
											$elm$html$Html$Attributes$min('1'),
											$elm$html$Html$Attributes$value(
											$elm$core$String$fromInt(model.Q.bK)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobOperationFormMsg),
												$author$project$Jobs$Messages$Rate))
										])),
									A2(
									$author$project$Utils$Clarity$clarityNumberControl,
									_Utils_Tuple2('conversion', 'Operation Conversion'),
									_List_fromArray(
										[
											$elm$html$Html$Attributes$min('1'),
											$elm$html$Html$Attributes$value(
											$elm$core$String$fromInt(model.Q.bD)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobOperationFormMsg),
												$author$project$Jobs$Messages$Conversion))
										])),
									A2(
									$author$project$Utils$Clarity$clarityNumberControl,
									_Utils_Tuple2('setup', 'Setup Time'),
									_List_fromArray(
										[
											$elm$html$Html$Attributes$min('0'),
											$elm$html$Html$Attributes$value(
											$elm$core$String$fromInt(model.Q.ck)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobOperationFormMsg),
												$author$project$Jobs$Messages$SetupTime))
										])),
									A2(
									$author$project$Utils$Clarity$clarityNumberControl,
									_Utils_Tuple2('teardown', 'Teardown Time'),
									_List_fromArray(
										[
											$elm$html$Html$Attributes$min('0'),
											$elm$html$Html$Attributes$value(
											$elm$core$String$fromInt(model.Q.cq)),
											$elm$html$Html$Events$onInput(
											A2(
												$elm$core$Basics$composeL,
												A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobOperationFormMsg),
												$author$project$Jobs$Messages$TeardownTime))
										])),
									A2(
									$author$project$Utils$Clarity$clarityFormControl,
									_Utils_Tuple2('dependencies', 'Dependencies'),
									A2(
										$elm$core$List$map,
										function (o) {
											return A2(
												$author$project$Utils$Clarity$clarityCheckbox,
												_Utils_Tuple2(
													o.bp,
													A4(
														$author$project$Utils$Uri$getRelatedAttr,
														function ($) {
															return $.b5;
														},
														function ($) {
															return $.b0;
														},
														model.b7,
														o)),
												_List_fromArray(
													[
														$elm$html$Html$Attributes$name('dependencies'),
														$elm$html$Html$Attributes$value(o.bp),
														$elm$html$Html$Events$onInput(
														A2(
															$elm$core$Basics$composeL,
															A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$JobOperationFormMsg),
															$author$project$Jobs$Messages$Dependencies)),
														$elm$html$Html$Attributes$checked(
														A2($elm$core$List$member, o.bp, model.Q.bH))
													]));
										},
										operations)),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('submit'),
											$elm$html$Html$Attributes$class('btn btn-primary'),
											$elm$html$Html$Attributes$disabled(
											!A2(
												$author$project$Utils$Utils$validateForm,
												_List_fromArray(
													[
														A2(
														$elm$core$Basics$composeR,
														function ($) {
															return $.b5;
														},
														$elm$core$Basics$neq(''))
													]),
												model.Q))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Add Operation')
										]))
								]))
						]))
				]));
	});
var $author$project$Jobs$View$viewJobOperations = F2(
	function (model, operations) {
		var getRelatedOperationName = A3(
			$author$project$Utils$Uri$getRelatedAttr,
			A3(
				$author$project$Utils$Uri$getRelatedAttr,
				$elm$core$Basics$identity,
				function ($) {
					return $.b5;
				},
				model.aq),
			function ($) {
				return $.b0;
			},
			model.b7);
		return $author$project$Utils$Clarity$gridFullBlock(
			_List_fromArray(
				[
					A2(
					$author$project$Utils$Clarity$viewTable,
					_List_fromArray(
						[
							_Utils_Tuple2(
							'Operation',
							A2(
								$elm$core$Basics$composeR,
								A3(
									$author$project$Utils$Uri$getRelatedAttr,
									function ($) {
										return $.b5;
									},
									function ($) {
										return $.b0;
									},
									model.b7),
								$elm$html$Html$text)),
							_Utils_Tuple2(
							'Count',
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.bC;
								},
								A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text))),
							_Utils_Tuple2(
							'Estimated Rate',
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.bK;
								},
								A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text))),
							_Utils_Tuple2(
							'Count Conversion',
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.bD;
								},
								A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text))),
							_Utils_Tuple2(
							'Setup Time',
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.ck;
								},
								A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text))),
							_Utils_Tuple2(
							'Teardown Time',
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.cq;
								},
								A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text))),
							_Utils_Tuple2(
							'Dependencies',
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.bH;
								},
								A2(
									$elm$core$Basics$composeR,
									$elm$core$List$map(getRelatedOperationName),
									A2(
										$elm$core$Basics$composeR,
										$elm$core$String$join(','),
										$elm$html$Html$text))))
						]),
					operations)
				]));
	});
var $author$project$Jobs$Models$Active = 1;
var $author$project$Jobs$Models$Finished = 2;
var $author$project$Jobs$Models$NotActive = 0;
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$Jobs$Models$generateJobTimeline = function (job) {
	var statuses = _List_fromArray(
		[0, 1, 2, 3, 4]);
	var stages = function () {
		var _v0 = job.W;
		switch (_v0) {
			case 0:
				return _List_fromArray(
					[1, 0, 0, 0, 0]);
			case 1:
				return _List_fromArray(
					[2, 1, 0, 0, 0]);
			case 2:
				return _List_fromArray(
					[2, 2, 1, 0, 0]);
			case 3:
				return _List_fromArray(
					[2, 2, 2, 1, 0]);
			default:
				return _List_fromArray(
					[2, 2, 2, 2, 2]);
		}
	}();
	return A3($elm$core$List$map2, $elm$core$Tuple$pair, statuses, stages);
};
var $lattyware$elm_fontawesome$FontAwesome$Regular$checkCircle = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'far',
	'check-circle',
	512,
	512,
	_List_fromArray(
		['M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z']));
var $lattyware$elm_fontawesome$FontAwesome$Regular$circle = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'far',
	'circle',
	512,
	512,
	_List_fromArray(
		['M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z']));
var $lattyware$elm_fontawesome$FontAwesome$Regular$dotCircle = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'far',
	'dot-circle',
	512,
	512,
	_List_fromArray(
		['M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z']));
var $lattyware$elm_fontawesome$FontAwesome$Attributes$fa2x = $elm$svg$Svg$Attributes$class('fa-2x');
var $author$project$Jobs$Models$jobStatusToString = function (status) {
	switch (status) {
		case 0:
			return 'Setup';
		case 1:
			return 'Ready';
		case 2:
			return 'Scheduled';
		case 3:
			return 'Running';
		default:
			return 'Completed';
	}
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $lattyware$elm_fontawesome$FontAwesome$Icon$styled = F2(
	function (attributes, _v0) {
		var presentation = _v0;
		return _Utils_update(
			presentation,
			{
				ac: _Utils_ap(presentation.ac, attributes)
			});
	});
var $lattyware$elm_fontawesome$FontAwesome$Icon$viewStyled = function (styles) {
	return A2(
		$elm$core$Basics$composeR,
		$lattyware$elm_fontawesome$FontAwesome$Icon$present,
		A2(
			$elm$core$Basics$composeR,
			$lattyware$elm_fontawesome$FontAwesome$Icon$styled(styles),
			$lattyware$elm_fontawesome$FontAwesome$Icon$view));
};
var $author$project$Jobs$View$renderStep = function (_v0) {
	var stage = _v0.a;
	var status = _v0.b;
	var icon = function () {
		switch (status) {
			case 0:
				return $lattyware$elm_fontawesome$FontAwesome$Regular$circle;
			case 1:
				return $lattyware$elm_fontawesome$FontAwesome$Regular$dotCircle;
			default:
				return $lattyware$elm_fontawesome$FontAwesome$Regular$checkCircle;
		}
	}();
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('clr-timeline-step'),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('step-completed', status === 2)
					])),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('step-progress', status === 1)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('clr-timeline-step-header')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Jobs$Models$jobStatusToString(stage))
					])),
				A2(
				$lattyware$elm_fontawesome$FontAwesome$Icon$viewStyled,
				_List_fromArray(
					[$lattyware$elm_fontawesome$FontAwesome$Attributes$fa2x]),
				icon),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('clr-timeline-step-body')
					]),
				_List_Nil)
			]));
};
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Jobs$View$viewJobTimeline = function (job) {
	return $author$project$Utils$Clarity$gridFullBlock(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h3,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Status')
					])),
				A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$ul,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clr-timeline clr-timeline-horizontal job-timeline')
							]),
						A2(
							$elm$core$List$map,
							$author$project$Jobs$View$renderStep,
							$author$project$Jobs$Models$generateJobTimeline(job)))
					]))
			]));
};
var $author$project$Jobs$View$viewJobDetail = F2(
	function (model, job) {
		var operations = A2(
			$elm$core$List$filter,
			function (r) {
				return _Utils_eq(r.bX, job.bp);
			},
			$elm$core$Dict$values(model.aq));
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Jobs$View$viewJobDetailHeader(job),
					A2($author$project$Jobs$View$viewJobInfoDescription, model, job),
					$author$project$Jobs$View$viewJobTimeline(job),
					$author$project$Utils$Clarity$gridFullBlock(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h3,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('Operations')
								]))
						])),
					A2($author$project$Jobs$View$viewJobOperations, model, operations),
					A3($author$project$Jobs$View$viewJobOperationForm, model, job, operations)
				]));
	});
var $author$project$Jobs$View$viewJobList = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$author$project$Utils$Clarity$viewTable,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'JobName',
								function (job) {
									return A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$href(
												$author$project$Utils$Uri$linkFromUri(job.bp))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(job.b0)
											]));
								}),
								_Utils_Tuple2(
								'Client',
								A2(
									$elm$core$Basics$composeR,
									A3(
										$author$project$Utils$Uri$getRelatedAttr,
										function ($) {
											return $.bz;
										},
										function ($) {
											return $.b0;
										},
										model.al),
									$elm$html$Html$text)),
								_Utils_Tuple2(
								'Date Created',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.bE;
									},
									$elm$html$Html$text)),
								_Utils_Tuple2(
								'Date Due',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.bF;
									},
									$elm$html$Html$text)),
								_Utils_Tuple2(
								'Total Count',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.ct;
									},
									A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text))),
								_Utils_Tuple2(
								'Value',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.cx;
									},
									A2(
										$elm$core$Basics$composeR,
										$elm$core$String$fromInt,
										A2(
											$elm$core$Basics$composeR,
											$elm$core$String$append('$'),
											$elm$html$Html$text)))),
								_Utils_Tuple2(
								'Operations',
								A2(
									$elm$core$Basics$composeR,
									function (job) {
										return $elm$core$List$length(
											A2(
												$elm$core$List$filter,
												function (o) {
													return _Utils_eq(o.bX, job.bp);
												},
												$elm$core$Dict$values(model.aq)));
									},
									A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text))),
								_Utils_Tuple2(
								'Status',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.W;
									},
									A2($elm$core$Basics$composeR, $author$project$Jobs$Models$jobStatusToString, $elm$html$Html$text)))
							]),
						$elm$core$Dict$values(model.ar))
					]))
			]));
};
var $author$project$Jobs$View$view = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return $author$project$Jobs$View$viewJobList(model);
			case 1:
				return $author$project$Jobs$View$viewJobCreate(model);
			default:
				var job_id = route.a;
				var _v1 = A3($author$project$Utils$Uri$lookupId, 'jobs', job_id, model.ar);
				if (!_v1.$) {
					var job = _v1.a;
					return A2($author$project$Jobs$View$viewJobDetail, model, job);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Job Not Found')
							]));
				}
		}
	});
var $author$project$Machines$Messages$MachineDepartment = function (a) {
	return {$: 2, a: a};
};
var $author$project$Machines$Messages$MachineFormMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Machines$Messages$MachineName = function (a) {
	return {$: 0, a: a};
};
var $author$project$Machines$Messages$MachineOperation = function (a) {
	return {$: 1, a: a};
};
var $author$project$Machines$Messages$SubmitMachine = {$: 2};
var $author$project$Machines$View$viewMachineCreate = function (model) {
	var operation_operations = A2(
		$elm$core$List$map,
		function (o) {
			return _Utils_Tuple2(o.bp, o.b0);
		},
		$elm$core$Dict$values(model.b7));
	var department_options = A2(
		$elm$core$List$map,
		function (d) {
			return _Utils_Tuple2(d.bp, d.b0);
		},
		$elm$core$Dict$values(model.am));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('/machines')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Machines')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('New Machine')
									]))
							]))
					])),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clr-form clr-form-compact'),
								$elm$html$Html$Events$onSubmit(
								$author$project$Messages$MachinesMsg($author$project$Machines$Messages$SubmitMachine))
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Utils$Clarity$clarityTextControl,
								_Utils_Tuple2('machine_name', 'Machine Name'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(model.S.b0),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$MachinesMsg, $author$project$Machines$Messages$MachineFormMsg),
											$author$project$Machines$Messages$MachineName))
									])),
								A5(
								$author$project$Utils$Clarity$claritySelectControl,
								_Utils_Tuple2('department', 'Department'),
								_List_fromArray(
									[
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$MachinesMsg, $author$project$Machines$Messages$MachineFormMsg),
											$author$project$Machines$Messages$MachineDepartment))
									]),
								department_options,
								'Select a Department',
								model.S.aH),
								A5(
								$author$project$Utils$Clarity$claritySelectControl,
								_Utils_Tuple2('operation', 'Operation'),
								_List_fromArray(
									[
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$MachinesMsg, $author$project$Machines$Messages$MachineFormMsg),
											$author$project$Machines$Messages$MachineOperation))
									]),
								operation_operations,
								'Select an Operation',
								model.S.b5),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('submit'),
										$elm$html$Html$Attributes$class('btn btn-primary'),
										$elm$html$Html$Attributes$disabled(
										!A2(
											$author$project$Utils$Utils$validateForm,
											_List_fromArray(
												[
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.b0;
													},
													$elm$core$Basics$neq('')),
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.aH;
													},
													$elm$core$Basics$neq('')),
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.b5;
													},
													$elm$core$Basics$neq(''))
												]),
											model.S))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Create Machine')
									]))
							]))
					]))
			]));
};
var $author$project$Machines$View$viewMachineDetail = F2(
	function (model, machine) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Utils$Clarity$gridFullBlock(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('/machines')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Machines')
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(machine.b0)
										]))
								]))
						]))
				]));
	});
var $author$project$Machines$View$viewMachineList = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridTwoColumns(
				_Utils_Tuple2(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mar-none')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Machines')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-right')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-primary'),
											$elm$html$Html$Attributes$href('/machines/create')
										]),
									_List_fromArray(
										[
											$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$plus),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mar-l-sm')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Create Machine')
												]))
										]))
								]))
						]))),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$author$project$Utils$Clarity$viewTable,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Name',
								function (machine) {
									return A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$href(
												$author$project$Utils$Uri$linkFromUri(machine.bp))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(machine.b0)
											]));
								}),
								_Utils_Tuple2(
								'Department',
								A2(
									$elm$core$Basics$composeR,
									A3(
										$author$project$Utils$Uri$getRelatedAttr,
										function ($) {
											return $.aH;
										},
										function ($) {
											return $.b0;
										},
										model.am),
									$elm$html$Html$text)),
								_Utils_Tuple2(
								'Operation',
								A2(
									$elm$core$Basics$composeR,
									A3(
										$author$project$Utils$Uri$getRelatedAttr,
										function ($) {
											return $.b5;
										},
										function ($) {
											return $.b0;
										},
										model.b7),
									$elm$html$Html$text))
							]),
						$elm$core$Dict$values(model.aZ))
					]))
			]));
};
var $author$project$Machines$View$view = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return $author$project$Machines$View$viewMachineList(model);
			case 1:
				return $author$project$Machines$View$viewMachineCreate(model);
			default:
				var machine_id = route.a;
				var _v1 = A3($author$project$Utils$Uri$lookupId, 'machines', machine_id, model.aZ);
				if (!_v1.$) {
					var machine = _v1.a;
					return A2($author$project$Machines$View$viewMachineDetail, model, machine);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Machine Not Found')
							]));
				}
		}
	});
var $author$project$Operations$Messages$OperationFormMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Operations$Messages$SubmitOperation = {$: 2};
var $author$project$Operations$View$viewOperationCreate = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('/operations')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Operations')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('New Operation')
									]))
							]))
					])),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clr-form clr-form-compact'),
								$elm$html$Html$Events$onSubmit(
								$author$project$Messages$OperationsMsg($author$project$Operations$Messages$SubmitOperation))
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Utils$Clarity$clarityTextControl,
								_Utils_Tuple2('operation_name', 'Operation Name'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(model.b6.b0),
										$elm$html$Html$Events$onInput(
										A2($elm$core$Basics$composeL, $author$project$Messages$OperationsMsg, $author$project$Operations$Messages$OperationFormMsg))
									])),
								A2($elm$html$Html$br, _List_Nil, _List_Nil),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('submit'),
										$elm$html$Html$Attributes$class('btn btn-primary'),
										$elm$html$Html$Attributes$disabled(
										!A2(
											$author$project$Utils$Utils$validateForm,
											_List_fromArray(
												[
													A2(
													$elm$core$Basics$composeR,
													function ($) {
														return $.b0;
													},
													$elm$core$Basics$neq(''))
												]),
											model.b6))
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Create Operation')
									]))
							]))
					]))
			]));
};
var $author$project$Operations$View$viewOperationDetail = F2(
	function (model, operation) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Utils$Clarity$gridFullBlock(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$href('/operations')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Operations')
										])),
									A2(
									$elm$html$Html$span,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text(operation.b0)
										]))
								]))
						]))
				]));
	});
var $author$project$Operations$View$viewOperationList = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridTwoColumns(
				_Utils_Tuple2(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mar-none')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Operations')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-right')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-primary'),
											$elm$html$Html$Attributes$href('/operations/create')
										]),
									_List_fromArray(
										[
											$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$plus),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mar-l-sm')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Create Operation')
												]))
										]))
								]))
						]))),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$author$project$Utils$Clarity$viewTable,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Name',
								function (operation) {
									return A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$href(
												$author$project$Utils$Uri$linkFromUri(operation.bp))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(operation.b0)
											]));
								}),
								_Utils_Tuple2(
								'# Jobs',
								A2(
									$elm$core$Basics$composeR,
									function (d) {
										return $elm$core$List$length(_List_Nil);
									},
									A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text)))
							]),
						$elm$core$Dict$values(model.b7))
					]))
			]));
};
var $author$project$Operations$View$view = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return $author$project$Operations$View$viewOperationList(model);
			case 1:
				return $author$project$Operations$View$viewOperationCreate(model);
			default:
				var operation_id = route.a;
				var _v1 = A3($author$project$Utils$Uri$lookupId, 'operations', operation_id, model.b7);
				if (!_v1.$) {
					var operation = _v1.a;
					return A2($author$project$Operations$View$viewOperationDetail, model, operation);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Operation Not Found')
							]));
				}
		}
	});
var $author$project$Schedule$Calculate$getScheduledOperation = F2(
	function (model, scheduled) {
		return A2(
			$elm$core$Maybe$map,
			$elm$core$Tuple$pair(scheduled),
			A2($elm$core$Dict$get, scheduled.aW, model.aq));
	});
var $author$project$Schedule$Calculate$getOperationHours = function (job_operation) {
	return {
		t: false,
		F: $elm$core$Basics$ceiling(job_operation.bC / job_operation.bK),
		G: job_operation.ck,
		x: _List_Nil,
		I: job_operation.cq
	};
};
var $author$project$Schedule$Models$Available = {$: 0};
var $author$project$Schedule$Models$Busy = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Schedule$Models$Running = 1;
var $author$project$Schedule$Models$Setup = 0;
var $author$project$Schedule$Models$Teardown = 2;
var $author$project$Schedule$Calculate$injectOperation = F3(
	function (schedule_job, schedule_cell, remaining_hours) {
		injectOperation:
		while (true) {
			var scheduled = schedule_job.a;
			var job_operation = schedule_job.b;
			var _v0 = schedule_cell.a;
			var machine = _v0.a;
			var time = _v0.b;
			var status = schedule_cell.b;
			var found = remaining_hours.t;
			var setup = remaining_hours.G;
			var run = remaining_hours.F;
			var teardown = remaining_hours.I;
			var slots = remaining_hours.x;
			var _v1 = _Utils_Tuple2(
				found,
				_Utils_eq(status, $author$project$Schedule$Models$Available));
			if (!_v1.b) {
				return remaining_hours;
			} else {
				if (!_v1.a) {
					var _v2 = _Utils_eq(scheduled.Z, time);
					if (_v2) {
						var $temp$schedule_job = schedule_job,
							$temp$schedule_cell = schedule_cell,
							$temp$remaining_hours = _Utils_update(
							remaining_hours,
							{t: true});
						schedule_job = $temp$schedule_job;
						schedule_cell = $temp$schedule_cell;
						remaining_hours = $temp$remaining_hours;
						continue injectOperation;
					} else {
						return remaining_hours;
					}
				} else {
					var _v3 = _Utils_Tuple3(setup, run, teardown);
					if (!_v3.a) {
						if (!_v3.b) {
							if (!_v3.c) {
								return remaining_hours;
							} else {
								var t = _v3.c;
								return {
									t: true,
									F: run,
									G: setup,
									x: A2(
										$elm$core$List$cons,
										_Utils_Tuple2(
											_Utils_Tuple2(machine, time),
											A3($author$project$Schedule$Models$Busy, job_operation, 2, t === 1)),
										slots),
									I: t - 1
								};
							}
						} else {
							var r = _v3.b;
							var t = _v3.c;
							return {
								t: true,
								F: r - 1,
								G: setup,
								x: A2(
									$elm$core$List$cons,
									_Utils_Tuple2(
										_Utils_Tuple2(machine, time),
										A3($author$project$Schedule$Models$Busy, job_operation, 1, (r === 1) && (!t))),
									slots),
								I: teardown
							};
						}
					} else {
						var s = _v3.a;
						return {
							t: true,
							F: run,
							G: s - 1,
							x: A2(
								$elm$core$List$cons,
								_Utils_Tuple2(
									_Utils_Tuple2(machine, time),
									A3($author$project$Schedule$Models$Busy, job_operation, 0, false)),
								slots),
							I: teardown
						};
					}
				}
			}
		}
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $author$project$Schedule$Calculate$scheduleOperation = F2(
	function (_v0, grid) {
		var schedule = _v0.a;
		var job_operation = _v0.b;
		var job_dict = $elm$core$Dict$fromList(
			A3(
				$elm$core$List$foldl,
				$author$project$Schedule$Calculate$injectOperation(
					_Utils_Tuple2(schedule, job_operation)),
				$author$project$Schedule$Calculate$getOperationHours(job_operation),
				A2(
					$elm$core$List$filter,
					function (_v1) {
						var _v2 = _v1.a;
						var machine = _v2.a;
						var time = _v2.b;
						var status = _v1.b;
						return _Utils_eq(machine, schedule.aY);
					},
					grid)).x);
		var grid_dict = $elm$core$Dict$fromList(grid);
		return $elm$core$Dict$toList(
			A2($elm$core$Dict$union, job_dict, grid_dict));
	});
var $author$project$Schedule$Calculate$applyJobOperations = F2(
	function (model, grid) {
		return A3(
			$elm$core$List$foldl,
			$author$project$Schedule$Calculate$scheduleOperation,
			grid,
			A2(
				$elm$core$List$filterMap,
				$author$project$Schedule$Calculate$getScheduledOperation(model),
				model.ci));
	});
var $author$project$Schedule$Models$OffHours = {$: 2};
var $elm$core$List$sortBy = _List_sortBy;
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.ax, posixMinutes) < 0) {
					return posixMinutes + era.b;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Schedule$Calculate$applyShifts = F2(
	function (shifts, grid) {
		var applyShiftAvailability = function (_v1) {
			var cell = _v1.a;
			var machine = cell.a;
			var time_slot = cell.b;
			var time_slot_posix = $author$project$Utils$TimeSlots$timeslotToPosix(time_slot);
			var time_slot_posix_default = $elm$time$Time$millisToPosix(
				1 + $elm$time$Time$posixToMillis(time_slot_posix));
			var time_slot_hour = A2($elm$time$Time$toHour, $elm$time$Time$utc, time_slot_posix);
			var rule = $elm$core$List$head(
				$elm$core$List$reverse(
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.cc;
						},
						A2(
							$elm$core$List$filter,
							function (sr) {
								return _Utils_cmp(sr.bi.b, time_slot_hour) > 0;
							},
							A2(
								$elm$core$List$filter,
								function (sr) {
									return _Utils_cmp(sr.bi.a, time_slot_hour) < 1;
								},
								A2(
									$elm$core$List$filter,
									function (sr) {
										return A2(
											$elm$core$List$member,
											A2($elm$time$Time$toWeekday, $elm$time$Time$utc, time_slot_posix),
											sr.bG);
									},
									A2(
										$elm$core$List$filter,
										function (sr) {
											return _Utils_cmp(
												$elm$time$Time$posixToMillis(
													A2(
														$elm$core$Maybe$withDefault,
														time_slot_posix_default,
														A2($elm$core$Maybe$map, $elm$core$Tuple$second, sr.bq))),
												$elm$time$Time$posixToMillis(time_slot_posix)) > 0;
										},
										A2(
											$elm$core$List$filter,
											function (sr) {
												return _Utils_cmp(
													$elm$time$Time$posixToMillis(
														A2(
															$elm$core$Maybe$withDefault,
															time_slot_posix,
															A2($elm$core$Maybe$map, $elm$core$Tuple$first, sr.bq))),
													$elm$time$Time$posixToMillis(time_slot_posix)) < 1;
											},
											A2(
												$elm$core$List$filter,
												function (sr) {
													return _Utils_eq(
														A2($elm$core$Maybe$withDefault, machine, sr.aY),
														machine);
												},
												shifts)))))))));
			var newStatus = function () {
				if (!rule.$) {
					return $author$project$Schedule$Models$Available;
				} else {
					return $author$project$Schedule$Models$OffHours;
				}
			}();
			return _Utils_Tuple2(cell, newStatus);
		};
		return A2($elm$core$List$map, applyShiftAvailability, grid);
	});
var $author$project$Schedule$Calculate$generateEmptyGrid = F2(
	function (machines, time_slots) {
		return A2(
			$elm$core$List$map,
			function (machine_timeslot) {
				return _Utils_Tuple2(machine_timeslot, $author$project$Schedule$Models$OffHours);
			},
			A2(
				$elm$core$List$concatMap,
				function (machine) {
					return A2(
						$elm$core$List$map,
						function (time) {
							return _Utils_Tuple2(machine, time);
						},
						time_slots);
				},
				machines));
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		aF: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		a$: month,
		bs: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).aF;
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).a$;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString = F2(
	function (digits, time) {
		return A3(
			$elm$core$String$padLeft,
			digits,
			'0',
			$elm$core$String$fromInt(time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).bs;
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime = function (time) {
	return A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		4,
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time)) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		$rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth(
			A2($elm$time$Time$toMonth, $elm$time$Time$utc, time))) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toDay, $elm$time$Time$utc, time)) + ('T' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toHour, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toMinute, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toSecond, $elm$time$Time$utc, time)) + ('.' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		3,
		A2($elm$time$Time$toMillis, $elm$time$Time$utc, time)) + 'Z'))))))))))));
};
var $author$project$Utils$TimeSlots$monthToString = function (month) {
	switch (month) {
		case 0:
			return '01';
		case 1:
			return '02';
		case 2:
			return '03';
		case 3:
			return '04';
		case 4:
			return '05';
		case 5:
			return '06';
		case 6:
			return '07';
		case 7:
			return '08';
		case 8:
			return '09';
		case 9:
			return '10';
		case 10:
			return '11';
		default:
			return '12';
	}
};
var $author$project$Utils$TimeSlots$posixToTimeslot = function (time) {
	return $elm$core$String$fromInt(
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time)) + ('-' + ($author$project$Utils$TimeSlots$monthToString(
		A2($elm$time$Time$toMonth, $elm$time$Time$utc, time)) + ('-' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			A2($elm$time$Time$toDay, $elm$time$Time$utc, time))) + ('T' + (A3(
		$elm$core$String$padLeft,
		2,
		'0',
		$elm$core$String$fromInt(
			A2($elm$time$Time$toHour, $elm$time$Time$utc, time))) + ':00:00'))))));
};
var $author$project$Utils$TimeSlots$generateTimeline = F2(
	function (start, hours) {
		return A2(
			$elm$core$List$map,
			$author$project$Utils$TimeSlots$posixToTimeslot,
			A2(
				$elm$core$List$map,
				$elm$time$Time$millisToPosix,
				A2(
					$elm$core$List$map,
					$elm$core$Basics$add(
						$elm$time$Time$posixToMillis(start)),
					A2(
						$elm$core$List$map,
						$elm$core$Basics$mul($author$project$Utils$TimeSlots$hourMillis),
						A2($elm$core$List$range, 0, hours)))));
	});
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $elm$core$List$minimum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$min, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Schedule$Calculate$times = function (model) {
	var _v0 = function () {
		var _v1 = model.cz;
		if (!_v1.$) {
			var s = _v1.a;
			var r = _v1.b;
			return _Utils_Tuple2(s, r);
		} else {
			var s = _v1.a;
			var r = _v1.b;
			return _Utils_Tuple2(
				$elm$time$Time$millisToPosix(s),
				r);
		}
	}();
	var start = _v0.a;
	var range = _v0.b;
	var first = A2(
		$elm$core$Result$withDefault,
		start,
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(
			A2(
				$elm$core$Maybe$withDefault,
				$rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime(start),
				$elm$core$List$minimum(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.Z;
						},
						model.ci)))));
	var diff = A3(
		$author$project$Utils$Utils$flip,
		$elm$core$Basics$idiv,
		$author$project$Utils$TimeSlots$hourMillis,
		$elm$core$Basics$abs(
			A3(
				$author$project$Utils$Utils$flip,
				$elm$core$Basics$sub,
				$elm$time$Time$posixToMillis(first),
				$elm$time$Time$posixToMillis(start))));
	var _v2 = function () {
		var _v3 = _Utils_cmp(
			$elm$time$Time$posixToMillis(first),
			$elm$time$Time$posixToMillis(start)) < 0;
		if (_v3) {
			return _Utils_Tuple2(first, range + diff);
		} else {
			return _Utils_Tuple2(start, range);
		}
	}();
	var lower = _v2.a;
	var upper = _v2.b;
	return A2($author$project$Utils$TimeSlots$generateTimeline, lower, upper);
};
var $author$project$Schedule$Calculate$calculateSchedule = function (model) {
	return A2(
		$author$project$Schedule$Calculate$applyJobOperations,
		model,
		A2(
			$author$project$Schedule$Calculate$applyShifts,
			model.cm,
			A2(
				$author$project$Schedule$Calculate$generateEmptyGrid,
				$elm$core$Dict$keys(model.aZ),
				$author$project$Schedule$Calculate$times(model))));
};
var $author$project$Schedule$Calculate$filterWindow = F2(
	function (model, grid) {
		var _v0 = function () {
			var _v1 = model.cz;
			if (!_v1.$) {
				var s = _v1.a;
				var r = _v1.b;
				return _Utils_Tuple2(s, r);
			} else {
				var s = _v1.a;
				var r = _v1.b;
				return _Utils_Tuple2(
					$elm$time$Time$millisToPosix(s),
					r);
			}
		}();
		var start = _v0.a;
		var first_time_slot = $author$project$Utils$TimeSlots$posixToTimeslot(start);
		return A2(
			$elm$core$List$filter,
			function (_v2) {
				var _v3 = _v2.a;
				var slot = _v3.b;
				return _Utils_cmp(slot, first_time_slot) > -1;
			},
			grid);
	});
var $author$project$Schedule$Calculate$viewCalculatedSchedule = function (model) {
	return A2(
		$author$project$Schedule$Calculate$filterWindow,
		model,
		$author$project$Schedule$Calculate$calculateSchedule(model));
};
var $author$project$Schedule$Models$Eligible = function (a) {
	return {$: 4, a: a};
};
var $author$project$Utils$Utils$applyN = function () {
	var for_ = F4(
		function (i, n, f, v) {
			for_:
			while (true) {
				if (_Utils_cmp(i, n) < 0) {
					var $temp$i = i + 1,
						$temp$n = n,
						$temp$f = f,
						$temp$v = f(v);
					i = $temp$i;
					n = $temp$n;
					f = $temp$f;
					v = $temp$v;
					continue for_;
				} else {
					return v;
				}
			}
		});
	return for_(0);
}();
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Utils$TimeSlots$nextIsoHour = function (iso) {
	return $author$project$Utils$TimeSlots$posixToTimeslot(
		$elm$time$Time$millisToPosix(
			$author$project$Utils$TimeSlots$hourMillis + $elm$time$Time$posixToMillis(
				A2(
					$elm$core$Result$withDefault,
					$elm$time$Time$millisToPosix(0),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(iso)))));
};
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $author$project$Schedule$Calculate$applyEligibleSlots = F2(
	function (model, grid) {
		var _v0 = model.bt;
		if (!_v0.$) {
			var operation = _v0.a;
			var job_start = A4(
				$author$project$Utils$Uri$getRelatedAttr,
				function ($) {
					return $.bX;
				},
				function ($) {
					return $.bE;
				},
				model.ar,
				operation);
			var job_end = A4(
				$author$project$Utils$Uri$getRelatedAttr,
				function ($) {
					return $.bX;
				},
				function ($) {
					return $.bF;
				},
				model.ar,
				operation);
			var dependent_operation_blocking_time = function (dependent_operation_uri) {
				var scheduled_operation = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						A2(
							$elm$core$Basics$composeL,
							$elm$core$Basics$eq(dependent_operation_uri),
							function ($) {
								return $.aW;
							}),
						model.ci));
				var job_operation = A2($elm$core$Dict$get, dependent_operation_uri, model.aq);
				var filter_operation = function (_v11) {
					var status = _v11.b;
					if (status.$ === 1) {
						var scheduled_job_op = status.a;
						return _Utils_eq(
							$elm$core$Maybe$Just(scheduled_job_op),
							job_operation);
					} else {
						return false;
					}
				};
				var _v8 = _Utils_Tuple2(job_operation, scheduled_operation);
				if ((!_v8.a.$) && (!_v8.b.$)) {
					var op = _v8.a.a;
					var scheduled = _v8.b.a;
					var _v9 = A2($elm$core$Basics$compare, op.bK * op.bD, operation.bK);
					switch (_v9) {
						case 0:
							var op_hours = $elm$core$Basics$ceiling(operation.bC / operation.bK);
							var dependent_slots = $elm$core$List$sort(
								A2(
									$elm$core$List$map,
									A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $elm$core$Tuple$second),
									A2($elm$core$List$filter, filter_operation, grid)));
							var dependent_op_hours = $elm$core$Basics$ceiling(op.bC / op.bK);
							var delay = (dependent_op_hours - op_hours) + 2;
							var earliest_run_time = A2(
								$elm$core$Maybe$withDefault,
								scheduled.Z,
								$elm$core$List$head(
									$elm$core$List$reverse(
										A2($elm$core$List$take, delay, dependent_slots))));
							return $elm$core$Maybe$Just(
								A3($author$project$Utils$Utils$applyN, 1 - operation.ck, $author$project$Utils$TimeSlots$nextIsoHour, earliest_run_time));
						case 1:
							return $elm$core$Maybe$Just(
								A3($author$project$Utils$Utils$applyN, (op.ck - operation.ck) + 1, $author$project$Utils$TimeSlots$nextIsoHour, scheduled.Z));
						default:
							return $elm$core$Maybe$Just(
								A3($author$project$Utils$Utils$applyN, (op.ck - operation.ck) + 1, $author$project$Utils$TimeSlots$nextIsoHour, scheduled.Z));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			};
			var minimum_start_time = A2(
				$elm$core$Maybe$withDefault,
				job_start,
				$elm$core$List$maximum(
					A2($elm$core$List$filterMap, dependent_operation_blocking_time, operation.bH)));
			var eligible_slots = $elm$core$Dict$fromList(
				A2(
					$elm$core$List$map,
					function (_v6) {
						var _v7 = _v6.a;
						var machine = _v7.a;
						var time_slot = _v7.b;
						return _Utils_Tuple2(
							_Utils_Tuple2(machine, time_slot),
							$author$project$Schedule$Models$Eligible(operation));
					},
					A2(
						$elm$core$List$filter,
						function (_v4) {
							var _v5 = _v4.a;
							var time_slot = _v5.b;
							return (_Utils_cmp(time_slot, minimum_start_time) > -1) && (_Utils_cmp(time_slot, job_end) < 0);
						},
						A2(
							$elm$core$List$filter,
							function (_v2) {
								var _v3 = _v2.a;
								var machine = _v3.a;
								return _Utils_eq(
									operation.b5,
									A2(
										$elm$core$Maybe$withDefault,
										'',
										A2(
											$elm$core$Maybe$map,
											function ($) {
												return $.b5;
											},
											A2($elm$core$Dict$get, machine, model.aZ))));
							},
							A2(
								$elm$core$List$filter,
								function (_v1) {
									var status = _v1.b;
									return _Utils_eq(status, $author$project$Schedule$Models$Available);
								},
								grid)))));
			return $elm$core$Dict$toList(
				A2(
					$elm$core$Dict$union,
					eligible_slots,
					$elm$core$Dict$fromList(grid)));
		} else {
			return grid;
		}
	});
var $author$project$Schedule$Calculate$applyProposedScheduledOperations = F2(
	function (model, grid) {
		return A3(
			$elm$core$List$foldl,
			$author$project$Schedule$Calculate$scheduleOperation,
			grid,
			A2(
				$elm$core$List$filterMap,
				$author$project$Schedule$Calculate$getScheduledOperation(model),
				model.cd));
	});
var $author$project$Schedule$Calculate$calculateScheduleWithProposed = function (model) {
	return A2(
		$author$project$Schedule$Calculate$applyEligibleSlots,
		model,
		A2(
			$author$project$Schedule$Calculate$applyProposedScheduledOperations,
			model,
			$author$project$Schedule$Calculate$calculateSchedule(model)));
};
var $author$project$Schedule$Calculate$viewCalculatedScheduleWithProposed = function (model) {
	return A2(
		$author$project$Schedule$Calculate$filterWindow,
		model,
		$author$project$Schedule$Calculate$calculateScheduleWithProposed(model));
};
var $author$project$Schedule$Messages$ChangeColorSchema = function (a) {
	return {$: 6, a: a};
};
var $author$project$UI$Messages$DropdownMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Schedule$Messages$SaveProposedSchedule = {$: 10};
var $author$project$Schedule$Messages$ScrollNow = {$: 5};
var $author$project$Schedule$Messages$ScrollTime = function (a) {
	return {$: 3, a: a};
};
var $author$project$Schedule$Messages$SelectJobOperation = function (a) {
	return {$: 8, a: a};
};
var $author$project$Schedule$Messages$SizeWindow = function (a) {
	return {$: 4, a: a};
};
var $author$project$Schedule$Messages$UnProposeSchedule = {$: 11};
var $author$project$Schedule$Models$VaryClient = {$: 1};
var $lattyware$elm_fontawesome$FontAwesome$Solid$angleDoubleLeft = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'angle-double-left',
	448,
	512,
	_List_fromArray(
		['M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z']));
var $lattyware$elm_fontawesome$FontAwesome$Solid$angleDoubleRight = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'angle-double-right',
	448,
	512,
	_List_fromArray(
		['M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z']));
var $lattyware$elm_fontawesome$FontAwesome$Solid$chevronLeft = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'chevron-left',
	320,
	512,
	_List_fromArray(
		['M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z']));
var $lattyware$elm_fontawesome$FontAwesome$Solid$chevronRight = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'chevron-right',
	320,
	512,
	_List_fromArray(
		['M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z']));
var $lattyware$elm_fontawesome$FontAwesome$Solid$clock = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'clock',
	512,
	512,
	_List_fromArray(
		['M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm57.1 350.1L224.9 294c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h48c6.6 0 12 5.4 12 12v137.7l63.5 46.2c5.4 3.9 6.5 11.4 2.6 16.8l-28.2 38.8c-3.9 5.3-11.4 6.5-16.8 2.6z']));
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Utils$Utils$isNothing = function (maybe) {
	if (!maybe.$) {
		return false;
	} else {
		return true;
	}
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$Utils$Utils$onClickNoBubble = function (message) {
	return A2(
		$elm$html$Html$Events$custom,
		'click',
		$elm$json$Json$Decode$succeed(
			{b_: message, cb: true, co: true}));
};
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Schedule$Messages$ScheduleJobOperation = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Schedule$View$renderEligibleCell = F2(
	function (op, cell) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('eligible-cell__target'),
					$author$project$Utils$Utils$onClickNoBubble(
					$author$project$Messages$ScheduleMsg(
						A2($author$project$Schedule$Messages$ScheduleJobOperation, op, cell)))
				]),
			_List_Nil);
	});
var $author$project$Messages$GoTo = function (a) {
	return {$: 2, a: a};
};
var $author$project$Schedule$Models$HighlightedJob = function (a) {
	return {$: 2, a: a};
};
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $elm$html$Html$Events$onDoubleClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'dblclick',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Schedule$View$uriToUriClass = function (uri) {
	return 'uri' + A2(
		$elm$core$String$join,
		'-',
		$elm$core$List$reverse(
			A2(
				$elm$core$List$take,
				2,
				$elm$core$List$reverse(
					A2($elm$core$String$split, '/', uri)))));
};
var $author$project$Schedule$View$renderJobCell = F4(
	function (model, op, status, is_last) {
		var status_class = function () {
			switch (status) {
				case 0:
					return 'job-cell__setup';
				case 1:
					return 'job-cell__run';
				default:
					return 'job-cell__teardown';
			}
		}();
		var operation_type = A4(
			$author$project$Utils$Uri$getRelatedAttr,
			function ($) {
				return $.b5;
			},
			function ($) {
				return $.b0;
			},
			model.b7,
			op);
		var job_name = A4(
			$author$project$Utils$Uri$getRelatedAttr,
			function ($) {
				return $.bX;
			},
			function ($) {
				return $.b0;
			},
			model.ar,
			op);
		var client_uri = A4(
			$author$project$Utils$Uri$getRelatedAttr,
			function ($) {
				return $.bX;
			},
			function ($) {
				return $.bz;
			},
			model.ar,
			op);
		var client = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.b0;
				},
				A2($elm$core$Dict$get, client_uri, model.al)));
		var tooltip_body = A2(
			$elm$core$List$intersperse,
			A2($elm$html$Html$br, _List_Nil, _List_Nil),
			A2(
				$elm$core$List$map,
				$elm$html$Html$text,
				_List_fromArray(
					[
						client + ('-' + job_name),
						operation_type,
						'Count: ' + $elm$core$String$fromInt(op.bC),
						'Rate: ' + ($elm$core$String$fromInt(op.bK) + '/hr'),
						'Setup: ' + ($elm$core$String$fromInt(op.ck) + 'hrs'),
						'Conversion: ' + ($elm$core$String$fromInt(op.bD) + 'x'),
						'Teardown: ' + ($elm$core$String$fromInt(op.cq) + 'hrs')
					])));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('job-cell__last', is_last),
							_Utils_Tuple2(status_class, true),
							_Utils_Tuple2('uri', true),
							_Utils_Tuple2('job-cell', true),
							_Utils_Tuple2(
							$author$project$Schedule$View$uriToUriClass(op.bX),
							true),
							_Utils_Tuple2(
							$author$project$Schedule$View$uriToUriClass(client_uri),
							true)
						])),
					$author$project$Utils$Utils$onClickNoBubble(
					$author$project$Messages$ScheduleMsg(
						$author$project$Schedule$Messages$ChangeColorSchema(
							$author$project$Schedule$Models$HighlightedJob(op.bX)))),
					$elm$html$Html$Events$onDoubleClick(
					$author$project$Messages$GoTo(
						$author$project$Utils$Uri$linkFromUri(op.bX))),
					A2($elm$html$Html$Attributes$attribute, 'data-job', job_name),
					A2($elm$html$Html$Attributes$attribute, 'data-client', client)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(''),
							A2($elm$html$Html$Attributes$attribute, 'role', 'tooltip'),
							$elm$html$Html$Attributes$class('tooltip tooltip-sm tooltip-top-left')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tooltip-content')
								]),
							tooltip_body)
						]))
				]));
	});
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $author$project$Schedule$View$renderCell = F3(
	function (model, cell, slot) {
		var _v0 = function () {
			switch (slot.$) {
				case 0:
					return {z: 'available-cell', A: _List_Nil, J: 'Available'};
				case 1:
					var op = slot.a;
					var status = slot.b;
					var is_last = slot.c;
					return {
						z: '',
						A: _List_fromArray(
							[
								A4($author$project$Schedule$View$renderJobCell, model, op, status, is_last)
							]),
						J: ''
					};
				case 2:
					return {z: 'off-hours-cell', A: _List_Nil, J: 'Off Hours'};
				case 3:
					return {z: 'maintenance-cell', A: _List_Nil, J: 'Maintenance'};
				default:
					var op = slot.a;
					return {
						z: 'eligible-cell',
						A: _List_fromArray(
							[
								A2($author$project$Schedule$View$renderEligibleCell, op, cell)
							]),
						J: 'Eligible'
					};
			}
		}();
		var classes = _v0.z;
		var contents = _v0.A;
		var title_ = _v0.J;
		return A2(
			$elm$html$Html$td,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table-cell ' + classes),
					$elm$html$Html$Attributes$title(title_)
				]),
			contents);
	});
var $author$project$Schedule$View$renderDepartment = F3(
	function (model, schedule, department) {
		var times = $elm$core$List$sort(
			$elm$core$Set$toList(
				$elm$core$Set$fromList(
					A2(
						$elm$core$List$map,
						A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $elm$core$Tuple$second),
						schedule))));
		var renderMachineRow = function (machine) {
			var machine_slots = A2(
				$elm$core$List$sortBy,
				A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $elm$core$Tuple$second),
				A2(
					$elm$core$List$filter,
					function (_v2) {
						var _v3 = _v2.a;
						var machine_uri = _v3.a;
						return _Utils_eq(machine_uri, machine.bp);
					},
					schedule));
			return A2(
				$elm$html$Html$tr,
				_List_Nil,
				A2(
					$elm$core$List$cons,
					A2(
						$elm$html$Html$td,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(machine.b0)
							])),
					A2(
						$elm$core$List$map,
						function (_v1) {
							var cell = _v1.a;
							var operation = _v1.b;
							return A3($author$project$Schedule$View$renderCell, model, cell, operation);
						},
						machine_slots)));
		};
		var machines = A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.bp;
			},
			A2(
				$elm$core$List$filter,
				function (m) {
					return _Utils_eq(m.aH, department.bp);
				},
				$elm$core$Dict$values(model.aZ)));
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$thead,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$tr,
						_List_Nil,
						A2(
							$elm$core$List$cons,
							A2(
								$elm$html$Html$th,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(department.b0)
									])),
							A2(
								$elm$core$List$map,
								function (_v0) {
									return A2($elm$html$Html$th, _List_Nil, _List_Nil);
								},
								times)))
					])),
				A2(
				$elm$html$Html$tbody,
				_List_Nil,
				A2($elm$core$List$map, renderMachineRow, machines))
			]);
	});
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Utils$TimeSlots$isoToHeader = function (iso) {
	return A3(
		$elm$core$String$replace,
		'T',
		' ',
		A3($elm$core$String$replace, ':00:00', '', iso));
};
var $author$project$Schedule$View$renderTimeHeaders = function (times) {
	var rotateHeader = function (header) {
		return A2(
			$elm$html$Html$th,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('rotate')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Utils$TimeSlots$isoToHeader(header))
								]))
						]))
				]));
	};
	return A2(
		$elm$html$Html$thead,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$elm$html$Html$tr,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('no-edges')
					]),
				A2(
					$elm$core$List$cons,
					A2($elm$html$Html$th, _List_Nil, _List_Nil),
					A2($elm$core$List$map, rotateHeader, times)))
			]));
};
var $author$project$Schedule$View$renderSchedule = F2(
	function (model, schedule) {
		var times = $elm$core$List$sort(
			$elm$core$Set$toList(
				$elm$core$Set$fromList(
					A2(
						$elm$core$List$map,
						A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $elm$core$Tuple$second),
						schedule))));
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table schedule')
				]),
			A2(
				$elm$core$List$cons,
				$author$project$Schedule$View$renderTimeHeaders(times),
				A2(
					$elm$core$List$concatMap,
					A2($author$project$Schedule$View$renderDepartment, model, schedule),
					$elm$core$Dict$values(model.am))));
	});
var $author$project$Schedule$View$colorCycle = _List_fromArray(
	['var(--color-red)', 'var(--color-green)', 'var(--color-blue)', 'var(--color-orange)', 'var(--color-spring)', 'var(--color-violet)', 'var(--color-yellow)', 'var(--color-cyan)', 'var(--color-magenta)', 'var(--color-chartreuse)', 'var(--color-azure)', 'var(--color-rose)']);
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$Schedule$View$styleTag = function (styles) {
	return A3(
		$elm$html$Html$node,
		'style',
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(styles)
			]));
};
var $author$project$Schedule$View$renderStyles = function (model) {
	var styles = function () {
		var _v0 = model.bA;
		switch (_v0.$) {
			case 1:
				return A2(
					$elm$core$String$join,
					'\n',
					A2(
						$elm$core$List$map,
						function (_v1) {
							var color = _v1.a;
							var cls = _v1.b;
							return '.' + (cls + ('{background-color: ' + (color + '!important}')));
						},
						A3(
							$elm$core$List$map2,
							$elm$core$Tuple$pair,
							$elm$core$List$reverse($author$project$Schedule$View$colorCycle),
							A2(
								$elm$core$List$map,
								$author$project$Schedule$View$uriToUriClass,
								$elm$core$Dict$keys(model.al)))));
			case 0:
				return A2(
					$elm$core$String$join,
					'\n',
					A2(
						$elm$core$List$map,
						function (_v2) {
							var color = _v2.a;
							var cls = _v2.b;
							return '.' + (cls + ('{background-color: ' + (color + '!important}')));
						},
						A3(
							$elm$core$List$map2,
							$elm$core$Tuple$pair,
							$author$project$Schedule$View$colorCycle,
							A2(
								$elm$core$List$map,
								$author$project$Schedule$View$uriToUriClass,
								$elm$core$Dict$keys(model.ar)))));
			default:
				var job_uri = _v0.a;
				return function (cls) {
					return cls + '{background-color: cyan!important}';
				}(
					'.' + $author$project$Schedule$View$uriToUriClass(job_uri));
		}
	}();
	return $author$project$Schedule$View$styleTag(styles);
};
var $lattyware$elm_fontawesome$FontAwesome$Solid$searchMinus = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'search-minus',
	512,
	512,
	_List_fromArray(
		['M304 192v32c0 6.6-5.4 12-12 12H124c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z']));
var $lattyware$elm_fontawesome$FontAwesome$Solid$searchPlus = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'search-plus',
	512,
	512,
	_List_fromArray(
		['M304 192v32c0 6.6-5.4 12-12 12h-56v56c0 6.6-5.4 12-12 12h-32c-6.6 0-12-5.4-12-12v-56h-56c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h56v-56c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v56h56c6.6 0 12 5.4 12 12zm201 284.7L476.7 505c-9.4 9.4-24.6 9.4-33.9 0L343 405.3c-4.5-4.5-7-10.6-7-17V372c-35.3 27.6-79.7 44-128 44C93.1 416 0 322.9 0 208S93.1 0 208 0s208 93.1 208 208c0 48.3-16.4 92.7-44 128h16.3c6.4 0 12.5 2.5 17 7l99.7 99.7c9.3 9.4 9.3 24.6 0 34zM344 208c0-75.2-60.8-136-136-136S72 132.8 72 208s60.8 136 136 136 136-60.8 136-136z']));
var $lattyware$elm_fontawesome$FontAwesome$Solid$swatchbook = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'swatchbook',
	512,
	512,
	_List_fromArray(
		['M434.66,167.71h0L344.5,77.36a31.83,31.83,0,0,0-45-.07h0l-.07.07L224,152.88V424L434.66,212.9A32,32,0,0,0,434.66,167.71ZM480,320H373.09L186.68,506.51c-2.06,2.07-4.5,3.58-6.68,5.49H480a32,32,0,0,0,32-32V352A32,32,0,0,0,480,320ZM192,32A32,32,0,0,0,160,0H32A32,32,0,0,0,0,32V416a96,96,0,0,0,192,0ZM96,440a24,24,0,1,1,24-24A24,24,0,0,1,96,440Zm32-184H64V192h64Zm0-128H64V64h64Z']));
var $author$project$Schedule$View$viewEditSchedule = F2(
	function (model, schedule) {
		var headerText = function () {
			var _v0 = model.bt;
			if (!_v0.$) {
				var operation = _v0.a;
				return 'Scheduling:  ' + (A4(
					$author$project$Utils$Uri$getRelatedAttr,
					function ($) {
						return $.bX;
					},
					function ($) {
						return $.b0;
					},
					model.ar,
					operation) + (' ' + A4(
					$author$project$Utils$Uri$getRelatedAttr,
					function ($) {
						return $.b5;
					},
					function ($) {
						return $.b0;
					},
					model.b7,
					operation)));
			} else {
				return 'Edit Schedule';
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Messages$ScheduleMsg(
						$author$project$Schedule$Messages$ChangeColorSchema($author$project$Schedule$Models$VaryJob)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('clr-row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('clr-col-md-8 flex flex-row')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h2,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mar-none')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(headerText)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex-grow')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-success'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'hidden',
													!$elm$core$List$length(model.cd))
												])),
											$elm$html$Html$Events$onClick(
											$author$project$Messages$ScheduleMsg($author$project$Schedule$Messages$SaveProposedSchedule))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fas fa-save')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Save')
												]))
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-info'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'hidden',
													!$elm$core$List$length(model.cd))
												])),
											$elm$html$Html$Events$onClick(
											$author$project$Messages$ScheduleMsg($author$project$Schedule$Messages$UnProposeSchedule))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fas fa-undo')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Undo')
												]))
										])),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-danger'),
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'hidden',
													$author$project$Utils$Utils$isNothing(model.bt))
												])),
											$elm$html$Html$Events$onClick(
											$author$project$Messages$ScheduleMsg(
												$author$project$Schedule$Messages$SelectJobOperation($elm$core$Maybe$Nothing)))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$i,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('fas fa-times')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Cancel')
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex-grow')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('clr-col-md-4 text-right')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn-group btn-primary btn-icon')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Scroll Left'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$ScrollTime(-8)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$angleDoubleLeft)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Scroll Left'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$ScrollTime(-1)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$chevronLeft)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Scroll Now'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg($author$project$Schedule$Messages$ScrollNow))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$clock)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Scroll Right'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$ScrollTime(1)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$chevronRight)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Scroll Right'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$ScrollTime(8)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$angleDoubleRight)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn-group btn-primary btn-icon')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Zoom Out'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$SizeWindow(1)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$searchMinus)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Zoom In'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$SizeWindow(-1)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$searchPlus)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('dropdown', true),
													_Utils_Tuple2('bottom-right', true),
													_Utils_Tuple2(
													'open',
													_Utils_eq(
														model.b4,
														$elm$core$Maybe$Just('schedule-colors')))
												]))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary dropdown-toggle'),
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Attributes$title('Change Coloring'),
													$elm$html$Html$Events$onClick(
													$author$project$Messages$UIMsg(
														$author$project$UI$Messages$DropdownMsg(
															$elm$core$Maybe$Just('schedule-colors'))))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$swatchbook)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('dropdown-menu'),
													$elm$html$Html$Events$onMouseLeave(
													$author$project$Messages$UIMsg(
														$author$project$UI$Messages$DropdownMsg($elm$core$Maybe$Nothing))),
													$elm$html$Html$Events$onBlur(
													$author$project$Messages$UIMsg(
														$author$project$UI$Messages$DropdownMsg($elm$core$Maybe$Nothing)))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('button'),
															$elm$html$Html$Attributes$class('dropdown-item'),
															$author$project$Utils$Utils$onClickNoBubble(
															$author$project$Messages$ScheduleMsg(
																$author$project$Schedule$Messages$ChangeColorSchema($author$project$Schedule$Models$VaryJob)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Color By Job')
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('button'),
															$elm$html$Html$Attributes$class('dropdown-item'),
															$author$project$Utils$Utils$onClickNoBubble(
															$author$project$Messages$ScheduleMsg(
																$author$project$Schedule$Messages$ChangeColorSchema($author$project$Schedule$Models$VaryClient)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Color By Client')
														]))
												]))
										]))
								]))
						])),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('clr-row horizontal-scroll')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('clr-col-md-12')
								]),
							_List_fromArray(
								[
									$author$project$Schedule$View$renderStyles(model),
									A2($author$project$Schedule$View$renderSchedule, model, schedule)
								]))
						]))
				]));
	});
var $author$project$Schedule$View$viewSchedule = F2(
	function (model, schedule) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					$author$project$Messages$ScheduleMsg(
						$author$project$Schedule$Messages$ChangeColorSchema($author$project$Schedule$Models$VaryJob)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('clr-row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('clr-col-md-6')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h2,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mar-none')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Schedule')
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('clr-col-md-6 text-right')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn-group btn-primary btn-icon')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Scroll Left'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$ScrollTime(-8)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$angleDoubleLeft)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Scroll Left'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$ScrollTime(-1)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$chevronLeft)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Scroll Now'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg($author$project$Schedule$Messages$ScrollNow))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$clock)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Scroll Right'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$ScrollTime(1)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$chevronRight)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Scroll Right'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$ScrollTime(8)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$angleDoubleRight)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn-group btn-primary btn-icon')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Zoom Out'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$SizeWindow(1)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$searchMinus)
												])),
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn'),
													$elm$html$Html$Attributes$title('Zoom In'),
													$author$project$Utils$Utils$onClickNoBubble(
													$author$project$Messages$ScheduleMsg(
														$author$project$Schedule$Messages$SizeWindow(-1)))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$searchPlus)
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('dropdown', true),
													_Utils_Tuple2('bottom-right', true),
													_Utils_Tuple2(
													'open',
													_Utils_eq(
														model.b4,
														$elm$core$Maybe$Just('schedule-colors')))
												]))
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$button,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('btn btn-primary dropdown-toggle'),
													$elm$html$Html$Attributes$type_('button'),
													$elm$html$Html$Attributes$title('Change Coloring'),
													$elm$html$Html$Events$onClick(
													$author$project$Messages$UIMsg(
														$author$project$UI$Messages$DropdownMsg(
															$elm$core$Maybe$Just('schedule-colors'))))
												]),
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$swatchbook)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('dropdown-menu'),
													$elm$html$Html$Events$onMouseLeave(
													$author$project$Messages$UIMsg(
														$author$project$UI$Messages$DropdownMsg($elm$core$Maybe$Nothing))),
													$elm$html$Html$Events$onBlur(
													$author$project$Messages$UIMsg(
														$author$project$UI$Messages$DropdownMsg($elm$core$Maybe$Nothing)))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('button'),
															$elm$html$Html$Attributes$class('dropdown-item'),
															$author$project$Utils$Utils$onClickNoBubble(
															$author$project$Messages$ScheduleMsg(
																$author$project$Schedule$Messages$ChangeColorSchema($author$project$Schedule$Models$VaryJob)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Color By Job')
														])),
													A2(
													$elm$html$Html$button,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('button'),
															$elm$html$Html$Attributes$class('dropdown-item'),
															$author$project$Utils$Utils$onClickNoBubble(
															$author$project$Messages$ScheduleMsg(
																$author$project$Schedule$Messages$ChangeColorSchema($author$project$Schedule$Models$VaryClient)))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Color By Client')
														]))
												]))
										]))
								]))
						])),
					A2($elm$html$Html$br, _List_Nil, _List_Nil),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('clr-row horizontal-scroll')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('clr-col-md-12')
								]),
							_List_fromArray(
								[
									$author$project$Schedule$View$renderStyles(model),
									A2($author$project$Schedule$View$renderSchedule, model, schedule)
								]))
						]))
				]));
	});
var $author$project$Schedule$View$view = F2(
	function (model, route) {
		if (!route) {
			return A2(
				$author$project$Schedule$View$viewSchedule,
				model,
				$author$project$Schedule$Calculate$viewCalculatedSchedule(model));
		} else {
			return A2(
				$author$project$Schedule$View$viewEditSchedule,
				model,
				$author$project$Schedule$Calculate$viewCalculatedScheduleWithProposed(model));
		}
	});
var $author$project$Shifts$Messages$ShiftDays = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Shifts$Messages$ShiftFormMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Shifts$Messages$ShiftMachine = function (a) {
	return {$: 2, a: a};
};
var $author$project$Shifts$Messages$ShiftName = function (a) {
	return {$: 0, a: a};
};
var $author$project$Shifts$Messages$ShiftPriority = function (a) {
	return {$: 7, a: a};
};
var $author$project$Shifts$Messages$ShiftRangeStart = function (a) {
	return {$: 3, a: a};
};
var $author$project$Shifts$Messages$ShiftRangeStop = function (a) {
	return {$: 4, a: a};
};
var $author$project$Shifts$Messages$ShiftTimeEnd = function (a) {
	return {$: 6, a: a};
};
var $author$project$Shifts$Messages$ShiftTimeStart = function (a) {
	return {$: 5, a: a};
};
var $author$project$Shifts$Messages$SubmitShift = {$: 2};
var $author$project$Utils$Clarity$clarityDateControl = F2(
	function (idLabel, attrs) {
		var inputId = idLabel.a;
		return A2(
			$author$project$Utils$Clarity$clarityFormControl,
			idLabel,
			_List_fromArray(
				[
					$author$project$Utils$Clarity$clarityInput(
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(inputId),
								$elm$html$Html$Attributes$type_('date')
							]),
						attrs))
				]));
	});
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $author$project$Utils$TimeSlots$posixToIsoDay = function (time) {
	return $elm$core$String$fromInt(
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time)) + ('-' + ($author$project$Utils$TimeSlots$monthToString(
		A2($elm$time$Time$toMonth, $elm$time$Time$utc, time)) + ('-' + $elm$core$String$fromInt(
		A2($elm$time$Time$toDay, $elm$time$Time$utc, time)))));
};
var $author$project$Shifts$View$viewShiftCreate = function (model) {
	var machine_options = A2(
		$elm$core$List$map,
		function (o) {
			return _Utils_Tuple2(o.bp, o.b0);
		},
		$elm$core$Dict$values(model.aZ));
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h2,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href('/shifts')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Shifts')
									])),
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('New Shift')
									]))
							]))
					])),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$form,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('clr-form clr-form-compact'),
								$elm$html$Html$Events$onSubmit(
								$author$project$Messages$ShiftsMsg($author$project$Shifts$Messages$SubmitShift))
							]),
						_List_fromArray(
							[
								A2(
								$author$project$Utils$Clarity$clarityTextControl,
								_Utils_Tuple2('shift_name', 'Shift Name'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(model.cl.b0),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$ShiftsMsg, $author$project$Shifts$Messages$ShiftFormMsg),
											$author$project$Shifts$Messages$ShiftName))
									])),
								A2(
								$author$project$Utils$Clarity$clarityFormControl,
								_Utils_Tuple2('shift-days', 'Days'),
								A2(
									$elm$core$List$map,
									function (wd) {
										return A2(
											$author$project$Utils$Clarity$clarityCheckbox,
											_Utils_Tuple2(
												$author$project$Utils$TimeSlots$showDay(wd),
												$author$project$Utils$TimeSlots$showDay(wd)),
											_List_fromArray(
												[
													$elm$html$Html$Attributes$name('weekdays'),
													$elm$html$Html$Attributes$value(
													$author$project$Utils$TimeSlots$showDay(wd)),
													$elm$html$Html$Events$onCheck(
													A2(
														$elm$core$Basics$composeL,
														A2($elm$core$Basics$composeL, $author$project$Messages$ShiftsMsg, $author$project$Shifts$Messages$ShiftFormMsg),
														$author$project$Shifts$Messages$ShiftDays(wd))),
													$elm$html$Html$Attributes$checked(
													A2($elm$core$List$member, wd, model.cl.bG))
												]));
									},
									_List_fromArray(
										[6, 0, 1, 2, 3, 4, 5]))),
								A5(
								$author$project$Utils$Clarity$claritySelectControl,
								_Utils_Tuple2('machine', 'Machine'),
								_List_fromArray(
									[
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$ShiftsMsg, $author$project$Shifts$Messages$ShiftFormMsg),
											$author$project$Shifts$Messages$ShiftMachine))
									]),
								machine_options,
								'Shift for Machine',
								A2($elm$core$Maybe$withDefault, '', model.cl.aY)),
								A2(
								$author$project$Utils$Clarity$clarityDateControl,
								_Utils_Tuple2('range_start', 'Valid Schedule Start'),
								_List_fromArray(
									[
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$ShiftsMsg, $author$project$Shifts$Messages$ShiftFormMsg),
											$author$project$Shifts$Messages$ShiftRangeStart)),
										$elm$html$Html$Attributes$value(
										A2(
											$elm$core$Maybe$withDefault,
											'',
											A2(
												$elm$core$Maybe$map,
												A2($elm$core$Basics$composeL, $author$project$Utils$TimeSlots$posixToIsoDay, $elm$core$Tuple$first),
												model.cl.bq)))
									])),
								A2(
								$author$project$Utils$Clarity$clarityDateControl,
								_Utils_Tuple2('range_end', 'Valid Schedule End'),
								_List_fromArray(
									[
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2($elm$core$Basics$composeL, $author$project$Messages$ShiftsMsg, $author$project$Shifts$Messages$ShiftFormMsg),
											$author$project$Shifts$Messages$ShiftRangeStop)),
										$elm$html$Html$Attributes$value(
										A2(
											$elm$core$Maybe$withDefault,
											'',
											A2(
												$elm$core$Maybe$map,
												A2($elm$core$Basics$composeL, $author$project$Utils$TimeSlots$posixToIsoDay, $elm$core$Tuple$second),
												model.cl.bq)))
									])),
								A2(
								$author$project$Utils$Clarity$clarityNumberControl,
								_Utils_Tuple2('time_start', 'Shift Hour Start'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromInt(model.cl.bi.a)),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2(
												$elm$core$Basics$composeL,
												A2(
													$elm$core$Basics$composeL,
													A2($elm$core$Basics$composeL, $author$project$Messages$ShiftsMsg, $author$project$Shifts$Messages$ShiftFormMsg),
													$author$project$Shifts$Messages$ShiftTimeStart),
												$elm$core$Maybe$withDefault(0)),
											$elm$core$String$toInt)),
										$elm$html$Html$Attributes$min('0'),
										$elm$html$Html$Attributes$max('23')
									])),
								A2(
								$author$project$Utils$Clarity$clarityNumberControl,
								_Utils_Tuple2('time_end', 'Shift Hour End'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromInt(model.cl.bi.b)),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2(
												$elm$core$Basics$composeL,
												A2(
													$elm$core$Basics$composeL,
													A2($elm$core$Basics$composeL, $author$project$Messages$ShiftsMsg, $author$project$Shifts$Messages$ShiftFormMsg),
													$author$project$Shifts$Messages$ShiftTimeEnd),
												$elm$core$Maybe$withDefault(0)),
											$elm$core$String$toInt)),
										$elm$html$Html$Attributes$min('1'),
										$elm$html$Html$Attributes$max('24')
									])),
								A2(
								$author$project$Utils$Clarity$clarityNumberControl,
								_Utils_Tuple2('priority', 'Shift Rule Priority (Higher is better)'),
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										$elm$core$String$fromInt(model.cl.cc)),
										$elm$html$Html$Events$onInput(
										A2(
											$elm$core$Basics$composeL,
											A2(
												$elm$core$Basics$composeL,
												A2(
													$elm$core$Basics$composeL,
													A2($elm$core$Basics$composeL, $author$project$Messages$ShiftsMsg, $author$project$Shifts$Messages$ShiftFormMsg),
													$author$project$Shifts$Messages$ShiftPriority),
												$elm$core$Maybe$withDefault(1)),
											$elm$core$String$toInt)),
										$elm$html$Html$Attributes$min('1'),
										$elm$html$Html$Attributes$max('100')
									])),
								A2(
								$elm$html$Html$button,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$type_('submit'),
										$elm$html$Html$Attributes$class('btn btn-primary'),
										$elm$html$Html$Attributes$disabled(
										!A2($author$project$Utils$Utils$validateForm, _List_Nil, model.cl))
									]),
								_List_Nil)
							]))
					]))
			]));
};
var $author$project$Shifts$View$shiftDetailHeader = function (shift) {
	return $author$project$Utils$Clarity$gridFullBlock(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('mar-none header-breadcrumb')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$href('/shifts')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Shifts')
							])),
						A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(shift.b0)
							]))
					]))
			]));
};
var $author$project$Utils$Clarity$clarityVerticalTable = F2(
	function (fields, record) {
		return A2(
			$elm$html$Html$table,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('table table-vertical mar-t-none')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (_v0) {
							var field = _v0.a;
							var accessor = _v0.b;
							return A2(
								$elm$html$Html$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$elm$html$Html$th,
										_List_Nil,
										_List_fromArray(
											[
												$elm$html$Html$text(field)
											])),
										A2(
										$elm$html$Html$td,
										_List_Nil,
										_List_fromArray(
											[
												accessor(record)
											]))
									]));
						},
						fields))
				]));
	});
var $author$project$Shifts$View$showDays = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map($author$project$Utils$TimeSlots$showDay),
	$elm$core$String$join(','));
var $author$project$Shifts$View$shiftInfo = $author$project$Utils$Clarity$clarityVerticalTable(
	_List_fromArray(
		[
			_Utils_Tuple2(
			'Days',
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bG;
				},
				A2($elm$core$Basics$composeR, $author$project$Shifts$View$showDays, $elm$html$Html$text))),
			_Utils_Tuple2(
			'Machine(s)',
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.aY;
				},
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Maybe$withDefault('All Machines'),
					$elm$html$Html$text))),
			_Utils_Tuple2(
			'Valid Date Range',
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bq;
				},
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Maybe$map(
						A2($elm$core$Tuple$mapBoth, $author$project$Utils$TimeSlots$posixToIsoDay, $author$project$Utils$TimeSlots$posixToIsoDay)),
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(
							A2(
								$elm$core$Basics$composeR,
								$author$project$Utils$Utils$tupleToList,
								$elm$core$String$join(','))),
						A2(
							$elm$core$Basics$composeR,
							$elm$core$Maybe$withDefault('All Time'),
							$elm$html$Html$text))))),
			_Utils_Tuple2(
			'Shift Start Time',
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bi;
				},
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Tuple$first,
					A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text)))),
			_Utils_Tuple2(
			'Shift End Time',
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.bi;
				},
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Tuple$second,
					A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text)))),
			_Utils_Tuple2(
			'Priority',
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.cc;
				},
				A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text)))
		]));
var $author$project$Shifts$View$viewShiftDetail = F2(
	function (model, shift) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Shifts$View$shiftDetailHeader(shift),
					$author$project$Shifts$View$shiftInfo(shift)
				]));
	});
var $author$project$Shifts$View$viewShiftList = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Utils$Clarity$gridTwoColumns(
				_Utils_Tuple2(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h2,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('mar-none')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Shifts')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-right')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$a,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('btn btn-primary'),
											$elm$html$Html$Attributes$href('/shifts/create')
										]),
									_List_fromArray(
										[
											$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$plus),
											A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('mar-l-sm')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Create Shift')
												]))
										]))
								]))
						]))),
				$author$project$Utils$Clarity$gridFullBlock(
				_List_fromArray(
					[
						A2(
						$author$project$Utils$Clarity$viewTable,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'Name',
								function (shift) {
									return A2(
										$elm$html$Html$a,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$href(
												$author$project$Utils$Uri$linkFromUri(shift.bp))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(shift.b0)
											]));
								}),
								_Utils_Tuple2(
								'Days',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.bG;
									},
									A2($elm$core$Basics$composeR, $author$project$Shifts$View$showDays, $elm$html$Html$text))),
								_Utils_Tuple2(
								'Machine',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.aY;
									},
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Maybe$withDefault('All'),
										$elm$html$Html$text))),
								_Utils_Tuple2(
								'Date Range',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.aY;
									},
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Maybe$withDefault('All'),
										$elm$html$Html$text))),
								_Utils_Tuple2(
								'Start',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.bi;
									},
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Tuple$first,
										A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text)))),
								_Utils_Tuple2(
								'End',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.bi;
									},
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Tuple$second,
										A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text)))),
								_Utils_Tuple2(
								'Priority',
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.cc;
									},
									A2($elm$core$Basics$composeR, $elm$core$String$fromInt, $elm$html$Html$text)))
							]),
						model.cm)
					]))
			]));
};
var $author$project$Shifts$View$view = F2(
	function (model, route) {
		switch (route.$) {
			case 0:
				return $author$project$Shifts$View$viewShiftList(model);
			case 1:
				return $author$project$Shifts$View$viewShiftCreate(model);
			default:
				var shift_id = route.a;
				var _v1 = $elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (s) {
							return A2($elm$core$String$endsWith, '/shifts/' + (shift_id + '/'), s.bp);
						},
						model.cm));
				if (!_v1.$) {
					var shift = _v1.a;
					return A2($author$project$Shifts$View$viewShiftDetail, model, shift);
				} else {
					return A2(
						$elm$html$Html$div,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Shift Not Found')
							]));
				}
		}
	});
var $author$project$Jobs$Messages$FilterJobClient = function (a) {
	return {$: 1, a: a};
};
var $author$project$Jobs$Messages$FilterJobListMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Jobs$Messages$FilterJobSearch = function (a) {
	return {$: 0, a: a};
};
var $author$project$Jobs$Messages$FilterJobStatus = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $author$project$Utils$Clarity$claritySidenav = function (content) {
	return A2(
		$elm$html$Html$nav,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('sidenav')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$section,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sidenav-content')
					]),
				content)
			]));
};
var $lattyware$elm_fontawesome$FontAwesome$Solid$search = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'search',
	512,
	512,
	_List_fromArray(
		['M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z']));
var $author$project$Jobs$View$viewSidenav = F2(
	function (model, route) {
		if (!route.$) {
			return $elm$core$Maybe$Just(
				$author$project$Utils$Clarity$claritySidenav(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('pad-l-xlg')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h5,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Search')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex flex-row flex-align_center no-child-margin mar-t-md')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$span,
											_List_Nil,
											_List_fromArray(
												[
													$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon($lattyware$elm_fontawesome$FontAwesome$Solid$search)
												])),
											A2(
											$author$project$Utils$Clarity$clarityTextControl,
											_Utils_Tuple2('job-search', ''),
											_List_fromArray(
												[
													$elm$html$Html$Attributes$placeholder('Search Jobs'),
													$elm$html$Html$Events$onInput(
													A2(
														$elm$core$Basics$composeL,
														A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$FilterJobListMsg),
														$author$project$Jobs$Messages$FilterJobSearch)),
													$elm$html$Html$Attributes$value(model.aV.bg)
												]))
										])),
									A2(
									$elm$html$Html$h5,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Filter')
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A5(
											$author$project$Utils$Clarity$claritySelectControl,
											_Utils_Tuple2('filterClient', 'Filter By Client'),
											_List_fromArray(
												[
													$elm$html$Html$Events$onInput(
													A2(
														$elm$core$Basics$composeL,
														A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$FilterJobListMsg),
														$author$project$Jobs$Messages$FilterJobClient))
												]),
											A2(
												$elm$core$List$map,
												function (c) {
													return _Utils_Tuple2(c.bp, c.b0);
												},
												$elm$core$Dict$values(model.al)),
											'Select Client',
											model.aV.bz)
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A5(
											$author$project$Utils$Clarity$claritySelectControl,
											_Utils_Tuple2('filterStatus', 'Filter By Status'),
											_List_fromArray(
												[
													$elm$html$Html$Events$onInput(
													A2(
														$elm$core$Basics$composeL,
														A2($elm$core$Basics$composeL, $author$project$Messages$JobsMsg, $author$project$Jobs$Messages$FilterJobListMsg),
														$author$project$Jobs$Messages$FilterJobStatus))
												]),
											A2(
												$elm$core$List$map,
												function (c) {
													return _Utils_Tuple2(c.bp, c.b0);
												},
												$elm$core$Dict$values(model.al)),
											'Select Status',
											model.aV.W)
										])),
									A2(
									$elm$html$Html$h5,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Sort')
										])),
									A2($elm$html$Html$div, _List_Nil, _List_Nil)
								]))
						])));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$html$Html$hr = _VirtualDom_node('hr');
var $author$project$Schedule$View$IsPending = 2;
var $author$project$Schedule$View$IsReady = 1;
var $author$project$Schedule$View$IsScheduled = 0;
var $author$project$Schedule$View$jobOperationIsReady = F2(
	function (model, jobOperation) {
		return A2(
			$elm$core$List$all,
			function (uri) {
				return A2(
					$elm$core$List$member,
					uri,
					A2(
						$elm$core$List$map,
						function ($) {
							return $.aW;
						},
						model.ci));
			},
			jobOperation.bH);
	});
var $author$project$Schedule$View$jobOperationIsScheduled = F2(
	function (model, jobOperation) {
		return A2(
			$elm$core$List$member,
			jobOperation.bp,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.aW;
				},
				model.ci));
	});
var $author$project$Schedule$View$getOperationScheduleStatus = F2(
	function (model, jobOperation) {
		var _v0 = _Utils_Tuple2(
			A2($author$project$Schedule$View$jobOperationIsScheduled, model, jobOperation),
			A2($author$project$Schedule$View$jobOperationIsReady, model, jobOperation));
		if (_v0.a) {
			return 0;
		} else {
			if (_v0.b) {
				return 1;
			} else {
				return 2;
			}
		}
	});
var $author$project$Utils$Uri$idFromUri = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$split('/'),
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$reverse,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$filter(
				$elm$core$Basics$neq('')),
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$head,
				$elm$core$Maybe$withDefault('NA')))));
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $author$project$Schedule$View$showJobTree = function (model) {
	var renderJob = function (job) {
		var renderOperation = function (op) {
			var actions = function () {
				var _v0 = A2($author$project$Schedule$View$getOperationScheduleStatus, model, op);
				switch (_v0) {
					case 0:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fas fa-check mar-l-sm'),
										$elm$html$Html$Attributes$title('Scheduled')
									]),
								_List_Nil),
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fas fa-ellipsis-v mar-l-sm')
									]),
								_List_Nil)
							]);
					case 1:
						var _v1 = _Utils_Tuple2(
							_Utils_eq(
								$elm$core$Maybe$Just(op),
								model.bt),
							A2(
								$elm$core$List$member,
								op.bp,
								A2(
									$elm$core$List$map,
									function ($) {
										return $.aW;
									},
									model.cd)));
						if (!_v1.a) {
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fas fa-location'),
											$elm$html$Html$Attributes$title('Plan'),
											$elm$html$Html$Events$onClick(
											$author$project$Messages$ScheduleMsg(
												$author$project$Schedule$Messages$SelectJobOperation(
													$elm$core$Maybe$Just(op))))
										]),
									_List_Nil)
								]);
						} else {
							if (!_v1.b) {
								return _List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fas fa-times mar-l-sm'),
												$elm$html$Html$Attributes$title('Cancel'),
												$elm$html$Html$Events$onClick(
												$author$project$Messages$ScheduleMsg(
													$author$project$Schedule$Messages$SelectJobOperation($elm$core$Maybe$Nothing)))
											]),
										_List_Nil)
									]);
							} else {
								return _List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fas fa-save mar-l-sm'),
												$elm$html$Html$Attributes$title('Save'),
												$elm$html$Html$Events$onClick(
												$author$project$Messages$ScheduleMsg($author$project$Schedule$Messages$SaveProposedSchedule))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fas fa-undo mar-l-sm'),
												$elm$html$Html$Attributes$title('Undo'),
												$elm$html$Html$Events$onClick(
												$author$project$Messages$ScheduleMsg($author$project$Schedule$Messages$UnProposeSchedule))
											]),
										_List_Nil),
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fas fa-times mar-l-sm'),
												$elm$html$Html$Attributes$title('Cancel'),
												$elm$html$Html$Events$onClick(
												$author$project$Messages$ScheduleMsg(
													$author$project$Schedule$Messages$SelectJobOperation($elm$core$Maybe$Nothing)))
											]),
										_List_Nil)
									]);
							}
						}
					default:
						return _List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fas fa-hourglass'),
										$elm$html$Html$Attributes$title('Awaiting Dependencies to be Scheduled')
									]),
								_List_Nil)
							]);
				}
			}();
			return A2(
				$elm$html$Html$li,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$a,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('nav-link flex flex-row pad-r-lg'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'active',
										_Utils_eq(
											$elm$core$Maybe$Just(op),
											model.bt))
									])),
								$elm$html$Html$Attributes$href('')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A4(
											$author$project$Utils$Uri$getRelatedAttr,
											function ($) {
												return $.b5;
											},
											function ($) {
												return $.b0;
											},
											model.b7,
											op))
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-grow')
									]),
								_List_Nil),
								A2($elm$html$Html$span, _List_Nil, actions)
							]))
					]));
		};
		var operations = A2(
			$elm$core$List$filter,
			function (op) {
				return _Utils_eq(op.bX, job.bp);
			},
			$elm$core$Dict$values(model.aq));
		var clientName = A4(
			$author$project$Utils$Uri$getRelatedAttr,
			function ($) {
				return $.bz;
			},
			function ($) {
				return $.b0;
			},
			model.al,
			job);
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('nav-group collapsible')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('checkbox'),
							$elm$html$Html$Attributes$id(
							'job-' + $author$project$Utils$Uri$idFromUri(job.bp))
						]),
					_List_Nil),
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$for(
							'job-' + $author$project$Utils$Uri$idFromUri(job.bp))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$strong,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(clientName + (' ' + job.b0))
								]))
						])),
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('nav-list')
						]),
					A2($elm$core$List$map, renderOperation, operations))
				]));
	};
	return A2(
		$elm$core$List$map,
		renderJob,
		$elm$core$Dict$values(model.ar));
};
var $author$project$Schedule$View$viewSidenav = F2(
	function (model, route) {
		if (!route) {
			return $elm$core$Maybe$Nothing;
		} else {
			return $elm$core$Maybe$Just(
				$author$project$Utils$Clarity$claritySidenav(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_Nil,
							A2(
								$elm$core$List$cons,
								A2(
									$elm$html$Html$h5,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('pad-h-lg')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Jobs')
										])),
								A2(
									$elm$core$List$cons,
									A2($elm$html$Html$hr, _List_Nil, _List_Nil),
									$author$project$Schedule$View$showJobTree(model))))
						])));
		}
	});
var $author$project$View$viewContent = F2(
	function (model, route) {
		var wrapContentArea = function (content) {
			return $elm$core$Maybe$Just(
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('content-area')
						]),
					_List_fromArray(
						[content])));
		};
		var _v0 = function () {
			switch (route.$) {
				case 1:
					return _Utils_Tuple2($author$project$View$view404, $elm$core$Maybe$Nothing);
				case 9:
					return _Utils_Tuple2(
						A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('TODO: Layout Landing / Homepage')
								])),
						$elm$core$Maybe$Nothing);
				case 2:
					var jobsRoute = route.a;
					return _Utils_Tuple2(
						A2($author$project$Jobs$View$view, model, jobsRoute),
						A2($author$project$Jobs$View$viewSidenav, model, jobsRoute));
				case 3:
					var clientsRoute = route.a;
					return _Utils_Tuple2(
						A2($author$project$Clients$View$view, model, clientsRoute),
						$elm$core$Maybe$Nothing);
				case 4:
					var machinesRoute = route.a;
					return _Utils_Tuple2(
						A2($author$project$Machines$View$view, model, machinesRoute),
						$elm$core$Maybe$Nothing);
				case 5:
					var operationsRoute = route.a;
					return _Utils_Tuple2(
						A2($author$project$Operations$View$view, model, operationsRoute),
						$elm$core$Maybe$Nothing);
				case 6:
					var departmentsRoute = route.a;
					return _Utils_Tuple2(
						A2($author$project$Departments$View$view, model, departmentsRoute),
						$elm$core$Maybe$Nothing);
				case 7:
					var scheduleRoute = route.a;
					return _Utils_Tuple2(
						A2($author$project$Schedule$View$view, model, scheduleRoute),
						A2($author$project$Schedule$View$viewSidenav, model, scheduleRoute));
				case 8:
					var shiftRoute = route.a;
					return _Utils_Tuple2(
						A2($author$project$Shifts$View$view, model, shiftRoute),
						$elm$core$Maybe$Nothing);
				default:
					return _Utils_Tuple2($author$project$View$viewLoading, $elm$core$Maybe$Nothing);
			}
		}();
		var body = _v0.a;
		var sidenav = _v0.b;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('content-container')
					]),
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							wrapContentArea(body),
							sidenav
						]))));
	});
var $author$project$UI$View$alertLevelToClasses = function (level) {
	switch (level) {
		case 0:
			return _Utils_Tuple2('alert-danger', 'fa-exclamation-circle');
		case 1:
			return _Utils_Tuple2('alert-warning', 'fa-exclamation-triangle');
		case 2:
			return _Utils_Tuple2('alert-info', 'fa-info-circle');
		default:
			return _Utils_Tuple2('alert-success', 'fa-check-circle');
	}
};
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$UI$View$showGlobalAlert = function (alert) {
	var _v0 = $author$project$UI$View$alertLevelToClasses(alert.bZ);
	var alert_class = _v0.a;
	var alert_icon = _v0.b;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('alert alert-app-level ' + alert_class)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('alert-items')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('alert-item static')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('alert-icon-wrapper')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$i,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fas' + alert_icon)
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('alert-text')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(alert.b_)
									]))
							]))
					])),
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Attributes$class('close'),
						A2($elm$html$Html$Attributes$style, 'font-size', '1rem'),
						$elm$html$Html$Events$onClick(
						$author$project$Messages$UIMsg(
							$author$project$UI$Messages$AlertMsg($elm$core$Maybe$Nothing)))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$i,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fas fa-times')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$View$viewGlobalAlert = F2(
	function (model, _v0) {
		return A2($elm$core$Maybe$map, $author$project$UI$View$showGlobalAlert, model.bQ);
	});
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$View$navLink = F3(
	function (url, display, route) {
		var route_url = $author$project$Routes$routeToString(route);
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('nav-link', true),
							_Utils_Tuple2(
							'active',
							A2($elm$core$String$startsWith, url, route_url))
						])),
					$elm$html$Html$Attributes$href(url)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('nav-text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(display)
						]))
				]));
	});
var $author$project$Auth$Messages$Logout = {$: 5};
var $author$project$UI$Messages$ToggleDarkTheme = function (a) {
	return {$: 2, a: a};
};
var $author$project$View$showHeaderActions = F2(
	function (is_dark_theme, open_dropdown) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('header-actions')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('dropdown', true),
									_Utils_Tuple2(
									'open',
									_Utils_eq(
										open_dropdown,
										$elm$core$Maybe$Just('account_options_dropdown')))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropdown-toggle'),
									$elm$html$Html$Attributes$type_('button'),
									A2($elm$html$Html$Attributes$style, 'padding', '0 15px'),
									$elm$html$Html$Attributes$title('Account Options'),
									$elm$html$Html$Events$onClick(
									$author$project$Messages$UIMsg(
										$author$project$UI$Messages$DropdownMsg(
											$elm$core$Maybe$Just('account_options_dropdown'))))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fas fa-user fa-lg')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$i,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fas fa-caret-down')
										]),
									_List_Nil)
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('dropdown-menu'),
									$elm$html$Html$Events$onMouseLeave(
									$author$project$Messages$UIMsg(
										$author$project$UI$Messages$DropdownMsg($elm$core$Maybe$Nothing))),
									$elm$html$Html$Events$onBlur(
									$author$project$Messages$UIMsg(
										$author$project$UI$Messages$DropdownMsg($elm$core$Maybe$Nothing)))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('dropdown-item')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('clr-toggle-wrapper'),
													$elm$html$Html$Events$onClick(
													$author$project$Messages$UIMsg(
														$author$project$UI$Messages$ToggleDarkTheme(!is_dark_theme)))
												]),
											_List_fromArray(
												[
													A2(
													$elm$html$Html$input,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$type_('checkbox'),
															$elm$html$Html$Attributes$id('dark-themne'),
															$elm$html$Html$Attributes$class('clr-toggle'),
															$elm$html$Html$Attributes$checked(is_dark_theme)
														]),
													_List_Nil),
													A2(
													$elm$html$Html$label,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Dark Theme')
														]))
												]))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('dropdown-divider')
										]),
									_List_Nil),
									A2(
									$elm$html$Html$button,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$type_('button'),
											$elm$html$Html$Attributes$class('dropdown-item'),
											$elm$html$Html$Events$onClick(
											$author$project$Messages$AuthMsg($author$project$Auth$Messages$Logout))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Log Out')
										]))
								]))
						]))
				]));
	});
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$View$viewHeader = F2(
	function (model, route) {
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$header,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('header header-6')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('branding')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('nav-link nav-text'),
										$elm$html$Html$Attributes$href('/')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$img,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$src('/logo.svg'),
												A2($elm$html$Html$Attributes$style, 'height', '25px'),
												A2($elm$html$Html$Attributes$style, 'margin-right', '15px')
											]),
										_List_Nil),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('title')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Janet Scheduling')
											]))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('header-nav')
							]),
						_List_fromArray(
							[
								A3($author$project$View$navLink, '/jobs', 'Jobs', route),
								A3($author$project$View$navLink, '/schedule', 'Schedule', route),
								A3($author$project$View$navLink, '/machines', 'Machines', route),
								A3($author$project$View$navLink, '/operations', 'Operations', route),
								A3($author$project$View$navLink, '/departments', 'Departments', route),
								A3($author$project$View$navLink, '/clients', 'Clients', route),
								A3($author$project$View$navLink, '/shifts', 'Shifts', route),
								A3($author$project$View$navLink, '/users', 'Users', route)
							])),
						A2($author$project$View$showHeaderActions, model.bV, model.b4)
					])));
	});
var $author$project$Utils$Clarity$claritySubnav = F2(
	function (links, current_url) {
		var renderLink = function (_v1) {
			var maybeIcon = _v1.a;
			var display = _v1.b;
			var link = _v1.c;
			if (!maybeIcon.$) {
				var icon = maybeIcon.a;
				return A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('nav-item')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href(link),
									$elm$html$Html$Attributes$class('nav-link'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'active',
											_Utils_eq(link, current_url))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('mar-r-sm')
										]),
									_List_fromArray(
										[
											$lattyware$elm_fontawesome$FontAwesome$Icon$viewIcon(icon)
										])),
									$elm$html$Html$text(display)
								]))
						]));
			} else {
				return A2(
					$elm$html$Html$li,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('nav-item')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href(link),
									$elm$html$Html$Attributes$class('nav-link'),
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'active',
											_Utils_eq(link, current_url))
										]))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(display)
								]))
						]));
			}
		};
		return A2(
			$elm$html$Html$nav,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('subnav')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('nav')
						]),
					A2($elm$core$List$map, renderLink, links))
				]));
	});
var $lattyware$elm_fontawesome$FontAwesome$Solid$list = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'list',
	512,
	512,
	_List_fromArray(
		['M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm416 176H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z']));
var $author$project$Jobs$View$viewSubnav = F2(
	function (model, route) {
		return A2(
			$author$project$Utils$Clarity$claritySubnav,
			_List_fromArray(
				[
					_Utils_Tuple3(
					$elm$core$Maybe$Just($lattyware$elm_fontawesome$FontAwesome$Solid$list),
					'List',
					'/jobs'),
					_Utils_Tuple3(
					$elm$core$Maybe$Just($lattyware$elm_fontawesome$FontAwesome$Solid$plus),
					'New',
					'/jobs/create')
				]),
			$author$project$Jobs$Routes$routeToString(route));
	});
var $lattyware$elm_fontawesome$FontAwesome$Solid$calendar = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'calendar',
	448,
	512,
	_List_fromArray(
		['M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z']));
var $lattyware$elm_fontawesome$FontAwesome$Solid$pencilAlt = A5(
	$lattyware$elm_fontawesome$FontAwesome$Icon$Icon,
	'fas',
	'pencil-alt',
	512,
	512,
	_List_fromArray(
		['M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z']));
var $author$project$Schedule$View$viewSubnav = F2(
	function (model, route) {
		return A2(
			$author$project$Utils$Clarity$claritySubnav,
			_List_fromArray(
				[
					_Utils_Tuple3(
					$elm$core$Maybe$Just($lattyware$elm_fontawesome$FontAwesome$Solid$calendar),
					'Schedule',
					'/schedule'),
					_Utils_Tuple3(
					$elm$core$Maybe$Just($lattyware$elm_fontawesome$FontAwesome$Solid$pencilAlt),
					'Edit',
					'/schedule/edit')
				]),
			$author$project$Schedule$Routes$routeToString(route));
	});
var $author$project$View$viewSubnav = F2(
	function (model, route) {
		switch (route.$) {
			case 2:
				var jobRoute = route.a;
				return $elm$core$Maybe$Just(
					A2($author$project$Jobs$View$viewSubnav, model, jobRoute));
			case 7:
				var scheduleRoute = route.a;
				return $elm$core$Maybe$Just(
					A2($author$project$Schedule$View$viewSubnav, model, scheduleRoute));
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$View$viewMainContainer = F2(
	function (model, route) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('main-container'),
					$author$project$UI$View$darkThemeAttribute(model.bV)
				]),
			A2(
				$elm$core$List$filterMap,
				function (f) {
					return A2(f, model, route);
				},
				_List_fromArray(
					[$author$project$View$viewGlobalAlert, $author$project$View$viewHeader, $author$project$View$viewSubnav, $author$project$View$viewContent, $author$project$View$showModal, $author$project$View$showModalBackdrop])));
	});
var $author$project$View$viewBody = function (model) {
	var _v0 = $author$project$Routes$getCurrentRoute(model.aP);
	if (!_v0.$) {
		switch (_v0.a.$) {
			case 1:
				var route = _v0.a.a;
				return A2($author$project$Auth$View$view, model, route);
			case 0:
				var _v1 = _v0.a;
				return $author$project$View$viewLoading;
			default:
				var route = _v0.a;
				return A2($author$project$View$viewMainContainer, model, route);
		}
	} else {
		return $author$project$View$view404;
	}
};
var $author$project$View$view = function (model) {
	return {
		ak: _List_fromArray(
			[
				$author$project$View$viewBody(model)
			]),
		cs: 'Janet'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{bT: $author$project$Main$init, b1: $author$project$Messages$UrlChanged, b2: $author$project$Messages$LinkClicked, cp: $author$project$Subscriptions$subscriptions, cu: $author$project$Update$update, cy: $author$project$View$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (token) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (tenant) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (protocol) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (is_dark_theme) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (api_url) {
											return $elm$json$Json$Decode$succeed(
												{bw: api_url, bV: is_dark_theme, ce: protocol, cr: tenant, bn: token});
										},
										A2($elm$json$Json$Decode$field, 'api_url', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'is_dark_theme', $elm$json$Json$Decode$bool));
						},
						A2($elm$json$Json$Decode$field, 'protocol', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'tenant', $elm$json$Json$Decode$string));
		},
		A2(
			$elm$json$Json$Decode$field,
			'token',
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
						A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
					])))))(0)}});}(this));